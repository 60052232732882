import * as React from 'react';

import axios from '../../http-request';
import { openDialogAction } from '../../forms/confirmation-dialog';
import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import { env } from '../../env/env';
import { logOutThunk } from '../../authentication/actions/log-out';

const baseUrl = `${env && env.ClaimsLiveAPI}/api/TeleClaim`;

export function submitToClaimsLive(teleClaim) {
    return async dispatch => {
        dispatch(
            openDialogAction({
                dialogContentDetails: {
                    notitle: true,
                    content: <span style={{ textAlign: 'center' }}>Submit to Claims Live</span>,
                },
                yesAction: { action: async () => await submit(teleClaim, dispatch) },
            })
        );
    };
}

async function submit(teleClaim, dispatch) {
    dispatch(showLoading());
    const generateSynopsisUri = `${baseUrl}/Submit`;
    try {
        /**@type {import('axios').AxiosRequestConfig} */
        const config = {
            url: generateSynopsisUri,
            data: { ...teleClaim, submitted: true },
            method: 'POST',
            responseType: 'text',
        };
        const summaryResponse = await axios(config);
        if (summaryResponse && summaryResponse.status === 200) {
            dispatch(
                openDialogAction({
                    dialogContentDetails: {
                        noTitle: true,
                        content: 'Tele Claim submitted successfully',
                    },
                    doneAction: { action: () => dispatch(logOutThunk()) },
                })
            );
        } else {
            showErrorMessage(`Status Code ${summaryResponse.status}`);
        }
    } catch (error) {
        let errorMessage = '';
        if (error.response) {
            errorMessage = `Error Code ${error.response.status}`;
        } else {
            errorMessage = error.message;
        }
        showErrorMessage(errorMessage);
    } finally {
        dispatch(hideLoading());
    }

    function showErrorMessage(error) {
        dispatch(
            openDialogAction({
                error: true,
                dialogContentDetails: {
                    title: 'Error generating summary',
                    content: `Summary could not be generated: ${error}`,
                },
            })
        );
    }
}
