import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { connect } from 'react-redux';
import { AssessorsNotes } from './common/assessors-notes';
import { LabelValue } from './common/label-value';

function requirements({ classes, requirements }) {
    const notes = (requirements && requirements.notes) || [];
    const documents = (requirements && requirements.documents) || [];
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>Section 3 - Requirements</Typography>
            </Grid>
            {documents.map((document, index) => {
                return (
                    <RequestedDocument
                        key={`requested-doc-${index}`}
                        classes={classes}
                        document={document}
                        index={index}
                    />
                );
            })}
            <AssessorsNotes id="requirements" notes={notes} />
        </React.Fragment>
    );
}

const RequestedDocument = ({ classes, document, index }) => {
    return (
        <React.Fragment>
            <Typography className={classes.summaryStepName}>{`Information Record ${
                index + 1
            }`}</Typography>
            <LabelValue label="Requirement" value={getDocumentName(document)} />
            <LabelValue label="Reason for Requirement" value={document.reason} />
        </React.Fragment>
    );
};

function getDocumentName(document) {
    if (document.name === 'Other') {
        return document.description;
    }
    return document.name;
}

const mapStateToProps = state => ({
    requirements: (state.teleClaim && state.teleClaim.requirements) || {},
});

const mapDispatchToProps = {};

export const RequirementsSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(requirements));
