export const MaritalStatuses = [
    'Single',
    'Engaged',
    'Widowed',
    'Separated',
    'Married',
    'Divorced',
].map(name => {
    return { value: name, label: name };
});

export const Frequency = [
    { value: 'Annually', label: 'Annually' },
    { value: 'Bi-Annually', label: 'Bi-Annually' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Monthly', label: 'Monthly' },
];

export const ExpenseIncomeTypes = [
    { value: 'Expense', label: 'Expense' },
    { value: 'Income', label: 'Income' },
];

export const MaintenanceType = [
    { value: 'Accommodation', label: 'Accommodation' },
    { value: 'Clothes', label: 'Clothes' },
    { value: 'Bond', label: 'Bond' },
    { value: 'Transport', label: 'Transport' },
    { value: 'MedicalAid', label: 'Medical aid' },
    { value: 'StudentFees', label: 'Student fees' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Salary', label: 'Salary' },
    { value: 'Rental', label: 'Rental' },
    { value: 'Other', label: 'Other' },
];

export const ClaimantTypes = ['Individual', 'Entity'].map(name => {
    return { value: name, label: name };
});

export const EntityTypes = [
    { value: 'Trust', label: 'Trust' },
    { value: 'ForeignTrust', label: 'Foreign Trust' },
    { value: 'CloseCorporation', label: 'Close Corporation' },
    { value: 'NonProfitOrganisation', label: 'Non Profit Organisation' },
    { value: 'PrivateCompany', label: 'Private Company' },
    { value: 'PublicCompany', label: 'Public Company' },
    { value: 'StateOwnedCompany', label: 'State Owned Company' },
    { value: 'PersonalLiabilityCompany', label: 'Personal Liability Company' },
    { value: 'ForeignCompany', label: 'Foreign Company' },
];

export const Suffixes = [
    { value: 'LTD', label: 'LTD' },
    { value: 'PTY_LTD', label: '(PTY) LTD' },
    { value: 'CC', label: 'CC' },
    { value: 'Incorporated', label: 'Incorporated' },
    { value: 'NPC', label: 'NPC' },
    { value: 'SOC', label: 'SOC' },
];

export const Countries = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Aland Islands', label: 'Aland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua and Bermuda', label: 'Antigua and Bermuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Ascension Island', label: 'Ascension Island' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovinia', label: 'Bosnia and Herzegovinia' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Bouvet Island', label: 'Bouvet Island' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British and Indian Territory', label: 'British and Indian Territory' },
    { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burma', label: 'Burma' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo, Republic of (Brazzaville)', label: 'Congo, Republic of (Brazzaville)' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    {
        value: 'Democratic Republic of the Congo(Kinshasa)',
        label: 'Democratic Republic of the Congo(Kinshasa)',
    },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'East Timor Timor - Leste', label: 'East Timor Timor - Leste' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Falkand Islands', label: 'Falkand Islands' },
    { value: 'Faroe Islands', label: 'Faroe Islands' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'French Metropolitan', label: 'French Metropolitan' },
    { value: 'French Polinesia', label: 'French Polinesia' },
    { value: 'French Southern Territories', label: 'French Southern Territories' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Gibraltar', label: 'Gibraltar' },
    { value: 'Great Britain', label: 'Great Britain' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Greenland', label: 'Greenland' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guernsey', label: 'Guernsey' },
    { value: 'Guinea - Bissau', label: 'Guinea - Bissau' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Heard and Mc Donald Island', label: 'Heard and Mc Donald Island' },
    { value: 'Holy See', label: 'Holy See' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran (Islamic Republic of)', label: 'Iran (Islamic Republic of)' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Isle of Man', label: 'Isle of Man' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Ivory Coast', label: 'Ivory Coast' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jersey', label: 'Jersey' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    {
        value: 'Korea, Democratic Peoples Rep (North Korea)',
        label: 'Korea, Democratic Peoples Rep (North Korea)',
    },
    { value: 'Korea, Republic of (South Korea)', label: 'Korea, Republic of (South Korea)' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Lao, Peoples Democratic Republic', label: 'Lao, Peoples Democratic Republic' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macau', label: 'Macau' },
    { value: 'Macedonia', label: 'Macedonia' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mayanmar, Burma', label: 'Mayanmar, Burma' },
    { value: 'Mayotte', label: 'Mayotte' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Miconesia, Federal States of', label: 'Miconesia, Federal States of' },
    { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Niue', label: 'Niue' },
    { value: 'Norfolk Island', label: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestinian National Authority', label: 'Palestinian National Authority' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pitcairn Island', label: 'Pitcairn Island' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Reunion Island', label: 'Reunion Island' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russian Federation', label: 'Russian Federation' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Barthelemy', label: 'Saint Barthelemy' },
    { value: 'Saint Helena', label: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Saint-Martin (French part)', label: 'Saint-Martin (French part)' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia (Slovak Republic)', label: 'Slovakia (Slovak Republic)' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    {
        value: 'South Georgia and South Sandwich Islands',
        label: 'South Georgia and South Sandwich Islands',
    },
    { value: 'South Sudan', label: 'South Sudan' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'St. Pierre and Miquelon', label: 'St. Pierre and Miquelon' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Svabald and Jan Mayen Islands', label: 'Svabald and Jan Mayen Islands' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria, Syrian Arab Republic', label: 'Syria, Syrian Arab Republic' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Tibet', label: 'Tibet' },
    { value: 'Timor - Leste (East Timor)', label: 'Timor - Leste (East Timor)' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tokelau', label: 'Tokelau' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Turks and Caicos Island', label: 'Turks and Caicos Island' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'U.S. Minor Outlying Islands', label: 'U.S. Minor Outlying Islands' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican City State (Holy See)', label: 'Vatican City State (Holy See)' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Virgin Islands (British)', label: 'Virgin Islands (British)' },
    { value: 'Virgin Islands (U.S.)', label: 'Virgin Islands (U.S.)' },
    { value: 'Wallis and Futuna Islands', label: 'Wallis and Futuna Islands' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zaire (see Congo, Democratic)', label: 'Zaire (see Congo, Democratic)' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
];

export const TaxStatuses = ['Tax Paying', 'Non Tax Paying'].map(name => {
    return { value: name, label: name };
});

export const AuthorisedPersonRelationships = [
    'Co-Director',
    'Authorized person/s',
    'Director',
    'Executor',
    'Shareholder',
    'Trustee',
    'Trust Founder',
    'Other',
].map(item => {
    return { value: item, label: item };
});

export const NaturalCausesOfDeath = [
    { value: '12', label: 'Tuberculosis' },
    { value: '47', label: 'Meningitis' },
    { value: '62', label: 'Encephalitis' },
    { value: '70', label: 'Hepatitis' },
    { value: '99', label: 'Venereal Disease' },
    { value: '140', label: 'Cancer Of The Lip' },
    { value: '141', label: 'Cancer Of The Tongue' },
    { value: '142', label: 'Cancer Of The Salivary Glands' },
    { value: '143', label: 'Cancer Of The Gum' },
    { value: '145', label: 'Cancer Of The Mouth' },
    { value: '149', label: 'Cancer Of The Pharynx' },
    { value: '150', label: 'Cancer Of The Oesophagus' },
    { value: '151', label: 'Cancer Of  The Stomach' },
    { value: '152', label: 'Cancer Of The Small Intestine' },
    { value: '153', label: 'Cancer Of The Colon' },
    { value: '154', label: 'Cancer Of The Rectum' },
    { value: '155', label: 'Cancer Of The Liver' },
    { value: '156', label: 'Cancer Of The Gall-Bladder' },
    { value: '157', label: 'Cancer Of The Pancreas' },
    { value: '160', label: 'Cancer Of The Nasal Cavities Or Sinus' },
    { value: '161', label: 'Cancer Of The Larynx' },
    { value: '164', label: 'Cancer Of The Thymus' },
    { value: '165', label: 'Cancer Of The Lung' },
    { value: '166', label: 'Cancer Of The Trachea' },
    { value: '170', label: 'Cancer Of The Bone' },
    { value: '171', label: 'Cancer Of The Connective Soft Tissue' },
    { value: '172', label: 'Cancer Of The Skin' },
    { value: '174', label: 'Cancer Of The Breast' },
    { value: '179', label: 'Cancer Of The Uterus' },
    { value: '180', label: 'Cancer Of The Cervix' },
    { value: '181', label: 'Cancer Of The Placenta' },
    { value: '183', label: 'Cancer Of  The Ovary' },
    { value: '185', label: 'Cancer Of The Prostate' },
    { value: '186', label: 'Cancer Of The Testis' },
    { value: '187', label: 'Cancer Of The Penis' },
    { value: '188', label: 'Cancer Of The Bladder' },
    { value: '189', label: 'Cancer Of The Kidney' },
    { value: '190', label: 'Cancer Of The Eye' },
    { value: '191', label: 'Cancer Of The Brain' },
    { value: '193', label: 'Cancer Of The Thyroid' },
    { value: '196', label: 'Cancer Of The Lymph Nodes' },
    { value: '208', label: 'Leukaemia' },
    { value: '234', label: 'Cancer' },
    { value: '243', label: 'Hypothyroidism' },
    { value: '246', label: 'Disorders Of Thyroid' },
    { value: '250', label: 'Diabetes' },
    { value: '251', label: 'Disorders Of Pancreatic' },
    { value: '269', label: 'Malnutrition' },
    { value: '285', label: 'Anaemia' },
    { value: '311', label: 'Depression' },
    { value: '319', label: 'Mental Retardation' },
    { value: '331', label: 'Demientia / Alzheimers' },
    { value: '343', label: 'Cerebral Palsy' },
    { value: '344', label: 'Paralysis' },
    { value: '348', label: 'Brain Tumour' },
    { value: '359', label: 'Muscular Dystrophy' },
    { value: '398', label: 'Rheumatic Fever' },
    { value: '401', label: 'Hypertension' },
    { value: '410', label: 'Heart Attack / Myocardial Infarction' },
    { value: '414', label: 'Ischaemic Heart Disease' },
    { value: '428', label: 'Heart Failure / Cardiac Failure' },
    { value: '437', label: 'Stroke / Cerebral Infarction' },
    { value: '467', label: 'COVID-19' },
    { value: '486', label: 'Pneumonia' },
    { value: '496', label: 'Chronic Airways Obstruction' },
    { value: '508', label: 'Respiratory Failure' },
    { value: '536', label: 'Disorders Of The Stomach / Ulcers' },
    { value: '542', label: 'Appendicitis' },
    { value: '550', label: 'Hernia' },
    { value: '571', label: 'Liver Disease & Cirrhosis' },
    { value: '586', label: 'Renal Failure' },
    { value: '593', label: 'Kidney Failure' },
    { value: '646', label: 'Complications Of Pregnancy' },
    { value: '714', label: 'Rheumatoid Arthritis' },
    { value: '715', label: 'Osteoarthrosis' },
    { value: '799', label: 'Other' },
];

export const AccountTypes = [
    { value: 'cheque', label: 'Cheque' },
    { value: 'savings', label: 'Savings' },
    { value: 'transmission', label: 'Transmission' },
];

export const AccountHolderRelationship = [
    { value: 'joint', label: 'Joint' },
    { value: 'own', label: 'Own' },
];

export const UnnaturalCausesOfDeath = [
    { value: '798', label: 'Other' },
    { value: '806', label: 'Train Accident' },
    { value: '811', label: 'Motor Vehicle Accident' },
    { value: '826', label: 'Bicycle Accident' },
    { value: '829', label: 'Motor Bike Accident' },
    { value: '835', label: 'Drowning' },
    { value: '837', label: 'Burning' },
    { value: '844', label: 'Aeroplane, Helicopter Accident' },
    { value: '849', label: 'Mine Accident' },
    { value: '858', label: 'Drug Overdose' },
    { value: '860', label: 'Alchohol Overdose' },
    { value: '880', label: 'Falling Down Stairs' },
    { value: '889', label: 'Murder / Homicide' },
    { value: '899', label: 'Accident Caused By Fire' },
    { value: '907', label: 'Struck By Lightening' },
    { value: '910', label: 'Accidental Drowning' },
    { value: '959', label: 'Suicide / Self Inflicted Injury' },
    { value: '960', label: 'Assault / Rape' },
    { value: '963', label: 'Strangulation' },
];

export function addressTypes(isResidential) {
    let types = [
        { value: 'Street', label: 'RSA Street Address' },
        { value: 'Foreign', label: 'Foreign' },
    ];

    if (!isResidential) {
        types = [
            ...types,
            { value: 'PostBox', label: 'RSA Post box' },
            { value: 'PrivateBag', label: 'RSA Private bag' },
        ];
    }

    types = types.sort();

    return types.map(type => {
        return { value: type.value, label: type.label };
    });
}

export const Provinces = [
    { value: 'Eastern Cape', label: 'Eastern Cape' },
    { value: 'Free State', label: 'Free State' },
    { value: 'Gauteng', label: 'Gauteng' },
    { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
    { value: 'Limpopo', label: 'Limpopo' },
    { value: 'Mpumalanga', label: 'Mpumalanga' },
    { value: 'North West', label: 'North West' },
    { value: 'Northern Cape', label: 'Northern Cape' },
    { value: 'Western Cape', label: 'Western Cape' },
];

export const Titles = [
    { value: 'MR', label: 'Mr' },
    { value: 'MRS', label: 'Mrs' },
    { value: 'MISS', label: 'Miss' },
    { value: 'DR', label: 'Dr' },
    { value: 'PROF', label: 'Prof' },
    { value: 'MS', label: 'Ms' },
    { value: 'ADJUTANT', label: 'Adjutant' },
    { value: 'ADJUTANT GENERAL', label: 'Adjutant General' },
    { value: 'ADMIRAL', label: 'Admiral' },
    { value: 'ADVOCATE', label: 'Advocate' },
    { value: 'BROTHER', label: 'Brother' },
    { value: 'BRIGADIER', label: 'Brigadier' },
    { value: 'BRIGADIER GENERAL', label: 'Brigadier General' },
    { value: 'CAPTAIN', label: 'Captain' },
    { value: 'THE HONOURABLE CHIEF JUSTICE', label: 'The Honourable Chief Justice' },
    { value: 'COMMANDER', label: 'Commander' },
    { value: 'COLONEL', label: 'Colonel' },
    { value: 'COMMANDANT', label: 'Commandant' },
    { value: 'DIRECTOR GENERAL', label: 'Director General' },
    { value: 'HIS EXCELLENCY', label: 'His Excellency' },
    { value: 'HER EXCELLENCY', label: 'Her Excellency' },
    { value: 'FIELD-MARSHALL', label: 'Field-Marshall' },
    { value: 'GENERAL', label: 'General' },
    { value: 'GOVERNOR', label: 'Governor' },
    { value: 'HIS EMINENCE', label: 'His Eminence' },
    { value: 'HIS HIGHNESS', label: 'His Highness' },
    { value: 'HER ROYAL HIGHNESS', label: 'Her Royal Highness' },
    { value: 'HIS ROYAL HIGHNESS', label: 'His Royal Highness' },
    { value: 'HER SERENE HIGHNESS', label: 'Her Serene Highness' },
    { value: 'HIS SERENE HIGHNESS', label: 'His Serene Highness' },
    { value: 'INSPECTOR', label: 'Inspector' },
    { value: 'LANCE-CORPORAL', label: 'Lance-Corporal' },
    { value: 'LIEUTENANT', label: 'Lieutenant' },
    { value: 'LIEUTENANT-COLONEL', label: 'Lieutenant-Colonel' },
    { value: 'LIEUTENANT-COMMANDER', label: 'Lieutenant-Commander' },
    { value: 'LIEUTENANT-GENERAL', label: 'Lieutenant-General' },
    { value: 'LORD', label: 'Lord' },
    { value: 'MAJOR', label: 'Major' },
    { value: 'MAJOR-GENERAL', label: 'Major-General' },
    { value: 'MESDAMES', label: 'Mesdames' },
    { value: 'MESSIEURS', label: 'Messieurs' },
    { value: 'MONSIGNOR', label: 'Monsignor' },
    { value: 'MINISTER', label: 'Minister' },
    { value: 'QUARTERMASTER-GENERAL', label: 'Quartermaster-General' },
    { value: 'QUARTERMASTER-SERGEANT', label: 'Quartermaster-Sergeant' },
    { value: 'RABBI', label: 'Rabbi' },
    { value: 'REAR-ADMIRAL', label: 'Rear-Admiral' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'THE RIGHT REVEREND', label: 'The Right Reverend' },
    { value: 'REGIMENTAL SERGEANT-MAJOR', label: 'Regimental Sergeant-Major' },
    { value: 'THE RIGHT HONOURABLE', label: 'The Right Honourable' },
    { value: 'SENATOR', label: 'Senator' },
    { value: 'SERGEANT', label: 'Sergeant' },
    { value: 'SERGEANT-MAJOR', label: 'Sergeant-Major' },
    { value: 'SIR', label: 'Sir' },
    { value: 'THEIR ROYAL HIGHNESSES', label: 'Their Royal Highnesses' },
    { value: 'FATHER', label: 'Father' },
    { value: 'DOCTORS', label: 'Doctors' },
    { value: 'MASTER', label: 'Master' },
    { value: 'SISTER', label: 'Sister' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'PASTOR', label: 'Pastor' },
    { value: 'THE HONOURABLE', label: 'The Honourable' },
    { value: 'SUB-LIEUTENANT', label: 'Sub-Lieutenant' },
    { value: 'LADY', label: 'Lady' },
    { value: 'BISHOP', label: 'Bishop' },
    { value: 'COUNT', label: 'Count' },
    { value: 'COUNTESS', label: 'Countess' },
    { value: 'CHIEF', label: 'Chief' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'JUDGE', label: 'Judge' },
    { value: 'BARON', label: 'Baron' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'MISTER & MISSUS', label: 'Mister & Missus' },
    { value: 'WARRANT OFFICER', label: 'Warrant Officer' },
    { value: 'BARONESS', label: 'Baroness' },
    { value: 'VISCOUNT', label: 'Viscount' },
    { value: 'VISCOUNTESS', label: 'Viscountess' },
    { value: 'COMMISSIONER', label: 'Commissioner' },
];

export const IdOptions = [
    { value: 'RsaId', label: 'South African barcoded green ID book' },
    { value: 'RsaIdSmartCard', label: 'South African ID Smart Card' },
    { value: 'RsaIdTemporaryId', label: 'South African Temporary ID' },
    { value: 'RsaIdBirthCertificate', label: 'South African Birth Certificate' },
    { value: 'RsaPassport', label: 'South African Passport' },
    { value: 'ForeignPassport', label: 'Foreign Passport' },
    { value: 'ForeignIdBook', label: 'Foreign Identity Book' },
    { value: 'WorkPermit', label: 'Work Permit' },
    { value: 'Asylum', label: 'Asylum' },
];

export const CommunicationMethods = [
    { value: 'Email', label: 'Email' },
    { value: 'Cellphone', label: 'Cellphone' },
    { value: 'Telephone', label: 'Telephone' },
];

export const ProofOfAddress = [
    { value: 'UtilityBill', label: 'A utility bill (less than 3 months old)' },
    { value: 'LeaseRentalAgreement', label: 'A lease/rental agreement (less than a year old)' },
    {
        value: 'MunicipalityInvoice',
        label: 'A municipal rates and taxes invoice (less than 3 months old)',
    },
    {
        value: 'TelephoneAccount',
        label: 'A telephone or cellphone account (less than 3 months old)',
    },
    {
        value: 'RetailAccount',
        label: 'A retail account statement/invoice (less than 3 months old)',
    },
    { value: 'TaxReturn', label: 'An official tax return (less than a year old)' },
    {
        value: 'TaxCorrespondence',
        label: 'An official tax assessment, from the local revenue services (less than a year old)',
    },
    { value: 'TelevisionLicense', label: 'A valid TV license document (less than a year old)' },
    { value: 'BankStatement', label: 'A bank statement (Except Standard Bank)' },
    {
        value: 'Confirmation',
        label: 'Confirmation from a tribal authority or municipal councilor',
    },
    { value: 'Affidavit', label: 'Affidavit from an authorised police official' },
];

export const Relationships = [
    { value: 'Estranged Spouse', label: 'Estranged Spouse' },
    { value: 'Ex Spouse', label: 'Ex Spouse' },
    { value: 'Life Partner', label: 'Life Partner' },
    { value: 'Major Child (18 years and older)', label: 'Major Child (18 years and older)' },
    { value: 'Minor Child (Younger than 18)', label: 'Minor Child (Younger than 18)' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Spouse', label: 'Spouse' },
    { value: 'Other', label: 'Other' },
];

export const NaturalRelationships = [
    { value: 'Aunt', label: 'Aunt' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Child', label: 'Child' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Estranged Spouse', label: 'Estranged Spouse' },
    { value: 'Ex Spouse', label: 'Ex Spouse' },
    { value: 'Executor', label: 'Executor' },
    { value: 'Father', label: 'Father' },
    { value: 'Grandchild', label: 'Grandchild' },
    { value: 'Granddaughter', label: 'Granddaughter' },
    { value: 'Grandfather', label: 'Grandfather' },
    { value: 'Grandmother', label: 'Grandmother' },
    { value: 'Grandparent', label: 'Grandparent' },
    { value: 'Grandson', label: 'Grandson' },
    { value: 'Husband', label: 'Husband' },
    { value: 'Major Child (18 years and older)', label: 'Major Child (18 years and older)' },
    { value: 'Minor Child (Younger than 18)', label: 'Minor Child (Younger than 18)' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Nephew', label: 'Nephew' },
    { value: 'Niece', label: 'Niece' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Son', label: 'Son' },
    { value: 'Uncle', label: 'Uncle' },
    { value: 'Wife', label: 'Wife' },
    { value: 'Other', label: 'Other' },
];

export const OrganisationRelationships = [
    { value: 'Creditor', label: 'Creditor' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Financial Advisor', label: 'Financial Advisor' },
    { value: 'Lawyer/Attorney', label: 'Lawyer/Attorney' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Other', label: 'Other' },
];

export const EntityAssigneeRelationships = [
    'Authorised Person',
    'Co-Director',
    'Co-Shareholder',
    'Director',
    'Family Trust',
    'Founder',
    'Organization Partner',
    'Owner of an organisation',
    'Shareholder',
    'Trust Founder',
    'Trust Fund',
    'Trust',
    'Trustee',
].map(entity => {
    return {
        value: entity,
        label: entity,
    };
});

export const RelationshipsChildren = [
    'Adopted Daughter',
    'Adopted Son',
    'Daughter',
    'Foster Daughter',
    'Foster Son',
    'Goddaughter',
    'Godson',
    'Grand-Daughter',
    'Granddaughter-In-Law',
    'Grand-Nephew',
    'Grand-Niece',
    'Grand-Son',
    'Grandson-In-Law',
    'Great Granddaughter',
    'Great Grandson',
    'Great-Grandnephew',
    'Great-Nephew',
    'Great-Niece',
    'Nephew',
    'Niece',
    'Son',
    'Step-Daughter',
    'Step-Granddaughter',
    'Step-Grandson',
    'Step-Son',
].map(children => {
    return {
        value: children,
        label: children,
    };
});

export const RelationshipsParents = [
    'Adopted Father',
    'Adopted Mother',
    'Father',
    'Foster Father',
    'Foster Mother',
    'Mother',
    'Step-Father',
    'Step-Mother',
].map(parents => {
    return {
        value: parents,
        label: parents,
    };
});

export const RelationshipsSpouses = ['Husband', 'Wife'].map(spouses => {
    return {
        value: spouses,
        label: spouses,
    };
});

export const RelationshipsRelatives = [
    'Aunt',
    'Adopted Brother',
    'Adopted Sister',
    'Brother',
    'Brother-In-Law',
    'Cousin',
    'Daughter-In-Law',
    'Father-In-Law',
    'Foster Brother',
    'Foster Sister',
    'Grandfather',
    'Grandmother',
    'Grandnephew',
    'Half-Brother',
    'Half-Sister',
    'Mother-In-Law',
    'Sister',
    'Sister-In-Law',
    'Son-In-Law',
    'Step-Brother',
    'Step-Grandfather',
    'Step-Grandmother',
    'Step-Sister',
    'Step-Son',
    'Uncle',
].map(relatives => {
    return {
        value: relatives,
        label: relatives,
    };
});

export const RelationshipsPartner = ['Partner'].map(partner => {
    return {
        value: partner,
        label: partner,
    };
});

export const RelationshipsExSpouses = ['Ex-Husband', 'Ex-Wife'].map(exSpouses => {
    return {
        value: exSpouses,
        label: exSpouses,
    };
});

export const RelationshipsOther = [
    'Curator',
    'Employer',
    'Executor',
    'Fiance (Male)',
    'Fiancee (Female)',
    'Friend',
    'Guardian',
    'Lawyer/attorney',
].map(other => {
    return {
        value: other,
        label: other,
    };
});

export const HighLeveTreatments = [
    { value: 'Medical intervention', label: 'Medical intervention' },
    { value: 'Surgery', label: 'Surgery' },
    { value: 'Medication', label: 'Medication' },
    { value: 'Other', label: 'Other' },
];

export const CallTimes = [
    { value: '08:00', label: '08:00 - 09:00 AM' },
    { value: '09:00', label: '09:00 - 10:00 AM' },
    { value: '10:00', label: '10:00 - 11:00 PM' },
    { value: '11:00', label: '11:00 - 12:00 PM' },
    { value: '12:00', label: '12:00 - 13:00 PM' },
    { value: '13:00', label: '13:00 - 14:00 PM' },
    { value: '14:00', label: '14:00 - 15:00 PM' },
    { value: '15:00', label: '15:00 - 16:00 PM' },
    { value: '16:00', label: '16:00 - 17:00 PM' },
    { value: '17:00', label: '17:00 - 18:00 PM' },
];
