import * as React from 'react';

import { FormHelperText, RadioGroup, withStyles, Typography } from '@material-ui/core';
import {
    addNameToChildren,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../../form-component-utilities';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';
import { callAllFunctions } from '../..';

const radioGroupField = ({
    classes,
    disabled,
    onChange,
    label,
    helperText,
    id,
    children,
    input,
    meta,
    ...rest
}) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const childrenWithProps = addNameToChildren({ id, name, children, disabled });
    const _id = getComponentId({ id, name });

    const showHelperText = showError || !!helperText;
    const labelStyle = showError
        ? { color: '#cd003d', marginBottom: '-4px' }
        : { marginBottom: '-4px' };
    return (
        <div className={classes.textField}>
            <Typography className={classes.cssLabel} style={labelStyle}>
                {getValidatedLabel(rest, nameToLabel({ label, name }))}
            </Typography>
            <RadioGroup
                {...rest}
                onChange={callAllFunctions(onChange, input.onChange)}
                className={classes.radioGroup}
                id={_id}
                name={name}
                value={value}
            >
                {childrenWithProps}
            </RadioGroup>
            {showHelperText && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre" style={{ marginTop: '-10px' }}>
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export const RadioGroupField = withStyles(styles)(radioGroupField);
