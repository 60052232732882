import * as React from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    withStyles,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getComponentId, nameToLabel } from '../../';
import { DeleteOutline } from '@material-ui/icons';

function styles(theme) {
    return createStyles({
        label: {
            fontSize: theme.typography.pxToRem(15),
            flexShrink: 0,
        },
        secondaryLabel: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        panelDetails: {
            flexDirection: 'column',
        },
        whiteTitle: {
            height: '27px',
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: '27px',
        },
        whiteBackground: {
            boxSizing: 'border-box',
            height: '60px',
            border: '2px solid #002B99',
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#FFFFFF',
        },
        labelBold: {
            fontWeight: 'bold',
            textTransform: 'capitalize',
        },
    });
}

const customExpansionPanel = ({
    children,
    name,
    id,
    deletable = false,
    label,
    remove,
    secondaryLabel,
    classes,
    ...rest
}) => {
    return (
        <Accordion {...rest}>
            <AccordionSummary
                className={[classes.whiteBackground, classes.whiteTitle].join(' ')}
                expandIcon={<ExpandMoreIcon />}
            >
                {deletable && (
                    <DeleteOutline
                        id={getComponentId({ id, name })}
                        style={{ color: '#F46363' }}
                        onClick={(fields, index) => remove(fields, index)}
                        className={classes.deleteIcon}
                    />
                )}
                <Typography className={[classes.label, classes.labelBold].join(' ')}>
                    {nameToLabel({ label, name })}
                </Typography>
                {secondaryLabel ? (
                    <Typography className={classes.secondaryLabel}>{secondaryLabel}</Typography>
                ) : undefined}
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>{children}</AccordionDetails>
        </Accordion>
    );
};

export const CustomExpansionPanel = withStyles(styles)(customExpansionPanel);
