import * as React from 'react';

import { Field } from 'react-final-form';
import { DatePicker } from '../date-picker';
import { FormControl } from '../form-control';

export const OutlinedDatePicker = ({ name, fullWidth = true, shaded = true, ...rest }) => {
    const { maxDateField, minDateField } = rest;
    let DatePickerFieldElement = (
        <Field
            {...rest}
            name={name}
            fullWidth={fullWidth}
            // @ts-ignore
            component={DatePicker}
            shaded={shaded}
            type="text"
        />
    );
    if (minDateField) {
        DatePickerFieldElement = addMinDateField(DatePickerFieldElement, minDateField);
    }

    if (maxDateField) {
        DatePickerFieldElement = addMaxDateField(DatePickerFieldElement, maxDateField);
    }

    return <FormControl>{DatePickerFieldElement}</FormControl>;
};

function addMinDateField(field, minDateField) {
    return (
        <Field
            name={minDateField}
            subscription={{ value: true }}
            component={({ input: { value } }) =>
                React.cloneElement(field, { minDate: value || '1900-01-01' })
            }
        />
    );
}

function addMaxDateField(field, maxDateField) {
    return (
        <Field
            name={maxDateField}
            subscription={{ value: true }}
            component={({ input: { value } }) =>
                React.cloneElement(field, { maxDate: value || '2100-01-01' })
            }
        />
    );
}
