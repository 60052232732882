import React from 'react';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { StepImage } from '../common/step-image';
import { PolicyInformation } from '../common/policy-information';
import { OutlinedTextAreaField } from '../../forms';
import { camelize } from '../../shared';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotesIcon from '../../styles/icons/notes-icon.svg';
import { NotesView } from '../common/notes';

const VerticalStepperSteps = function ({
    classes,
    form,
    handleSubmit,
    handleBack,
    name,
    resetFormErrors,
    submitted,
    verticalComponents,
    ...rest
}) {
    let verticalStepIndex = +sessionStorage.getItem('verticalStepIndex');

    if (verticalStepIndex === -1) {
        verticalStepIndex = verticalComponents.length - 1;
        sessionStorage.setItem('verticalStepIndex', `${verticalStepIndex}`);
    } else if (verticalStepIndex > verticalComponents.length - 1) {
        verticalStepIndex = 0;
        sessionStorage.setItem('verticalStepIndex', `${verticalStepIndex}`);
    }

    let [activeStep, setActiveStep] = React.useState(verticalStepIndex || 0);
    let [viewNotes, setViewNotesState] = React.useState(false);

    const goToNextStep = form => {
        if (activeStep === verticalComponents.length - 1) {
            sessionStorage.setItem('canGoToNextPage', 'true');
            handleSubmit(form);
        } else {
            if (Object.keys(form.getState().errors).length > 0) {
                form.submit();
            } else {
                activeStep++;
                sessionStorage.setItem('canGoToNextPage', 'false');
                sessionStorage.setItem('verticalStepIndex', `${activeStep}`);
                form.submit();
                setActiveStep(activeStep);
            }
        }
    };

    const goToPreviousStep = form => {
        if (activeStep === 0) {
            goToPreviousSection(form);
        } else {
            sessionStorage.setItem('verticalStepIndex', `${activeStep - 1}`);
            setActiveStep(prevActiveStep => prevActiveStep - 1);
            resetFormErrors(form);
        }
    };

    const goToPreviousSection = form => {
        sessionStorage.setItem('verticalStepIndex', '-1');
        sessionStorage.setItem('canGoToNextPage', 'true');
        handleBack(form);
    };

    const goToSelectedStep = (step, form) => () => {
        sessionStorage.setItem('verticalStepIndex', `${step}`);
        setActiveStep(step);
        if (step < verticalComponents.length - 1) {
            sessionStorage.setItem('canGoToNextPage', 'false');
        } else {
            sessionStorage.setItem('canGoToNextPage', 'true');
        }
        resetFormErrors(form);
    };

    let notesPrefix = '';
    const propertyName = camelize(name);
    const VerticalComponent = verticalComponents[activeStep].component;
    if (verticalComponents[activeStep] && verticalComponents[activeStep].name) {
        notesPrefix = `${propertyName}.notes[${activeStep}]`;
    }

    return (
        <Paper className={classes.paper}>
            <Grid container justify="flex-start" style={{ marginTop: 16 }}>
                <Grid item style={{ width: '280px' }}>
                    <Grid
                        item
                        xs={12}
                        style={{ textAlign: 'right', marginRight: '20px', marginBottom: '26px' }}
                    >
                        <Typography
                            style={{
                                fontSize: '24px',
                                lineHeight: '30px',
                                letterSpacing: '0.17px',
                            }}
                        >
                            {name}
                        </Typography>
                        <Typography style={{ marginTop: '12px' }}>
                            Complete all the steps
                        </Typography>
                    </Grid>
                    <Grid style={{ marginRight: '33px', marginTop: '80px' }}>
                        <Grid
                            item
                            xs={12}
                            container
                            justify="flex-start"
                            className={classes.stepsContainer}
                            style={{
                                height: stepperHeight(verticalComponents.length),
                            }}
                        >
                            {verticalComponents.map((component, stepIndex) => {
                                const stepNameClass =
                                    stepIndex === activeStep
                                        ? classes.stepNameActive
                                        : classes.stepName;
                                const stepIconClass =
                                    stepIndex < activeStep || submitted
                                        ? classes.stepIconCompleted
                                        : classes.stepIcon;
                                return (
                                    <div key={stepIndex} className={classes.stepInfoContainer}>
                                        <div className={classes.stepInfo}>
                                            <Typography className={classes.stepDetails}>
                                                <span className={stepNameClass}>
                                                    {formattedStepName(component.name)}
                                                </span>
                                            </Typography>
                                            <div
                                                className={stepIconClass}
                                                onClick={
                                                    stepIndex < activeStep || submitted
                                                        ? goToSelectedStep(stepIndex, form)
                                                        : () => {}
                                                }
                                            >
                                                <StepImage
                                                    currentIndex={stepIndex}
                                                    activeIndex={activeStep}
                                                    submitted={submitted}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.stepsContents} style={{ width: '430px' }}>
                    <Grid item style={{ width: '404px' }}>
                        <VerticalComponent
                            {...rest}
                            classes={classes}
                            index={verticalStepIndex}
                            form={form}
                            stepsLength={verticalComponents.length}
                            handleBackStepper={goToPreviousStep}
                            handleNext={goToNextStep}
                            submitted={submitted}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.stepsContents} style={{ width: '370px' }}>
                    <Grid container justify="flex-start">
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            container
                            justify="flex-start"
                            style={{ marginTop: '24px', marginBottom: '3px' }}
                        >
                            <Grid item xs={10}>
                                <Typography className={classes.notesLabel}>Notes</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: '10px',
                                        marginLeft: '-16px',
                                    }}
                                    onClick={() => setViewNotesState(true)}
                                >
                                    <img src={NotesIcon} alt="view notes" />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <OutlinedTextAreaField
                                name={notesPrefix}
                                label="Assessor's Notes"
                                placeholder="your private notes here"
                                disabled={submitted}
                            />
                        </Grid>
                    </Grid>
                    <PolicyInformation />
                </Grid>
                <SweetAlert
                    show={viewNotes}
                    style={{
                        width: '1000px',
                        borderRadius: '4px',
                        border: 'solid 1px #ffffff',
                        justifyContent: 'space-evenly',
                    }}
                    title=""
                    openAnim={{ name: 'showSweetAlert', duration: 0 }}
                    onConfirm={() => {}}
                    customButtons={
                        <button
                            onClick={() => setViewNotesState(false)}
                            className={classes.closeNotes}
                        >
                            Close
                        </button>
                    }
                >
                    <Grid container>
                        <Typography className={classes.assessorNotes}>
                            Assessor&apos;s Notes
                        </Typography>
                        <NotesView components={verticalComponents} name={propertyName} />
                    </Grid>
                </SweetAlert>
            </Grid>
        </Paper>
    );
};

function stepperHeight(numberOfSteps) {
    if (numberOfSteps === 1) {
        return 24;
    }
    return Number(numberOfSteps) * 80;
}

function formattedStepName(name) {
    switch (name) {
        case 'Past Medical Conditions':
            return 'Past Medical Condition(s)';
        case 'Medical Aid Questions':
            return 'Medical Aid Question(s)';
        default:
            return name;
    }
}

export const VerticalStepper = withStyles(styles)(VerticalStepperSteps);
