// @ts-nocheck
import { createStyles } from '@material-ui/core/styles';

/**
 * @typedef {import('@material-ui/core/styles/withStyles').WithStyles<typeof styles>} StyledComponent;
 * @param {import('@material-ui/core').Theme} theme
 */
export default function styles(theme) {
    return createStyles({
        appBar: {
            position: 'fixed',
            boxShadow: 'none',
        },
        logo: {
            marginTop: 10,
            marginRight: 20,
        },
        medicalHistoryTitle: {
            color: '#0D1325',
            fontStyle: 'italic',
            lineHeight: '27px',
        },
        dialogContent: {
            textAlign: 'center',
            color: '#0D1325',
            fontFamily: 'Noto Sans',
            fontSize: '16px',
            letterSpacing: '0',
            lineHeight: '27px',
        },
        dialogAction: {
            justifyContent: 'center',
            marginTop: '8px',
            marginBottom: '24px',
        },
        customSweetAlert: {
            zIndex: 1200,
            width: '416px',
            height: '522px',
            borderRadius: '4px',
            border: 'solid 1px #ffffff',
            justifyContent: 'space-evenly',
        },
        viewNotes: {
            cursor: 'pointer',
            display: 'flex',
            marginTop: '10px',
            marginLeft: '90px',
        },
        notesLabel: {
            height: '41px',
            width: '69px',
            color: '#0D1325',
            fontFamily: 'Noto Sans',
            fontSize: '24px',
            fontWeight: '500',
            letterSpacing: '0.17px',
            lineHeight: '41px',
        },
        notesView: {
            textAlign: 'initial',
            color: '#0D1325',
            fontSize: '14px',
            letterSpacing: 0,
            lineHeight: '20px',
            marginBottom: '27px',
        },
        notesDisclaimer: {
            height: '20px',
            fontSize: '14px',
            fontStyle: 'italic',
            lineHeight: '20px',
            textAlign: 'left',
            marginBottom: '16px',
        },
        notesIndex: {
            borderRadius: '50%',
            height: '24px',
            width: '24px',
            border: '1px solid #002B99',
            color: '#002B99',
            textAlign: 'center',
            marginLeft: '0px',
        },
        sectionLabel: sectionLabel(),
        assessorNotes: {
            height: '23px',
            fontSize: '24px',
            fontWeight: '500',
            letterSpacing: '0.17px',
            lineHeight: '23px',
            marginBottom: '19px',
        },
        modalTitle: {
            margin: '0px 0px 0.4em',
            position: 'relative',
            maxWidth: '100%',
            padding: '0px',
            fontSize: '1.875em',
            fontWeight: '600',
            textAlign: 'center',
            textTransform: 'none',
            overflowWrap: 'break-word',
        },
        modalMarginTop: {
            paddingTop: '71px',
        },
        modalContent: {
            textAlign: 'center',
            maxWidth: '458px',
            overflow: 'unset',
        },
        closeButton: {
            textAlign: 'center',
            boxSizing: 'border-box',
            height: '48px',
            width: '192px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            marginLeft: '8px',
            cursor: 'pointer',
            fontFamily: 'Noto Sans',
            fontWeight: '500',
            lineHeight: '20px',
            textTransform: 'capitalize',
        },
        saveButton: {
            boxSizing: 'border-box',
            height: '48px',
            width: '192px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#002B99',
            marginLeft: '16px',
            cursor: 'pointer',
            color: '#FFFFFF',
            fontFamily: 'Noto Sans',
            fontWeight: '500',
            textTransform: 'capitalize',
            lineHeight: '20px',
            '&:hover': {
                backgroundColor: '#002B99',
            },
        },
        closeNotes: {
            position: 'absolute',
            bottom: '11px',
            right: '24px',
            boxSizing: 'border-box',
            height: '48px',
            width: '144px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#002B99',
            cursor: 'pointer',
            color: '#FFFFFF',
            fontFamily: 'Noto Sans',
            fontWeight: '500',
            lineHeight: '20px',
        },
        screeningQuestionsView: {
            marginLeft: '38px',
        },
        screeningQuestions: {
            height: '31px',
            width: '368px',
            color: '#002B99',
            fontFamily: 'Noto Sans',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '31px',
            letterSpacing: '0',
        },
        confirmButton: {
            height: '48px',
            width: '368px',
            borderRadius: '4px',
            backgroundColor: '#002B99',
            marginLeft: '0',
        },
        activeStepperCircle: {
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            backgroundColor: '#002B99',
            color: '#FFFFFF',
            margin: '12px',
            marginLeft: '0px',
            textAlign: 'center',
        },
        utilSteps: {
            height: '20px',
            width: '70px',
            color: '#0D1325',
            fontSize: '14px',
            letterSpacing: '0',
            lineHeight: '20px',
        },
        contentHeader: {
            height: '30px',
            width: '330.21px',
            color: '#0D1325',
            fontFamily: 'Noto Sans',
            fontSize: '24px',
            fontWeight: 600,
            letterSpacing: '0.17px',
            lineHeight: '30px',
            marginTop: '9px',
        },
        wrappingContentHeader: {
            marginBottom: '50px',
        },
        empathyTitle: {
            height: '20px',
            width: '272px',
            color: '#0D1325',
            fontSize: '18px',
            letterSpacing: '0',
            lineHeight: '20px',
            marginBottom: '10px',
        },
        continueAndPreviouseButton: {
            flexWrap: 'unset',
            width: '36%',
            position: 'relative',
            bottom: '80px',
            left: '65%',
        },
        pointerCursor: {
            cursor: 'pointer',
        },
        errorContainer: {
            display: 'flex',
        },
        errorDetails: {
            margin: '24px 0px 20px 16px',
            width: '90%',
            fontSize: '18px',
            lineHeight: '20px',
            backgroundColor: '#FFEBEE',
        },
        errorHeading: {
            fontSize: '18px',
            lineHeight: '20px',
            color: '#0d1325',
        },
        errorIcon: {
            color: '#F46363',
            marginLeft: '18px',
            marginTop: '31px',
        },
        errorImage: {
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            marginTop: '16px',
        },
        errorLine: {
            height: '18px',
            color: '#424757',
            fontSize: '12px',
            lineHeight: '18px',
        },
        errorWrapper: {
            width: '100%',
            height: '100%',
            backgroundColor: '#FFEBEE',
            borderRadius: '4px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
            position: 'sticky',
            marginTop: '-5px',
            top: '64px',
            zIndex: 11 * 1001,
        },
        onCall: {
            color: '#0D1325',
            fontSize: '24px',
            fontWeight: 500,
            letterSpacing: '0.17px',
            lineHeight: '27px',
            marginTop: '29px',
            marginBottom: '7px',
        },
        onCallInfo: {
            color: '#0D1325',
            fontSize: '16px',
            letterSpacing: '0px',
            lineHeight: '27px',
            marginBottom: '16.07px',
        },
        requiredInformation: {
            backgroundColor: '#DEECFF',
            borderRadius: '4px',
            display: 'flex',
            minHeight: '40px',
            margin: '24px auto 32px auto',
            padding: '11px 11px 11px 19px',
            width: '100%',
        },
        requiredInformationText: {
            marginLeft: '11px',
            fontSize: '12px',
        },
        userPrompts: {
            maxWidth: '368px',
            backgroundColor: '#DEECFF',
            borderRadius: '4px',
            display: 'flex',
            minHeight: '40px',
            padding: '11px 11px 11px 19px',
            width: '100%',
        },
        userPromptsText: {
            marginLeft: '11px',
            fontSize: '12px',
        },
        stepsContainer: {
            borderRight: '1px solid #8B92A7',
        },
        stepsContents: {
            borderLeft: '1px solid #8B92A7',
            paddingLeft: '30px',
        },
        stepDetails: {
            marginRight: 33,
            textAlign: 'right',
        },
        stepDescription: {
            color: '#8B92A7',
            fontSize: '12px',
            lineHeight: '20px',
            textAlign: 'right',
        },
        stepInfoContainer: {
            display: 'fixed',
            width: '100%',
            marginBottom: '18px',
        },
        stepInfo: {
            display: 'flex',
            float: 'right',
        },
        stepName: stepName(),
        stepNameActive: {
            ...stepName(),
            fontWeight: 600,
        },
        stepIcon: stepIcon(),
        stepIconCompleted: {
            ...stepIcon(),
            cursor: 'pointer',
        },
        previousButton: {
            boxSizing: 'border-box',
            height: '48px',
            width: '368px',
            border: '2px solid #002B99',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
        },
        paper: paper(theme),
        pageHeader: pageHeader(),
        policyInfoHeading: {
            lineHeight: '41px',
            fontSize: '24px',
            fontWeight: 500,
        },
        policyInfoLabel: {
            lineHeight: '12px',
            letterSpacing: 0,
            fontSize: '12px',
            marginBottom: '8px',
        },
        dynamicAddButton: {
            backgroundColor: '#DEECFF',
            color: '#002B99',
            textTransform: 'capitalize',
            maxWidth: '368px',
            height: '48px',
        },
        policyInfoValue: {
            lineHeight: '20px',
            letterSpacing: 0,
            fontSize: '14px',
            fontWeight: 600,
        },
        policyInfo: {
            lineHeight: '27px',
            letterSpacing: 0,
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '12px',
            marginBottom: '16px',
        },
        doctorsHeading: {
            fontWeight: 600,
            marginTop: '30px',
            fontSize: '1rem',
        },
        doctorsInnerHeading: {
            fontWeight: 700,
            marginTop: '30px',
        },
        button: button(),
        buttonGroup: buttonGroup(theme),
        fullWidthButton: fullWidthButton(),
        leftButton: leftButton(),
        rightButton: rightButton(),
        spacedTypography: spacedTypography(theme),
        marginTop: marginTop(theme),
        marginBottom: marginBottom(theme),
        marginRight: marginRight(theme),
        marginLeft: marginLeft(theme),
        padding: padding(theme),
        paddingTop: paddingTop(theme),
        paddingRight: paddingRight(theme),
        paddingBottom: paddingBottom(theme),
        paddingLeft: paddingLeft(theme),
        stepper: stepper(theme),
        searchDivider: searchDivider(theme),
        stepperItem: stepperItem(),
        stepperArrow: stepperArrow(theme),
        stepperOverLayWrapper: stepperOverLayWrapper(),
        error: {
            color: theme.palette.error.main,
        },
        componentHeader: {
            height: '41px',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '41px',
        },
        componentIntro: {
            marginTop: '22px',
            marginLeft: '8px',
            marginBottom: '25.15px',
            height: '30px',
            fontSize: '22px',
            fontWeight: 500,
            lineHeight: '30px',
        },
        currentStep: currentStep(),
        step: step(),
        stepperDiv: stepperDiv(),
        activeCircle: activeCircle(),
        completedCirle: completedCirle(),
        inactiveCircle: inactiveCircle(),
        contentText: {
            marginTop: theme.spacing(2),
            maxWidth: '368px',
        },
        infoRecordHeading: {
            color: '#0D1325',
            fontSize: '20px',
            fontWeight: 500,
            letterSpacing: '0.14px',
            height: '34px',
            lineHeight: '34px',
            marginTop: '32px',
        },
        infoRecordName: {
            fontWeight: 600,
            paddingLeft: '7px',
            height: '27px',
            lineHeight: '27px',
        },
        infoRecordDate: {
            height: '27px',
            lineHeight: '27px',
        },
        infoRecIcon: {
            ...completedCirle(),
            height: '20px',
            width: '20px',
            marginTop: '4px',
        },
        grayTitle: {
            padding: '15px',
            paddingLeft: '24px',
            marginTop: '24px',
            borderRadius: '4px',
            backgroundColor: '#C5C7D2',
        },
        grayTitleText: {
            color: '#0D1325',
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '24px',
        },
        summaryStepName: {
            height: '27px',
            lineHeight: '27px',
            fontWeight: 600,
            marginTop: '16px',
        },
        summaryLabel: {
            lineHeight: '27px',
            marginTop: '5px',
            marginBottom: '5px',
            paddingRight: '8px',
        },
        summaryValue: {
            lineHeight: '27px',
            fontWeight: 300,
            marginTop: '5px',
            marginBottom: '5px',
        },
        summaryNotesLabel: {
            marginBottom: '9px',
            marginTop: '5px',
            height: '20px',
            fontSize: '12px',
            lineHeight: '20px',
        },
    });
}

function sectionLabel() {
    return {
        marginLeft: '8px',
        marginBottom: '15px',
        height: '27px',
        color: '#0D1325',
        fontFamily: 'Noto Sans',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '27px',
    };
}

export function fullWidthButton() {
    return {
        width: '368px',
    };
}
function stepName() {
    return {
        color: '#0D1325',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'right',
    };
}

function stepIcon() {
    return {
        ...stepperItem(),
        backgroundColor: 'white',
        borderRadius: '50%',
        height: '27px',
        width: '27px',
        marginRight: '-13px',
        border: '0px',
    };
}

function stepperDiv() {
    return {
        border: '1px solid #AFB3C2',
        borderRadius: '4px',
        width: '100%',
        backgroundColor: '#FAFAFF',
        paddingTop: '18px',
        paddingBottom: '19px',
        paddingLeft: '24px',
        alignItems: 'center',
        flexDirection: 'row',
        height: '64px',
    };
}

function completedCirle() {
    return {
        color: '#36B37E',
        height: '27px',
        width: '27px',
        marginTop: '-8px',
        marginBottom: '-10px',
        marginLeft: '-1.5px',
        textAlign: 'center',
    };
}

function activeCircle() {
    return {
        paddingTop: '2px',
        borderRadius: '50%',
        height: '27px',
        width: '27px',
        border: '0.5px solid',
        backgroundColor: '#002B99',
        color: '#ffffff',
        textAlign: 'center',
    };
}

function inactiveCircle() {
    return {
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        border: '1px solid #002B99',
        color: '#002B99',
        textAlign: 'center',
    };
}

function searchDivider() {
    return {
        boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
        backgroundColor: '#ffffff',
        height: '1px',
        width: '100%',
    };
}

function marginTop(theme) {
    return {
        marginTop: theme.spacing(2),
    };
}

function marginRight(theme) {
    return {
        marginRight: theme.spacing(2),
    };
}

function marginLeft(theme) {
    return {
        marginLeft: theme.spacing(2),
    };
}

function marginBottom(theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

function padding(theme) {
    return {
        padding: theme.spacing(2),
    };
}

function paddingLeft(theme) {
    return {
        paddingLeft: theme.spacing(2),
    };
}

function paddingRight(theme) {
    return {
        paddingRight: theme.spacing(2),
    };
}

function paddingTop(theme) {
    return {
        paddingTop: theme.spacing(2),
    };
}

function paddingBottom(theme) {
    return {
        paddingBottom: theme.spacing(2),
    };
}

export function paper(theme) {
    return {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        height: '100%',
    };
}

function stepper() {
    return {
        position: 'sticky',
        top: '64px',
        zIndex: 10 * 1000,
        boxShadow: ['none'],
    };
}

function stepperItem() {
    return {
        display: 'inline-block',
        textAlign: 'center',
    };
}

function currentStep() {
    return {
        color: '#0D1325',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
    };
}

function step() {
    return {
        color: '#0D1325',
    };
}

function stepperArrow() {
    return {
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'inline-block',
        textAlign: 'right',
        float: 'right',
    };
}
function button() {
    return {
        backgroundColor: '#002B99',
        width: '368px',
        maxWidth: '368px',
        boxShadow: ['none'],
        textTransform: 'none',
        height: '48px',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 1.69,
        '&:hover': {
            background: '#214EBF',
            borderColor: '#214EBF',
            color: '#FFFFFF',
        },
    };
}

function rightButton() {
    return {
        backgroundColor: '#002B99',
        width: '176px',
        marginLeft: '16px',
        boxShadow: ['none'],
        textTransform: 'none',
        height: '48px',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 1.69,
        '&:hover': {
            background: '#214EBF',
            borderColor: '#214EBF',
            color: '#FFFFFF',
        },
    };
}

function buttonGroup(theme) {
    return {
        width: '368px',
        maxWidth: '368px',
        marginTop: theme.spacing(2),
    };
}

function leftButton() {
    return {
        backgroundColor: 'white',
        border: '2px solid #002B99',
        color: '#002B99',
        width: '176px',
        boxShadow: ['none'],
        textTransform: 'none',
        height: '48px',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '1px',
        '&:hover': {
            background: '#214EBF',
            borderColor: '#214EBF',
            color: '#FFFFFF',
        },
    };
}

function pageHeader() {
    return {
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: 1.71,
        color: '#0d1325',
        paddingTop: '5px',
    };
}

function spacedTypography(theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

function stepperOverLayWrapper() {
    return {
        zIndex: '1',
    };
}
