import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { requiredValidator } from '../../forms/validations';
import {
    Condition,
    FixedWidthButtonGroup,
    FixedWidthRadioGroup,
    FixedWidthRadioOption,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
} from '../../forms';
import { BankNames } from '../../shared';
import { NumberMask } from '../../forms/input-masks';
import { Navigation } from '../common/navigation';

const bankingInformation = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    const prefix = 'claimantStatement.bankingInformation';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Banking Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    name={`${prefix}.premiumPayingAccount`}
                    options={['Yes', 'No']}
                    label="If your claim is approved, do you wish for money to be paid into the premium paying account?"
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={submitted}
                />
            </Grid>
            <Condition when={`${prefix}.premiumPayingAccount`} is="No">
                <True>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}.accountHoldersName`}
                            placeholder="Enter account holder's name"
                            labelprefixed="true"
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}.bankName`}
                            placeholder="Enter bank name"
                            labelprefixed="true"
                            options={BankNames}
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}.accountNumber`}
                            placeholder="Enter account number"
                            labelprefixed="true"
                            mask={NumberMask}
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}.bankBranch`}
                            label="Branch Name"
                            placeholder="Enter branch name"
                            labelprefixed="true"
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}.branchCode`}
                            placeholder="Enter branch code"
                            labelprefixed="true"
                            mask={NumberMask}
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FixedWidthRadioGroup
                            name={`${prefix}.accountType`}
                            labelprefixed="true"
                            style={{ display: 'grid' }}
                            validate={requiredValidator}
                            disabled={submitted}
                        >
                            <FixedWidthRadioOption value="Current" />
                            <FixedWidthRadioOption value="Savings" />
                            <FixedWidthRadioOption value="Transmission" />
                        </FixedWidthRadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FixedWidthRadioGroup
                            name={`${prefix}.accountHolderRelationship`}
                            labelprefixed="true"
                            style={{ display: 'grid' }}
                            validate={requiredValidator}
                            disabled={submitted}
                        >
                            <FixedWidthRadioOption value="Own" />
                            <FixedWidthRadioOption value="Joint" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </True>
            </Condition>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const BankingInformation = withStyles(styles)(bankingInformation);
