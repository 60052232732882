import * as React from 'react';

import { Field } from 'react-final-form';
import { FormControl } from '../form-control';
import { RadioGroupField } from '../radio-group-field';

const fixedWidthRadioGroup = ({ name, ...rest }) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                // @ts-ignore
                component={RadioGroupField}
            />
        </FormControl>
    );
};

export const FixedWidthRadioGroup = fixedWidthRadioGroup;
