import axios from 'axios';
import { env } from '../../env/env';
import { logout } from '../authentication-service';
import { setActiveUserActionCreator } from './set-active-user';

const logoutUri = `${env && env.ClaimsLiveAPI}/api/Logout`;

export const logOutThunk = () => {
    return async dispatch => {
        dispatch(setActiveUserActionCreator(undefined));
        try {
            await axios.post(logoutUri);
        } catch (err) {
            console.error(err);
        }
        logout();
    };
};
