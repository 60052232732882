import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { OutlinedTextAreaField } from '../../forms';
import failIcon from '../../styles/icons/fail.svg';
import styles from '../../styles/styles';

const terminateView = () => {
    const referenceId = sessionStorage.getItem('referenceId');
    return (
        <Grid container justify="center">
            <img
                src={failIcon}
                alt="terminate"
                style={{
                    display: 'block',
                    margin: '-20px auto 12px auto',
                    paddingTop: 0,
                    width: '50%',
                }}
            />
            <Grid item xs={12}>
                <Typography style={{ textAlign: 'center' }}>Terminate Tele-Claim!</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ textAlign: 'center' }}>Ref No - {referenceId}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ textAlign: 'center', width: '368px', marginTop: '12px' }}>
                    Please could you provide a reason for the call being terminated
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '8px' }}>
                <OutlinedTextAreaField
                    name="introduction.reasonForTerminating"
                    label="Reason for client not wanting a call back"
                    placeholder="your reason"
                    labelprefixed={'true'}
                />
            </Grid>
        </Grid>
    );
};

export const TerminateView = withStyles(styles)(terminateView);
