import { CLOSE_MODAL, OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from '../types';

export const openModalAction = options => {
    return {
        type: OPEN_MODAL,
        payload: {
            styles: options.styles,
            modalContent: options.modalContent,
            modalData: options.modalData,
        },
    };
};

export const updateCurrentModalDataAction = modalData => {
    return {
        type: UPDATE_CURRENT_MODAL_DATA,
        payload: {
            modalData,
        },
    };
};

export const closeModalAction = () => {
    return {
        type: CLOSE_MODAL,
    };
};
