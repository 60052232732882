import * as React from 'react';

import { Paper, withStyles } from '@material-ui/core';
import dimPositiveArrow from '../../styles/icons/positive-arrow-dim.svg';
import styles from '../../styles/styles';
import { StepImage } from '../common/step-image';

function Stepper({ classes, components, activeIndex, stepThrough, updateIndex }) {
    return (
        <Paper className={[classes.stepper, classes.stepperOverLayWrapper].join(' ')}>
            <div className={classes.stepperDiv}>
                {components.map((stepComponent, stepIndex) => {
                    const isCurrentStep = stepIndex === activeIndex;
                    const classNames = [classes.stepperItem];

                    if (isCurrentStep && !stepThrough) {
                        classNames.push(classes.currentStep);
                    } else {
                        classNames.push(classes.step);
                    }

                    if (stepThrough || stepIndex < activeIndex) {
                        classNames.push(classes.pointerCursor);
                    }

                    return (
                        <div
                            key={`stepper-${stepIndex}`}
                            className={classNames.join(' ')}
                            onClick={() => updateIndex(stepIndex)}
                        >
                            {
                                <React.Fragment>
                                    <StepImage
                                        currentIndex={stepIndex}
                                        activeIndex={activeIndex}
                                        submitted={stepThrough}
                                    />
                                    <span style={{ marginLeft: '8px' }}>{stepComponent.name}</span>
                                </React.Fragment>
                            }
                            {stepIndex < components.length - 1 && (
                                <div className={classes.stepperArrow}>
                                    <img
                                        alt="Bottom"
                                        style={{ verticalAlign: 'bottom' }}
                                        src={dimPositiveArrow}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </Paper>
    );
}

export const HorizontalStepper = withStyles(styles)(Stepper);
