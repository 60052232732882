import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { addressTypes, getCodeLabel } from '../../shared';

const addressDetails = ({ classes, addressDetails }) => {
    if (!addressDetails) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            {addressDetails.addressType && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {getCodeLabel(addressTypes(), addressDetails.addressType)}
                </Grid>
            )}
            {addressDetails.addressLine1 && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.addressLine1}
                </Grid>
            )}
            {addressDetails.addressLine2 && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.addressLine2}
                </Grid>
            )}
            {addressDetails.addressLine3 && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.addressLine3}
                </Grid>
            )}
            {addressDetails.addressLine4 && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.addressLine4}
                </Grid>
            )}
            {addressDetails.suburb && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.suburb}
                </Grid>
            )}
            {addressDetails.town && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.town}
                </Grid>
            )}
            {addressDetails.postalCode && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.postalCode}
                </Grid>
            )}
            {addressDetails.province && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.province}
                </Grid>
            )}
            {addressDetails.country && (
                <Grid item xs={12} className={classes.screeningQuestions}>
                    {addressDetails.country}
                </Grid>
            )}
        </Grid>
    );
};

export const AddressDetails = withStyles(styles)(addressDetails);
