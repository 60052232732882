import * as React from 'react';

import { Field } from 'react-final-form';
import { FormControl } from '..';
import { ToggleButtonGroup } from '../toggle-button-group';

const fixedWidthButtonGroup = ({ name, options, ...rest }) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                // @ts-ignore
                component={ToggleButtonGroup}
                options={options}
            />
        </FormControl>
    );
};

export const FixedWidthButtonGroup = fixedWidthButtonGroup;
