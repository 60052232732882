import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { OutlinedTextAreaField } from '../../forms';
import { Navigation } from '../common/navigation';

const process = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Process</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Please note that we will still require you to supply us with all the necessary
                    medical documentation. However, I will stipulate all these once we have
                    completed the questionnaire.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Do you have any questions before we continue with the questionnaire?
                </Typography>
            </Grid>
            <OutlinedTextAreaField
                name="introduction.clientQuestions"
                label="Client Questions"
                placeholder="any questions the client has"
                disabled={submitted}
            />
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Please stop me to clarify if there is anything that you do not understand or
                    that you are unsure about.
                </Typography>
            </Grid>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const Process = withStyles(styles)(process);
