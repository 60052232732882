import * as React from 'react';

import { Grid, Modal, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import styles from '../styles';

// tslint:disable-next-line:class-name
class modalView extends React.Component {
    static openModalIds = [];

    constructor(props) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(nextProps) {
        if (!this.props.modalState.activeModal || !nextProps.modalState.activeModal) {
            return true;
        }

        if (!this.state.modalId) {
            return true;
        }

        if (modalView.openModalIds.includes(this.state.modalId)) {
            return true;
        }

        if (nextProps.modalState.activeModal.modalId !== this.state.modalId) {
            return false;
        }

        return (
            this.props.modalState.activeModal.modalData !==
                nextProps.modalState.activeModal.modalData ||
            this.props.modalState.activeModal.modalContent !==
                nextProps.modalState.activeModal.modalContent
        );
    }
    componentDidUpdate() {
        if (!this.props.modalState.activeModal) {
            return;
        }

        if (this.state.modalId) {
            return;
        }

        const modalId = this.props.modalState.activeModal.modalId;

        if (modalView.openModalIds.includes(modalId)) {
            return;
        }

        this.setState({ modalId });
        modalView.openModalIds.push(modalId);
    }

    render() {
        const {
            classes,
            modalState: { activeModal },
        } = this.props;

        if (
            !activeModal ||
            (!!this.state.modalId && !modalView.openModalIds.includes(this.state.modalId))
        ) {
            return null;
        }

        return (
            <Modal open={true} className={classes.modal}>
                <Grid container spacing={0} justify="center" className={classes.modalItem}>
                    <ReduxToastr
                        timeOut={5000}
                        newestOnTop={true}
                        preventDuplicates
                        position="top-right"
                        transitionIn="bounceIn"
                        transitionOut="fadeOut"
                        progressBar
                    />
                    <Grid item xs={12} lg={10}>
                        <Paper
                            className={classes.paper}
                            style={{ ...activeModal.styles, margin: 'auto' }}
                        >
                            {activeModal.modalContent}
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    componentWillUnmount() {
        modalView.openModalIds.pop();
    }
}

const mapStateToProps = state => ({
    modalState: state.formModal,
});

const mapDispatchToProps = {};

export const ModalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(modalView));
