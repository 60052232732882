import React from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { generateSummaryPdf } from '../actions/generate-summary';
import { StepImage } from '../common/step-image';
import { ClaimantStatementSummary } from './claimant-statement';
import { ConclusionSummary } from './conclusion';
import { IntroductionSummary } from './introduction';
import { RequirementsSummary } from './requirements';
import { submitToClaimsLive } from '../actions/submit';

const summary = ({
    classes,
    form,
    generateSummaryPdf,
    handleBack,
    submitted,
    submitToClaimsLive,
}) => {
    return (
        <Grid container justify="flex-start" style={{ marginTop: 16 }}>
            <Grid item style={{ width: '280px' }}>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'right', marginRight: '20px', marginBottom: '26px' }}
                >
                    <Typography
                        style={{
                            fontSize: '24px',
                            lineHeight: '30px',
                            letterSpacing: '0.17px',
                        }}
                    >
                        Interview Summary
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'right', marginRight: '20px', marginBottom: '26px' }}
                >
                    <Typography>This is a summary of the tele-claim interview</Typography>
                </Grid>
                <Grid style={{ marginRight: '33px', marginTop: '80px' }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="flex-start"
                        className={classes.stepsContainer}
                        style={{
                            height: 24,
                        }}
                    >
                        <div className={classes.stepInfoContainer}>
                            <div className={classes.stepInfo}>
                                <Typography className={classes.stepDetails}>
                                    <span className={classes.stepNameActive}>Summary</span>
                                </Typography>
                                <div className={classes.stepIcon}>
                                    <StepImage currentIndex={0} activeIndex={0} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.stepsContents} style={{ width: '836px' }}>
                <Grid item xs={12}>
                    <Typography className={classes.utilSteps}>Step 1 of 1</Typography>
                </Grid>
                <Grid item xs={12} container justify="flex-start">
                    <Typography className={classes.activeStepperCircle}>1</Typography>
                    <Typography className={classes.contentHeader}>Summary</Typography>
                </Grid>
                <IntroductionSummary />
                <ClaimantStatementSummary />
                <RequirementsSummary />
                <ConclusionSummary />
                <Grid item xs={12} container style={{ marginTop: '16px' }}>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            className={classes.leftButton}
                            onClick={() => handleBack(form)}
                            id="summary-back-button"
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.rightButton}
                            onClick={() => {
                                const teleClaim = form.getState().values;
                                generateSummaryPdf(teleClaim, classes);
                            }}
                            id="generate-pdf"
                        >
                            Generate PDF
                        </Button>
                        {!submitted && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.rightButton}
                                onClick={() => {
                                    const teleClaim = form.getState().values;
                                    submitToClaimsLive(teleClaim);
                                }}
                                id="submit-button"
                            >
                                Submit
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    generateSummaryPdf: generateSummaryPdf,
    submitToClaimsLive: submitToClaimsLive,
};

export const InterviewSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(summary));
