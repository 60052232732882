import { SET_ACTIVE_USER } from './actions/action-types';

export const authenticationReducer = (state = null, action) => {
    switch (action.type) {
        case SET_ACTIVE_USER:
            return action.payload;
        default:
            return state;
    }
};
