import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { AssessorsNotes } from './common/assessors-notes';
import { IncludeInClientSummary } from './common/include-in-client-summary';
import { LabelValue } from './common/label-value';

const conclusion = ({ classes, conclusion, references }) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>Section 4 - Conclusion</Typography>
            </Grid>
            <Declaration classes={classes} conclusion={conclusion} />
            <ContactDetails classes={classes} conclusion={conclusion} references={references} />
        </Grid>
    );
};

const Declaration = ({ classes, conclusion }) => {
    const notes = (conclusion && conclusion.notes) || [];
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Declaration</Typography>
            </Grid>
            <LabelValue
                label="As per your telephonic claim submission you declare that you have answered all questions in full and truthfully to the best of your ability"
                value={conclusion.confirmed}
            />
            <IncludeInClientSummary
                include="conclusion.includeDeclaration"
                label="Declaration Notes"
                name="conclusion.declaration"
                placeholder="Enter declaration notes"
                value={conclusion.declaration}
            />
            <AssessorsNotes id="declaration" notes={notes[0]} />
        </Grid>
    );
};

const ContactDetails = ({ classes, conclusion, references }) => {
    const notes = (conclusion && conclusion.notes) || [];
    const reference = references[0] || {};
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Contact Details</Typography>
            </Grid>
            <LabelValue label="Claim Reference Number" value={reference.referenceId} />
            <LabelValue label="Concierge Contact Details" value={conclusion.telephoneNumber} />
            <IncludeInClientSummary
                include="conclusion.includeAdditionalAssistance"
                label="Additional Assistance Requested"
                name="conclusion.additionalAssistance"
                placeholder="Enter notes for additional assistance"
                value={conclusion.additionalAssistance}
            />
            <AssessorsNotes id="contact-details" notes={notes[1]} />
        </Grid>
    );
};

const mapStateToProps = state => ({
    conclusion: (state.teleClaim && state.teleClaim.conclusion) || {},
    references: (state.teleClaim && state.teleClaim.references) || [],
});

const mapDispatchToProps = {};

export const ConclusionSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(conclusion));
