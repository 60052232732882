import { CLAIM_SUMMARY_LOADED, TELE_CLAIM_LOADED } from './actions/types';

export const initialClaimSummary = {};
export const claimSummaryReducer = (state = initialClaimSummary, action) => {
    if (action.type === CLAIM_SUMMARY_LOADED) {
        return action.payload;
    }
    return state;
};

const initialTeleClaim = {};
export const teleClaimReducer = (state = initialTeleClaim, action) => {
    if (action.type === TELE_CLAIM_LOADED) {
        return action.payload;
    }
    return state;
};
