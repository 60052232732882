import React from 'react';

import { connect } from 'react-redux';
import { getAuthTokenThunk } from './actions/get-auth-token';

class AuthenticatedViewClass extends React.Component {
    componentDidMount() {
        if (this.props.disableLogin) {
            return;
        }

        this.props.getAuthToken();
    }

    render() {
        const { children = null, unauthorizedView = null, authState } = this.props;
        if (!authState) {
            return <React.Fragment>{unauthorizedView}</React.Fragment>;
        }

        return <React.Fragment>{children}</React.Fragment>;
    }
}

const mapStateToProps = state => ({
    authState: state.authentication,
});

const mapDispatchToProps = {
    getAuthToken: getAuthTokenThunk,
};

export const AuthenticatedView = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticatedViewClass);
