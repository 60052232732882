import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import { getAccount, redirectToLogin, sleep, tryGetTokenSilent } from '../authentication-service';
import { setActiveUserActionCreator } from './set-active-user';

export const getAuthTokenThunk = () => {
    return async dispatch => {
        const accessToken = await getAccessToken(dispatch);
        if (accessToken) {
            dispatch(setActiveUserActionCreator(getAccount()));
        }
        return accessToken;
    };
};

async function getAccessToken(dispatch) {
    dispatch(showLoading());
    let token = await tryGetTokenSilent();
    if (token) {
        dispatch(hideLoading());
        return token.accessToken;
    }
    // sometimes the library takes time to set the token :/
    await sleep(5000);
    token = await tryGetTokenSilent();
    if (token) {
        dispatch(hideLoading());
        return token.accessToken;
    }
    redirectToLogin();
    return '';
}
