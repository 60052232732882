import * as React from 'react';

import { FormHelperText, Typography, withStyles } from '@material-ui/core';
import { getComponentId, nameToLabel, processMetaForErrors } from '../../form-component-utilities';
import { callAllFunctions } from '../..';
import styles from '../styles';
import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';

const toggleButtonGroup = ({
    classes,
    label,
    helperText,
    hideLabel,
    onChange,
    id,
    options,
    input,
    meta,
    disabled,
    ...rest
}) => {
    const { name, value } = input;
    const _id = getComponentId({ id, name });
    const { errorMessage, showError } = processMetaForErrors(meta);

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography
                    className={classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <MuiToggleButtonGroup
                {...rest}
                id={_id}
                value={value}
                onChange={callAllFunctions(onChange, input.onChange)}
                className={classes.toggleButtonGroup}
            >
                {options.map((option, index) => {
                    return (
                        <ToggleButton
                            key={`${name}-${index}`}
                            id={`${name}${index}`}
                            name={name}
                            classes={{
                                label:
                                    value === option
                                        ? classes.overWriteSelectedLabel
                                        : classes.overWriteLabel,
                                selected: classes.overWriteSelected,
                            }}
                            className={classes.toggleButton}
                            value={option}
                            style={{ display: 'inline-table', textAlign: '-webkit-center' }}
                            disabled={disabled}
                        >
                            {option}
                        </ToggleButton>
                    );
                })}
            </MuiToggleButtonGroup>
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export const ToggleButtonGroup = withStyles(styles)(toggleButtonGroup);
