import * as React from 'react';
import { DialogContentText, Grid, withStyles } from '@material-ui/core';
import { OutlinedDatePicker, OutlinedSearchSelectField } from '../../forms';
import { requiredValidator } from '../../forms/validations';
import { CallTimes } from '../../shared';
import styles from '../../styles/styles';

const callBackView = ({ classes }) => {
    return (
        <div>
            <DialogContentText className={classes.dialogContent}>
                Please could you provide me with a date and time that is convenient for you?
            </DialogContentText>
            <Grid item xs={12} style={{ textAlign: 'justify' }}>
                <Grid item xs={12} lg={12}>
                    <OutlinedDatePicker
                        id="date"
                        validate={requiredValidator}
                        name="callBackDetails.date"
                        labelprefixed={'true'}
                        disablePast
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OutlinedSearchSelectField
                        id="time"
                        name="callBackDetails.time"
                        labelprefixed={'true'}
                        validate={requiredValidator}
                        options={CallTimes}
                        maxMenuHeight="90px"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export const CallBackView = withStyles(styles)(callBackView);
