import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';

const labelValue = ({ classes, label, value }) => {
    if (!value) {
        return null;
    }
    const values = getValues(value);
    return (
        <Grid container justify="flex-start">
            <Grid item xs={6}>
                <Typography className={classes.summaryLabel}>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
                {values.map((val, index) => {
                    return (
                        <Typography key={index} className={classes.summaryValue}>
                            {val}
                        </Typography>
                    );
                })}
            </Grid>
        </Grid>
    );
};

function getValues(value) {
    if (Array.isArray(value)) {
        let result = [];
        value.map(val => tokenizeString(val)).forEach(values => (result = result.concat(values)));

        return result;
    }

    return tokenizeString(value);
}

function tokenizeString(value) {
    if (typeof value === 'string') {
        const paragraphs = value.split('\n');
        const markedUpParagraphs = paragraphs.map(item => {
            return item === '' ? <br /> : item;
        });
        return markedUpParagraphs;
    }

    return [value];
}

export const LabelValue = withStyles(styles)(labelValue);
