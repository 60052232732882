import * as React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import PhoneInput from 'material-ui-phone-number';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';
import { TextField } from '../text-field';

const phoneField = ({
    id,
    input,
    classes,
    meta,
    label,
    hideLabel,
    helperText,
    onChange,
    shaded = true,
    ...rest
}) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const labelStyle = showError ? { color: '#cd003d' } : {};
    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography className={classes.cssLabel} style={labelStyle}>
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <PhoneInput
                {...rest}
                id={getComponentId({ id, name })}
                name={name}
                value={value}
                defaultCountry={'za'}
                dropdownClass={classes.dropContainerPos}
                InputProps={{
                    component: TextField,
                    autoComplete: nameToLabel({ label, name }),
                    classes: {
                        root: shaded ? classes.cssOutlinedInputShaded : classes.cssOutlinedInput,
                    },
                }}
                error={showError}
                helperText={showError ? errorMessage : helperText}
                onChange={callAllFunctions(onChange, input.onChange)}
                variant="outlined"
                fullWidth
            />
        </div>
    );
};

export const PhoneField = withStyles(styles)(phoneField);
