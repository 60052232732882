import * as React from 'react';

import { Field } from 'react-final-form';
import { nameToLabel } from '../..';
import { Radio } from '../radio';

export const FixedWidthRadioOption = ({ value, ...rest }) => {
    const { name, label } = rest;
    return (
        <Field
            {...rest}
            name={name || ''}
            // @ts-ignore
            component={Radio}
            type="radio"
            value={value}
            label={nameToLabel({ label, name: value })}
        />
    );
};
