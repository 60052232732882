import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { logOutThunk } from '../../authentication/actions/log-out';
import { openDialogAction } from '../../forms/confirmation-dialog';
import styles from '../../styles/styles';
import { VerticalStepper } from '../steppers/vertical-stepper';
import { Disclaimer } from './disclaimer';
import { Process } from './process';
import { Screening } from './screening';
import { Welcome } from './welcome';

const components = [
    { name: 'Welcome', component: Welcome },
    { name: 'Disclaimer', component: Disclaimer },
    { name: 'Screening', component: Screening },
    { name: 'Process', component: Process },
];

const introduction = ({ ...rest }) => {
    return <VerticalStepper name="Introduction" verticalComponents={components} {...rest} />;
};

const mapStateToProps = state => {
    const lifeAssured = (state.claimSummary && state.claimSummary.lifeAssured) || {};
    const currentUser = state.authentication || {};
    const references = state.teleClaim && state.teleClaim.references;
    return {
        lifeAssured,
        currentUser,
        references,
    };
};

const mapDispatchToProps = {
    logOut: logOutThunk,
    openDialog: openDialogAction,
};

export const Introduction = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(introduction));
