import * as React from 'react';

import { Form } from 'react-final-form';

const defaultSubscriptions = {
    submitting: true,
    pristine: true,
    submitError: true,
    hasValidationErrors: true,
};

export function GenericForm({
    subscription = defaultSubscriptions,
    initialValues,
    onSubmit,
    render,
    children,
    ...rest
}) {
    return (
        <Form
            subscription={subscription}
            render={render ? innerFormRender(render, initialValues, { ...rest }) : undefined}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...rest}
        >
            {children ? innerFormChildren(children, initialValues, { ...rest }) : undefined}
        </Form>
    );
}

function innerFormRender(form, initialValues, props) {
    // eslint-disable-next-line
    return renderProps => {
        return (
            <form onSubmit={renderProps.handleSubmit}>
                {form({ ...props, ...renderProps, initialValues })}
            </form>
        );
    };
}

function innerFormChildren(children, initialValues, props) {
    return renderProps => {
        if (typeof children === 'function') {
            return innerFormRender(children, props)(renderProps);
        } else {
            return (
                <form onSubmit={renderProps.handleSubmit}>
                    {React.Children.map(children, child => child).map(child =>
                        React.cloneElement(child, {
                            ...props,
                            ...renderProps,
                            initialValues,
                            ...child.props,
                        })
                    )}
                </form>
            );
        }
    };
}
