import React from 'react';
import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import { OutlinedDatePicker, OutlinedTextAreaField, OutlinedTextField } from '../../forms';
import { requiredValidator } from '../../forms/validations';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../forms/input-masks';
import { AddressDetails } from '../common/address-details';
import { CustomExpansionPanel } from '../../forms/form-components/custom-expansion-panel';
import styles from '../../styles/styles';
import { convertToUpper } from '../../shared';

const doctorVisits = ({ classes, form, prefix, submitted }) => {
    const { push } = form.mutators;
    return (
        <React.Fragment>
            <Typography className={classes.doctorsHeading}>
                Doctor&apos;s and Practice Details
            </Typography>
            <FieldArray name={`${prefix}.doctorsVisits`}>
                {({ fields }) =>
                    fields.map((doctor, doctorIndex) => {
                        return doctorsVisitRow(
                            prefix,
                            fields,
                            classes,
                            form,
                            doctorIndex,
                            submitted
                        );
                    })
                }
            </FieldArray>
            <Divider />
            {!submitted && (
                <Button
                    variant="contained"
                    color="secondary"
                    className={[classes.dynamicAddButton, classes.marginTop].join(' ')}
                    onClick={() => push(`${prefix}.doctorsVisits`, { visitOccurences: [{}] })}
                    id="add-visit"
                    fullWidth
                >
                    <AddCircleOutlined />
                    &nbsp; Add Doctor
                </Button>
            )}
        </React.Fragment>
    );
};

function doctorsVisitRow(prefix, fields, classes, form, doctorIndex, submitted) {
    const { push } = form.mutators;
    if (fields.length === 0) {
        fields.push(`${prefix}.doctorsVisits`, undefined);
    }

    return (
        <CustomExpansionPanel
            key={`${prefix}.doctorsVisit${doctorIndex + 1}`}
            defaultExpanded={true}
            style={{ marginTop: '20px', maxWidth: '368px', minWidth: '368px' }}
            name={`doctor${doctorIndex + 1}`}
            id={`doctor${doctorIndex + 1}`}
            label={`Doctor ${doctorIndex + 1}`}
            remove={() => fields.remove(doctorIndex)}
            deletable={!submitted}
        >
            <React.Fragment>
                <Typography className={classes.doctorsInnerHeading}>
                    Doctor&apos;s Details
                </Typography>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Initials"
                        name={`${prefix}.doctorsVisits[${doctorIndex}].initials`}
                        mask={InitialsMask}
                        parse={convertToUpper}
                        placeholder="Enter initials"
                        disabled={submitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Name"
                        name={`${prefix}.doctorsVisits[${doctorIndex}].firstName`}
                        mask={FirstNameMask}
                        placeholder="Enter name"
                        disabled={submitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Surname"
                        name={`${prefix}.doctorsVisits[${doctorIndex}].lastName`}
                        validate={requiredValidator}
                        mask={LastNameMask}
                        placeholder="Enter surname"
                        labelprefixed={'true'}
                        disabled={submitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Contact Number"
                        name={`${prefix}.doctorsVisits[${doctorIndex}].telephoneNumber`}
                        placeholder="Enter contact number"
                        mask={TelephoneNumberMask}
                        disabled={submitted}
                    />
                </Grid>
                <Typography className={classes.doctorsInnerHeading}>Practice Details</Typography>
                <AddressDetails
                    prefix={`${prefix}.doctorsVisits[${doctorIndex}].addressDetails.`}
                    isResidential={true}
                    submitted={submitted}
                />
                <FieldArray name={`${prefix}.doctorsVisits[${doctorIndex}].visitOccurences`}>
                    {({ fields }) =>
                        fields.map((visit, visitIndex) => {
                            return visitOccurenceRow(
                                prefix,
                                fields,
                                doctorIndex,
                                visitIndex,
                                submitted
                            );
                        })
                    }
                </FieldArray>
                {!submitted && (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={[classes.marginTop, classes.dynamicAddButton].join(' ')}
                        onClick={() =>
                            push(
                                `${prefix}.doctorsVisits[${doctorIndex}].visitOccurences`,
                                undefined
                            )
                        }
                        id={`add-occurence${doctorIndex}`}
                    >
                        <AddCircleOutlined /> &nbsp; Add Consultation
                    </Button>
                )}
            </React.Fragment>
        </CustomExpansionPanel>
    );
}

function visitOccurenceRow(prefix, fields, doctorIndex, visitIndex, submitted) {
    if (fields.length === 0) {
        fields.push(`${prefix}.doctorsVisits[${doctorIndex}].visitOccurences`, [{}]);
    }
    return (
        <CustomExpansionPanel
            key={`doctorsVisits${doctorIndex}visitOccurenceRow${visitIndex + 1}`}
            defaultExpanded={true}
            style={{ marginTop: '20px' }}
            name={`visit${visitIndex + 1}`}
            id={`visit${visitIndex + 1}`}
            label={`Reason for consultation ${visitIndex + 1}`}
            remove={() => fields.remove(visitIndex)}
            deletable={!submitted}
        >
            <React.Fragment>
                <Grid item xs={12}>
                    <OutlinedDatePicker
                        name={`${prefix}.doctorsVisits[${doctorIndex}].visitOccurences[${visitIndex}].date`}
                        disableFuture
                        disabled={submitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextAreaField
                        name={`${prefix}.doctorsVisits[${doctorIndex}].visitOccurences[${visitIndex}].reason`}
                        label="Reason"
                        placeholder="Enter reason"
                        disabled={submitted}
                    />
                </Grid>
            </React.Fragment>
        </CustomExpansionPanel>
    );
}

export const DoctorVisits = withStyles(styles)(doctorVisits);
