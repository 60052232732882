import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedDatePicker,
    OutlinedTextAreaField,
    OutlinedTextField,
    True,
    WhenFieldChanges,
} from '../../forms';
import { requiredValidator } from '../../forms/validations';
import informationIcon from '../../styles/icons/information.svg';
import { Navigation } from '../common/navigation';

const medicalQuestions = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    const prefix = 'claimantStatement.medicalQuestions';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Medical Questions</Typography>
            </Grid>
            <OutlinedTextAreaField
                name={`${prefix}.medicalReason`}
                id={`${prefix}.medicalReason`}
                label="What is the medical reason for the claim being submitted?"
                placeholder="Enter medical reason here"
                validate={requiredValidator}
                labelprefixed={'true'}
                disabled={submitted}
            />
            <div className={classes.userPrompts} style={{ marginTop: '16px' }}>
                <img src={informationIcon} alt="information" style={{ height: '27px' }} />
                <Typography className={classes.userPromptsText}>
                    <span className={classes.empathyTitle}>Empathy</span>
                    <br />
                    Once the client confirms his/her diagnosis one can show empathy by saying
                    something like :
                    <br />
                    <br />
                    I am really sorry that you fractured your leg. It must really be difficult to
                    get along with a cast.
                    <br />
                    <br />
                    Or
                    <br />
                    <br />
                    That is awful! I can just imagine the pain / inconvenience that you are going
                    through.
                </Typography>
            </div>
            <OutlinedDatePicker
                name={`${prefix}.diagnosisDate`}
                label="Please provide the date of diagnosis/date the event took place? "
                validate={requiredValidator}
                labelprefixed={'true'}
                disableFuture
                disabled={submitted}
            />
            <FixedWidthButtonGroup
                name={`${prefix}.conditionType`}
                options={['Disease', 'Accident']}
                validate={requiredValidator}
                label="The medical condition claimed for is due to"
                labelprefixed={'true'}
                disabled={submitted}
            />
            <Condition when={`${prefix}.conditionType`} is={'Accident'}>
                <True>
                    <FixedWidthButtonGroup
                        name={`${prefix}.reportedToPolice`}
                        options={['Yes', 'No']}
                        label="Was the accident reported to the police?"
                        labelprefixed={'true'}
                        validate={requiredValidator}
                        disabled={submitted}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.reportedToPolice`}
                        set={`${prefix}.policeStationReportedTo`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.reportedToPolice`}
                        set={`${prefix}.caseNumber`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}.reportedToPolice`}
                        set={`${prefix}.descriptionOfAccident`}
                        to={null}
                    />
                    <Condition when={`${prefix}.reportedToPolice`} is={'Yes'}>
                        <True>
                            <OutlinedTextField
                                label="Which police station was it reported to?"
                                name={`${prefix}.policeStationReportedTo`}
                                validate={requiredValidator}
                                placeholder="Enter police station name"
                                labelprefixed={'true'}
                                disabled={submitted}
                            />
                            <OutlinedTextField
                                label="What is your case number?"
                                name={`${prefix}.caseNumber`}
                                validate={requiredValidator}
                                placeholder="Enter case number"
                                labelprefixed={'true'}
                                disabled={submitted}
                            />
                            <OutlinedTextAreaField
                                label="Please provide a fully detailed description of the accident"
                                name={`${prefix}.descriptionOfAccident`}
                                validate={requiredValidator}
                                placeholder="Enter description of accident"
                                labelprefixed={'true'}
                                disabled={submitted}
                            />
                        </True>
                    </Condition>
                </True>
            </Condition>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const MedicalQuestions = withStyles(styles)(medicalQuestions);
