import * as React from 'react';

import { Field } from 'react-final-form';
import { FormControl } from '../form-control';
import { TextAreaField } from '../text-area';

export const OutlinedTextAreaField = ({ name, placeholder, type = 'text', ...rest }) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                placeholder={placeholder}
                // @ts-ignore
                component={TextAreaField}
                type={type}
            />
        </FormControl>
    );
};
