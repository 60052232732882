import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFullName } from '../../shared';
import styles from '../../styles/styles';
import { AssessorsNotes } from './common/assessors-notes';
import { IncludeInClientSummary } from './common/include-in-client-summary';
import { LabelValue } from './common/label-value';

function introduction({ claimSummary, classes, currentUser, introduction }) {
    const notes = (introduction && introduction.notes) || [];
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>Section 1 - Introduction</Typography>
            </Grid>
            <Welcome
                classes={classes}
                claimSummary={claimSummary}
                currentUser={currentUser}
                notes={notes[0]}
            />
            <Disclaimer classes={classes} notes={notes[1]} />
            <Screening classes={classes} notes={notes[2]} />
            <Process classes={classes} introduction={introduction} />
        </React.Fragment>
    );
}

const Welcome = ({ classes, claimSummary, currentUser, notes }) => {
    const contractNumbers =
        (claimSummary &&
            claimSummary.contracts &&
            claimSummary.contracts.map(c => c.policyNumber)) ||
        [];
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Welcome</Typography>
            </Grid>
            <LabelValue
                label="Life Assured"
                value={getFullName(claimSummary && claimSummary.lifeAssured)}
            />
            <LabelValue
                label="Assisted By Claims Concierge"
                value={currentUser && currentUser.name}
            />
            <LabelValue label="Claim Type" value={(claimSummary && claimSummary.claimType) || ''} />
            <LabelValue label="Policy Number(s)" value={contractNumbers} />
            <LabelValue label="Client was available for discussion?" value="Yes" />
            <LabelValue label="Client advised of the reason for this call?" value="Yes" />
            <AssessorsNotes id="welcome" notes={notes} />
        </React.Fragment>
    );
};

const Disclaimer = ({ classes, notes }) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Disclaimer</Typography>
            </Grid>
            <LabelValue label="Advised of call recordings" value="Yes" />
            <AssessorsNotes id="disclaimer" notes={notes} />
        </Grid>
    );
};

const Screening = ({ classes, notes }) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Screening</Typography>
            </Grid>
            <LabelValue label="Performed security checks" value="Yes" />
            <AssessorsNotes id="screening" notes={notes} />
        </Grid>
    );
};

const Process = ({ classes, introduction }) => {
    const notes = (introduction && introduction.notes && introduction.notes[3]) || [];
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Process</Typography>
            </Grid>
            <IncludeInClientSummary
                include="introduction.includeInClientSummary"
                label="Client Questions"
                name="introduction.clientQuestions"
                placeholder="any questions the client has"
                value={introduction.clientQuestions}
            />
            <AssessorsNotes id="process" notes={notes} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    currentUser: state.authentication || {},
    claimSummary: state.claimSummary || {},
    introduction: (state.teleClaim && state.teleClaim.introduction) || {},
});

const mapDispatchToProps = {};

export const IntroductionSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(introduction));
