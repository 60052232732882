import _ from 'lodash';
import { DateTime } from 'luxon';
import { Titles } from '../lookups/codes';

export function getDisplayDate(date, format = 'dd/MM/yyyy') {
    if (!date) {
        return undefined;
    }
    var newDate = new Date(date);
    if (!isNaN(newDate.getTime())) {
        return DateTime.fromJSDate(newDate).toFormat(format);
    }
    return '';
}

export function convertToNumber(value) {
    if (!value) {
        return undefined;
    }

    const cleanValue = value.replace(/[^\d.]/g, '');

    return parseFloat(cleanValue);
}

export function convertToUpper(value) {
    if (!value) {
        return undefined;
    }

    return _.toUpper(value);
}

export const currencyFormat = value => {
    const currencyFormatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return currencyFormatter.format(value || 0);
};

export function getCodeLabel(codes, value) {
    if (!value || value === '') {
        return '';
    }
    const code = codes.filter(r => r.value === value)[0];
    return code ? code.label : value;
}

export function getFullName(details) {
    if (!details) {
        return '';
    }

    return titleCase(
        [getTitle(details.title), details.firstName, details.lastName]
            .filter(value => !!value && value !== '')
            .join(' ')
    );
}

export function getTitleLastName(details) {
    if (!details) {
        return '';
    }
    return titleCase([getTitle(details.title), details.lastName].filter(v => !!v && v !== ''));
}

export function getTitle(title) {
    return getCodeLabel(Titles, title);
}

export function titleCase(value) {
    if (!value) {
        return '';
    }
    return _.startCase(_.toLower(value));
}

export const generateEmailEvent = (callDetails, lifeAssured, currentUser) => {
    const FileSaver = require('file-saver');
    const ics = require('ics');
    const scheduledTime = callDetails.time.split(':');
    const fullName = getFullName(lifeAssured.personalDetails);
    const event = {
        start: [
            callDetails.date.getFullYear(),
            callDetails.date.getMonth() + 1,
            callDetails.date.getDate(),
            parseInt(scheduledTime[0]),
            parseInt(scheduledTime[1]),
        ],
        duration: { hours: 1, minutes: 0 },
        title: `Tele-Claim Reminder - ${
            lifeAssured.references.find(c => c.referenceId).referenceId
        }`,
        description: `Reminder to call the client to continue with the tele-claim call. 
            
            
            Client Name
            ${fullName}`,
        location: 'Telephonically',
        status: 'CONFIRMED',
        busyStatus: 'BUSY',
        organizer: { name: currentUser.name, email: currentUser.userId },
        attendees: [
            {
                name: fullName,
            },
        ],
    };

    // @ts-ignore
    ics.createEvent(event, (error, value) => {
        if (error) {
            console.log(error);
            return;
        }
        var blob = new Blob([value], { type: 'text/calender;charset=utf-8' });
        FileSaver.saveAs(blob, 'event.ics');
    });
};

export function prettifiedErrors(data) {
    var result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
            if (l === 0) result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + '.' + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    }

    recurse(data, '');

    const prettifiedErrorList = Object.entries(result).map(([label, value]) => {
        const labelParts = label.replace(/([a-z](?=[A-Z]))/g, '$1 ').split('.');
        const cleanedLabel = labelParts
            .splice(labelParts.length - 1, labelParts.length)
            .join(' ')
            .toLowerCase()
            .replace('an fa', 'an FA');
        return {
            label: cleanedLabel.charAt(0).toUpperCase() + cleanedLabel.slice(1),
            value: value,
        };
    });

    return prettifiedErrorList;
}

export function camelize(text) {
    if (!text) {
        return undefined;
    }
    return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
}
