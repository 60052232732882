import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Condition, False, True, When, WhenFieldChanges } from '../../forms';
import { OutlinedSearchSelectField, OutlinedTextField } from '../../forms/form-components';
import { NumberMask } from '../../forms/input-masks';
import { requiredValidator } from '../../forms/validations';
import { addressTypes, Provinces, Countries } from '../../shared/lookups/codes';
import styles from '../../styles/styles';

function addressDetails({ prefix, isResidential, submitted, shaded = false }) {
    return (
        <React.Fragment>
            <OutlinedSearchSelectField
                name={`${prefix}addressType`}
                label="Address Type"
                labelprefixed="true"
                validate={requiredValidator}
                options={addressTypes(isResidential)}
                disabled={submitted}
                placeholder="Select address type"
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isNotForeign}
                set={`${prefix}country`}
                to="South Africa"
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                becomes="Foreign"
                set={`${prefix}province`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLine1`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLine2`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLine3`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}addressType`}
                condition={isBoxOrBag}
                set={`${prefix}addressLine4`}
                to={null}
            />
            <Condition when={`${prefix}addressType`} hasValue>
                <True>
                    <Condition when={`${prefix}addressType`} matchesChild>
                        <When is="PrivateBag">
                            <OutlinedTextField
                                name={`${prefix}addressLine1`}
                                label="Private Bag"
                                validate={requiredValidator}
                                disabled={submitted}
                                shaded={shaded}
                            />
                        </When>
                        <When is="PostBox">
                            <OutlinedTextField
                                name={`${prefix}addressLine1`}
                                label="PO Box"
                                validate={requiredValidator}
                                disabled={submitted}
                                shaded={shaded}
                                mask={NumberMask}
                            />
                        </When>
                    </Condition>
                    <Condition when={`${prefix}addressType`} is={['Street', 'Foreign']}>
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}addressLine1`}
                                    label="Address Line 1"
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                    disabled={submitted}
                                    shaded={shaded}
                                    placeholder="Address line 1"
                                />
                            </Grid>
                            <OutlinedTextField
                                name={`${prefix}addressLine2`}
                                label="Address Line 2"
                                disabled={submitted}
                                placeholder="Address line 2"
                                shaded={shaded}
                            />
                            <OutlinedTextField
                                name={`${prefix}addressLine3`}
                                label="Address Line 3"
                                disabled={submitted}
                                shaded={shaded}
                                placeholder="Address line 3"
                            />
                            <OutlinedTextField
                                name={`${prefix}addressLine4`}
                                label="Address Line 4"
                                disabled={submitted}
                                shaded={shaded}
                                placeholder="Address line 4"
                            />
                        </True>
                    </Condition>
                    <OutlinedTextField
                        name={`${prefix}suburb`}
                        label="Town/Suburb"
                        labelprefixed="true"
                        validate={requiredValidator}
                        placeholder="Town/suburb"
                        disabled={submitted}
                        shaded={shaded}
                    />
                    <OutlinedTextField
                        name={`${prefix}town`}
                        label="City"
                        labelprefixed="true"
                        validate={requiredValidator}
                        disabled={submitted}
                        shaded={shaded}
                        placeholder="City"
                    />
                    <Condition when={`${prefix}addressType`} is={'Foreign'}>
                        <True>
                            <OutlinedTextField
                                name={`${prefix}postalCode`}
                                label="Postal Code"
                                disabled={submitted}
                                shaded={shaded}
                                placeholder="Postal code"
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}country`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                validate={requiredValidator}
                                disabled={submitted}
                                placeholder="Country"
                                shaded={shaded}
                            />
                        </True>
                        <False>
                            <OutlinedTextField
                                name={`${prefix}postalCode`}
                                label="Postal Code"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={submitted}
                                shaded={shaded}
                                mask={NumberMask}
                                placeholder="Postal code"
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}province`}
                                label="Province"
                                labelprefixed="true"
                                options={Provinces}
                                validate={requiredValidator}
                                disabled={submitted}
                                shaded={shaded}
                                placeholder="Province"
                            />
                            <OutlinedSearchSelectField
                                name={`${prefix}country`}
                                label="Country"
                                labelprefixed="true"
                                options={Countries}
                                disabled={true}
                                shaded={shaded}
                                placeholder="Country"
                            />
                        </False>
                    </Condition>
                </True>
            </Condition>
        </React.Fragment>
    );
}

export function isBoxOrBag(addressType) {
    return addressType === 'PostBox' || addressType === 'PrivateBag';
}

export function isNotForeign(addressType) {
    return addressType !== 'Foreign';
}

export const AddressDetails = withStyles(styles)(addressDetails);
