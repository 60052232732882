// see https://material-ui.com/style/color/#color-tool for more info

export const black = '#000000';
export const dustygray = 'rgba(0,0,0,0.54)';
export const caribbeangreen = '#00D9B1';
export const jewelpie = '#fbab18';
export const shiraz = '#C60F30';
export const bluetang = '#4669c4';
export const shamrock = '#37DB9B';
export const raspberry = '#cd003d';
export const white = '#F2F2F2';
export const deepocean = '#00164e';
export const theriddler = '#36b37e';
export const riverBed = '#424757';
export const dustyGray = '#9C9C9C';
export const alizarinCrimson = '#e6243a';
export const athensGray = '#f0f1f4';
export const burningOrange = '#ff6c35';
export const cornflowerblue = '#5581ef';
export const ebony = '#0d1325';
export const honeyFlower = '#532673';
export const manatee = '#8B92A7';
export const mantis = '#92c955';
export const oceangreen = '#36b37e';
export const puertoRico = '#4ac8c8';
export const royalPurple = '#803cb2';
export const stratos = '#00164e';
export const toreabay = '#0d3393';
export const webOrange = '#ffab00';
export const zumthor = '#e6edff';
export const slateGray = '#6f7b95';
export const sideSubmenuBlue = 'rgba(33, 78, 191, 0.7)';
export const smalt = 'rgba(0, 43, 153, 0.7)';
export const smalt2 = '#002B99';
export const atlantis = '#84C83A';
export const cadetBlue = '#AFB3C2';
export const tundora = '#4A4A4A';
export const dodgerBlue = '#4378FD';
export const midnight = '#01123E';
export const titanWhite = '#FAFAFF';
export const boulder = '#767676';
export const mirage = '#141A2B';
export const silverChalice = '#AEAEAE';
export const porcelain = '#F7F8F9';
//new notifications colors according to InVision designs
export const chromeWhite = '#E0F1CE';
export const hawkesBlue = '#E0E9FE';
export const azalea = '#F9C8CE';
export const colonialWhite = '#FFEABF';
export const shark = '#21242C';
export const internationalKleinBlue = '#0033AA';
export const whisper = '#F1F1F7';
export const gulfBlue = '#05104E';
export const toryBlue = '#173D9D';
export const indigo = '#4D71CB';
export const pomegranate = '#E85211';
export const blackOpacity = 'rgba(0,0,0,0.15)';
export const blackShadow = 'rgba(0,0,0,0.61)';

//color palette dark and light themes, only to be used in palette.jsx
export const bitterSweet = '#ff6265';
export const brightRed = '#ac0014';
export const cobalt = '#0055bc';
export const malibu = '#8eb0ff';
export const blueHaze = '#bbc2d8';
export const comet = '#5e6478';
export const blackRussian = 'rgba(0, 0, 0, 0.08)';

// old colors that will be removed from the project
export const overlayBlue = 'rgba(1, 18, 62, 0.8)';

//custom colors
export const alabaster = '#FCFCFC';

export const shadows = {
    simple: {
        webkitBoxShadow: '0 4px 5px 0px #CCCCCC',
        mozBoxShadow: '0 4px 5px 0px #CCCCCC',
        boxShadow: '0 4px 5px 0px #CCCCCC',
    },
    paperShadow: {
        webkitBoxShadow: '0 15px 60px 0 rgba(0,0,0,0.08)',
        boxShadow: '0 15px 60px 0 rgba(0,0,0,0.08)',
        mozBoxShadow: '0 15px 60px 0 rgba(0,0,0,0.08)',
    },
    paperShadowHover: {
        webkitBoxShadow: '0px 25px 80px -10px rgba(0,0,0,0.20)',
        boxShadow: '0px 25px 80px -10px rgba(0,0,0,0.20)',
        mozBoxShadow: '0px 25px 80px -10px rgba(0,0,0,0.20)',
    },
    accordionShadow: {
        webkitBoxShadow: '0px 2px 8px 0px rgba(139,146,167,0.5)',
        boxShadow: '0px 2px 8px 0px rgba(139,146,167,0.5)',
        mozBoxShadow: '0px 2px 8px 0px rgba(139,146,167,0.5)',
    },
};
