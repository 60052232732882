export const DocumentNames = [
    { value: 'Angiogram report', label: 'Angiogram report' },
    { value: 'Blood tests', label: 'Blood tests' },
    { value: 'Claimant statement', label: 'Claimant statement' },
    {
        value: 'CT Report (report and not the images)',
        label: 'CT Report (report and not the images)',
    },
    { value: 'Histology', label: 'Histology' },
    { value: 'ID document', label: 'ID document' },
    { value: 'ID Smart card', label: 'ID Smart card' },
    { value: 'Medical Claim Form', label: 'Medical Claim Form' },
    { value: 'MRI', label: 'MRI Report (report and not the images)' },
    { value: 'Operation report', label: 'Operation report' },
    {
        value: 'Personal Medical Attendant report (PMA)',
        label: 'Personal Medical Attendant report (PMA)',
    },
    { value: 'Proof of account', label: 'Proof of account' },
    { value: 'Other', label: 'Other' },
];
