import React from 'react';

import { Grid, Typography, withStyles } from '@material-ui/core';
import { getFullName } from '../shared';
import informationIcon from '../styles/icons/information.svg';
import styles from '../styles/styles';
import { ClaimantStatement } from './claimant-statement/claimant-statement';
import { Conclusion } from './conclusion/conclusion';
import { Introduction } from './introduction/introduction';
import { Requirements } from './requirements/requirements';
import { HorizontalStepper } from './steppers/horizontal-stepper';
import { InterviewStepper } from './steppers/interview-stepper';
import { InterviewSummary } from './summary/summary';
import { AppHeader } from '../master-layout/app-header/app-header';

const components = [
    { name: 'Introduction', component: Introduction },
    { name: 'Claimant Statement', component: ClaimantStatement },
    { name: 'Requirements', component: Requirements },
    { name: 'Conclusion', component: Conclusion },
    { name: 'Interview Summary', component: InterviewSummary },
];

function interviewHeader({
    activeStepIndex,
    classes,
    components,
    currentUser,
    form,
    lifeAssured,
    stepThrough,
    updateStepIndexAll,
    submitted,
}) {
    return (
        <React.Fragment>
            <AppHeader form={form} />
            <Grid item xs={12}>
                <Typography className={classes.onCall}>
                    {getUsersFirstName(currentUser)}, you are currently on the call with{' '}
                    {getFullName(lifeAssured)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.onCallInfo}>
                    Complete the following steps to complete your call.
                </Typography>
            </Grid>
            <HorizontalStepper
                components={components}
                activeIndex={activeStepIndex}
                stepThrough={stepThrough}
                updateIndex={updateStepIndexAll}
                submitted={submitted}
            />
            <SectionNotification classes={classes} />
        </React.Fragment>
    );
}

function SectionNotification({ classes }) {
    return (
        <div className={classes.requiredInformation}>
            <img src={informationIcon} alt="information" />
            <Typography className={classes.requiredInformationText}>
                Please complete all fields marked with a *
            </Typography>
        </div>
    );
}

function getUsersFirstName(currentUser) {
    const fullnames = currentUser && currentUser.name && currentUser.name.split(',');
    if (fullnames && Array.isArray(fullnames) && fullnames.length > 0) {
        return fullnames[fullnames.length - 1];
    }
}

export const ClaimInterview = withStyles(styles)(() => (
    <InterviewStepper components={components} headerComponent={interviewHeader} />
));
