import * as React from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';

const navigation = ({ classes, form, handleBack, handleNext, submitted }) => {
    return (
        <Grid item xs={12} className={classes.buttonGroup}>
            <Button
                variant="contained"
                className={classes.leftButton}
                onClick={() => handleBack(form)}
                id="navigation-back"
            >
                Previous
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.rightButton}
                onClick={() => handleNext(form)}
                id="navigation-next"
            >
                {submitted ? 'Next' : 'Confirmed'}
            </Button>
        </Grid>
    );
};

export const Navigation = withStyles(styles)(navigation);
