import React from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { OutlinedPhoneField, OutlinedTextAreaField } from '../../forms';
import { getTitleLastName } from '../../shared';
import informationIcon from '../../styles/icons/information.svg';
import styles from '../../styles/styles';

const contactDetails = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    lifeAssured,
    reference,
    stepsLength,
    submitted,
    user,
}) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Contact Details</Typography>
            </Grid>
            <div className={classes.userPrompts} style={{ marginTop: '32px', height: '98px' }}>
                <img
                    src={informationIcon}
                    alt="information"
                    style={{ height: '18px', marginTop: '18px' }}
                />
                <Typography
                    className={classes.userPromptsText}
                    style={{ width: '272px', marginTop: '8px' }}
                >
                    <span className={classes.empathyTitle}>Note</span>
                    <br />
                    It is important to ensure that the client has all your contact details.
                </Typography>
            </div>
            <Typography
                style={{
                    width: '368px',
                    lineHeight: '27px',
                    marginTop: '29.58px',
                }}
            >
                <span style={{ fontWeight: 600 }}>{getTitleLastName(lifeAssured)}</span>, if you
                have any enquiries and you wish to contact us, you can do so via e-mail{' '}
                <span style={{ fontWeight: 600 }}>info@liberty.co.za</span> or my telephone number
            </Typography>
            <OutlinedPhoneField
                name="conclusion.telephoneNumber"
                placeholder="Enter your telephone number"
                disabled={submitted}
            />
            <Typography
                style={{
                    width: '368px',
                    lineHeight: '27px',
                    marginTop: '31.58px',
                }}
            >
                Your claim number (reference number) is&nbsp;
                <span style={{ fontWeight: 600 }}>
                    {(reference && reference.referenceId) || ''}
                </span>
                &nbsp;and just to remind you again, my name is&nbsp;
                <span style={{ fontWeight: 600 }}>{(user && user.name) || ''}</span>.
            </Typography>
            <Typography
                style={{
                    width: '368px',
                    lineHeight: '27px',
                    marginTop: '31.58px',
                }}
            >
                Is there anything further that I can assist you with?
            </Typography>
            <OutlinedTextAreaField
                name="conclusion.additionalAssistance"
                label="Additional Assistance Notes"
                placeholder="Enter notes for additional assistance"
                disabled={submitted}
            />
            <Typography
                style={{
                    width: '368px',
                    lineHeight: '27px',
                }}
            >
                Remember that you can contact us or your broker at any time if you have questions
                regarding your claim.
            </Typography>
            <Grid item xs={12} className={classes.buttonGroup}>
                <Button
                    variant="contained"
                    className={classes.leftButton}
                    onClick={() => handleBackStepper(form)}
                    id="cont-det-back"
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.rightButton}
                    onClick={() => handleNext(form)}
                    id="cont-det-confirmed"
                >
                    {submitted ? 'Next' : 'End Call'}
                </Button>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const lifeAssured = (state.claimSummary && state.claimSummary.lifeAssured) || {};
    const references = (state.teleClaim && state.teleClaim.references) || [];
    return {
        lifeAssured,
        reference: references[0],
        user: state.authentication || {},
    };
};

const mapDispatchToProps = {};

export const ContactDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetails));
