import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import * as rsaIdParser from 'south-african-id-parser';
import styles from '../../styles/styles';
import {
    Condition,
    FixedWidthButtonGroup,
    OutlinedDatePicker,
    OutlinedSearchSelectField,
    OutlinedTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../forms/input-masks';
import { requiredValidator } from '../../forms/validations';
import { Titles } from '../../shared';
import { MedicalAidSchemes } from '../../shared/lookups/medical-aid-schemes';
import { Navigation } from '../common/navigation';

const medicalAidDetails = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    const prefix = 'claimantStatement.medicalAidQuestions';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Medical Aid Question</Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    name={`${prefix}.memberOfMedicalScheme`}
                    options={['Yes', 'No']}
                    label="Do you belong to a Medical Aid Scheme?"
                    labelprefixed="true"
                    validate={requiredValidator}
                    disabled={submitted}
                />
            </Grid>
            <Condition when={`${prefix}.memberOfMedicalScheme`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <FixedWidthButtonGroup
                            name={`${prefix}.isLifeAssuredTheMainMember`}
                            options={['Yes', 'No']}
                            label="Are you the main member?"
                            labelprefixed="true"
                            validate={requiredValidator}
                            disabled={submitted}
                        />
                    </Grid>
                    <Condition when={`${prefix}.isLifeAssuredTheMainMember`} matchesChild>
                        <When is="No">
                            <Grid item xs={12}>
                                <OutlinedSearchSelectField
                                    name={`${prefix}.mainMembersTitle`}
                                    placeholder="Select main member's title"
                                    labelprefixed="true"
                                    options={Titles}
                                    validate={requiredValidator}
                                    disabled={submitted}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}.mainMembersInitials`}
                                    placeholder="Enter main member's initials"
                                    labelprefixed="true"
                                    mask={InitialsMask}
                                    validate={requiredValidator}
                                    disabled={submitted}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}.mainMembersFirstName`}
                                    label="Main Member's Name"
                                    placeholder="Enter main member's name"
                                    labelprefixed="true"
                                    mask={FirstNameMask}
                                    validate={requiredValidator}
                                    disabled={submitted}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}.mainMembersLastName`}
                                    label="Main Member's Surname"
                                    placeholder="Enter main member's surname"
                                    labelprefixed="true"
                                    mask={LastNameMask}
                                    validate={requiredValidator}
                                    disabled={submitted}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}.mainMembersIdNumber`}
                                    placeholder="Enter main member's ID number"
                                    validate={validateRSAIdNumber}
                                    disabled={submitted}
                                />
                            </Grid>
                        </When>
                    </Condition>
                    <Grid item xs={12}>
                        <OutlinedSearchSelectField
                            name={`${prefix}.medicalAidName`}
                            label="Medical Aid Scheme Name"
                            placeholder="Enter medical aid scheme name"
                            options={MedicalAidSchemes}
                            disabled={submitted}
                        />
                        <WhenFieldChanges
                            field={`${prefix}.medicalAidName`}
                            set={`${prefix}.medicalAidDescription`}
                            to={null}
                        />
                    </Grid>
                    <Condition when={`${prefix}.medicalAidName`} is="Other">
                        <True>
                            <Grid item xs={12}>
                                <OutlinedTextField
                                    name={`${prefix}.medicalAidDescription`}
                                    label="Medical Aid Scheme Name"
                                    placeholder="Enter medical aid scheme description"
                                    labelprefixed="true"
                                    validate={requiredValidator}
                                    disabled={submitted}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={12}>
                        <OutlinedTextField
                            name={`${prefix}.medicalAidNumber`}
                            label="Medical Aid Scheme Number"
                            placeholder="Enter medical aid scheme number"
                            disabled={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedDatePicker
                            name={`${prefix}.dateJoined`}
                            disableFuture
                            disabled={submitted}
                        />
                    </Grid>
                </True>
            </Condition>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

function validateRSAIdNumber(value) {
    if (!value) {
        return undefined;
    }
    const isValid = rsaIdParser.validate(value);
    return isValid ? undefined : 'Invalid RSA Id number';
}

export const MedicalAidQuestions = withStyles(styles)(medicalAidDetails);
