import { capitalize, endsWith, startCase, upperCase } from 'lodash';
import parseName from '../parse-name/index';

export function nameToLabel({ name, label, prefix = '', suffix = '' }) {
    if (label) {
        return label;
    }
    const _name = parseName(name);
    const words = startCase(_name).split(' ');
    for (let i = 0; i < words.length - 1; i++) {
        if (endsWith(words[i], 's')) {
            const word = words[i];
            if (shouldGetApostrophe(word)) {
                words[i] = word.substr(0, word.length - 1) + "'s";
            }
        }
        words[i] = formatWords(words[i]);
    }
    return prefix + words.join(' ') + suffix;
}

function shouldGetApostrophe(word) {
    const wordsToIgnore = ['reasons', 'address', 'details', 'as', 'has', 'claims'];
    return wordsToIgnore.indexOf(word.toLowerCase()) < 0;
}

function formatWords(value) {
    const words = capitalize(value).split(' ');
    for (let i = 0; i < words.length; i++) {
        if (shouldBeUpperCase(words[i])) {
            words[i] = upperCase(words[i]);
        } else if (words[i] === '(bi1663/dha1663)') {
            words[i] = '(BI1663/DHA1663)';
        } else if (shouldCapitalize(words[i])) {
            words[i] = capitalize(words[i]);
        }
    }

    return words.join(' ');
}

function shouldBeUpperCase(word) {
    return ['Id', 'id', 'rsa', 'Rsa', 'Ra', 'ra', 'po', 'Po'].includes(word);
}

function shouldCapitalize(word) {
    return ['box', 'south', 'african', 'africa', 'birth', 'questions'].includes(word);
}
