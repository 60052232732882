import React from 'react';
import { AppBar, Grid, Toolbar, Typography, withStyles } from '@material-ui/core';
import logo from '../../styles/icons/liberty-logo-white.svg';
import { CurrentUserInfo } from './current-user-info';
import styles from '../../styles/styles';

const appHeader = ({ classes, form }) => {
    return (
        <AppBar className={classes.appBar}>
            <Toolbar style={{ position: 'sticky' }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="inherit" noWrap>
                            <span style={{ color: 'white' }}>
                                <img src={logo} alt="Liberty" className={classes.logo} />
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CurrentUserInfo form={form} />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export const AppHeader = withStyles(styles)(appHeader);
