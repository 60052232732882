import { Button, Grid, withStyles } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/styles';
import { closeModalAction } from '../actions';

const formModalView = ({ classes, formView, onSubmit, closeModal, closeButton }) => {
    return (
        <React.Fragment>
            {formView}
            <Grid container spacing={0} justify="flex-end">
                <Button
                    id="Cancel"
                    variant="contained"
                    className={classes.closeButton}
                    onClick={closeModal}
                >
                    {closeButton ? 'Close' : 'Cancel'}
                </Button>
                {!closeButton && (
                    <Button
                        id="Save"
                        variant="contained"
                        color="secondary"
                        className={classes.rightButton}
                        onClick={submit}
                    >
                        Save
                    </Button>
                )}
            </Grid>
        </React.Fragment>
    );

    async function submit() {
        if (onSubmit) {
            const success = await onSubmit();
            if (!success) {
                return;
            }
        }

        closeModal();
    }
};

const mapStateToProps = () => ({
    onSubmit: () => ({}),
});

const mapDispatchToProps = {
    closeModal: closeModalAction,
};

export const FormModalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(formModalView));
