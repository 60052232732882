import { push as setLocation } from 'connected-react-router';
import { env } from '../../env/env';
import { openDialogAction } from '../../forms/confirmation-dialog';
import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import axios from '../../http-request';
import { initiateTeleClaimAction } from './load-tele-claim';
import { CLAIM_SUMMARY_LOADED } from './types';

const claimsUri = `${env && env.ClaimsLiveAPI}/api/TeleClaim`;

export const claimSummaryLoadedActionCreator = claim => ({
    type: CLAIM_SUMMARY_LOADED,
    payload: claim,
});

const claimSummaryLoadedThunk = claim => {
    return async dispatch => {
        sessionStorage.setItem('claimId', claim.claimId);
        dispatch(claimSummaryLoadedActionCreator(claim));
        return {};
    };
};

export const loadClaimSummaryAction = (
    claimId = undefined,
    referenceSource = undefined,
    referenceId = undefined
) => {
    return async dispatch => {
        dispatch(showLoading());
        try {
            const loadClaimSummaryUri = getLoadClaimSummaryUri(
                referenceSource,
                referenceId,
                claimId
            );
            const response = await axios.get(loadClaimSummaryUri);
            if (response && response.data) {
                dispatch(claimSummaryLoadedThunk(response.data));
                const teleClaimId = sessionStorage.getItem('teleClaimId');
                if (!teleClaimId) {
                    dispatch(initiateTeleClaimAction(referenceSource, referenceId));
                }
                dispatch(setLocation('/interview'));
            } else {
                dispatch(setLocation('/'));
            }
        } catch (error) {
            dispatch(setLocation('/'));
            dispatch(
                openDialogAction({
                    dialogContentDetails: {
                        title: 'Error',
                        error: true,
                        content: `Error while loading claim ${error}`,
                    },
                })
            );
        } finally {
            dispatch(hideLoading());
        }
        return {};
    };
};

function getLoadClaimSummaryUri(
    referenceSource = undefined,
    referenceId = undefined,
    claimId = undefined
) {
    if (claimId) {
        return `${claimsUri}/ClaimSummary/${claimId}`;
    }

    if (referenceSource && referenceId) {
        sessionStorage.setItem('referenceSource', referenceSource);
        sessionStorage.setItem('referenceId', referenceId);
        return `${claimsUri}/ClaimSummary?referenceSource=${referenceSource}&referenceId=${referenceId}`;
    }

    throw new Error('Either Claim ID or both Reference Source and Reference ID are required');
}
