import * as React from 'react';
import {
    Checkbox as MuiCheckbox,
    FormControlLabel,
    makeStyles,
    withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { callAllFunctions, getComponentId, nameToLabel } from '../..';
import { processMetaForErrors } from '../../form-component-utilities';
import styles from '../styles';

const checkBoxStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        boxSizing: 'border-box',
        height: 27,
        width: 27,
        border: '3px solid #D8D8D8',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: '#D8D8D8',
        },
    },
    iconShaded: {
        boxSizing: 'border-box',
        height: 27,
        width: 27,
        border: '3px solid #D8D8D8',
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
    },
    checkedIcon: {
        backgroundColor: '#002B99',
        border: '2px solid #002B99',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
});

const checkbox = ({ input, label, id, onChange, meta, shaded = true, hideLabel }) => {
    const classes = checkBoxStyles();
    const { checked, name } = input;
    const { errorMessage } = processMetaForErrors(meta);

    return (
        <React.Fragment>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        style={{ height: 27 }}
                        id={getComponentId({ id, name })}
                        name={name}
                        inputProps={{
                            'aria-label': 'decorative checkbox',
                            height: '27px',
                            width: '27px',
                        }}
                        onChange={callAllFunctions(onChange, input.onChange)}
                        checked={checked}
                        className={classes.root}
                        disableRipple
                        checkedIcon={
                            <span className={classNames(classes.icon, classes.checkedIcon)} />
                        }
                        icon={<span className={shaded ? classes.iconShaded : classes.icon} />}
                    />
                }
                label={hideLabel ? undefined : nameToLabel({ label, name })}
                style={{ height: 27, maxHeight: 27, padding: 0, marginTop: 12 }}
            />
            {errorMessage && (
                <span style={{ color: '#cd003d', fontSize: '0.75rem' }}>{errorMessage}</span>
            )}
        </React.Fragment>
    );
};

export const Checkbox = withStyles(styles)(checkbox);
