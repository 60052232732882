import React from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { logOutThunk } from '../../authentication/actions/log-out';
import { Condition, False, FixedWidthRadioGroup, FixedWidthRadioOption, True } from '../../forms';
import { openDialogAction } from '../../forms/confirmation-dialog';
import { getDisplayDate, getFullName, getTitleLastName } from '../../shared';
import styles from '../../styles/styles';
import { Navigation } from '../common/navigation';
import { AddressDetails } from './address-details';
import { TerminateView } from './terminate-view';
import { Field } from 'react-final-form';

const ScreeningView = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    lifeAssured,
    logOut,
    openDialog,
    submitted,
}) => {
    const prefix = 'introduction.screening.';
    const postalAddress = (lifeAssured && lifeAssured.postalAddress) || null;
    const residantialAddress = (lifeAssured && lifeAssured.residentialAddress) || null;

    const terminateCall = event => {
        event.stopPropagation();
        openDialog({
            dialogContentDetails: {
                notitle: true,
                content: <TerminateView />,
                contentClass: classes.modalContent,
            },
            yesAction: {
                action: async () => {
                    await form.submit();
                    logOut();
                },
            },
            yesLabel: 'Save & Exit',
            noLabel: 'Close',
        });
    };

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}> Screening</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    I need to ask you some security questions to ensure that I am speaking to the
                    correct person.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Please confirm your Full Name and Surname or Initials and Surname
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className={[classes.marginTop, classes.screeningQuestionsView].join('')}
            >
                <Typography className={[classes.marginTop, classes.screeningQuestions].join('')}>
                    {getFullName(lifeAssured)}
                </Typography>
                <FixedWidthRadioGroup
                    name={`${prefix}fullName`}
                    style={{ display: 'grid' }}
                    id="fullName"
                    disabled={submitted}
                >
                    <FixedWidthRadioOption value="confirmed" />
                    <FixedWidthRadioOption value="unconfirmed" />
                </FixedWidthRadioGroup>
            </Grid>
            <Grid item xs={12} className={classes.marginTop}>
                <Typography>Please confirm your Date of Birth</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className={[classes.marginTop, classes.screeningQuestionsView].join('')}
            >
                <Typography className={[classes.marginTop, classes.screeningQuestions].join('')}>
                    {getDisplayDate(lifeAssured.dateOfBirth)}
                </Typography>
                <FixedWidthRadioGroup
                    name={`${prefix}dateOfBirth`}
                    label="Date of Birth"
                    id="dateOfBirth"
                    style={{ display: 'grid' }}
                    disabled={submitted}
                >
                    <FixedWidthRadioOption value="confirmed" />
                    <FixedWidthRadioOption value="unconfirmed" />
                </FixedWidthRadioGroup>
            </Grid>

            {postalAddress !== null && (
                <React.Fragment>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography>Please confirm your Postal Address</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={[classes.marginTop, classes.screeningQuestionsView].join('')}
                    >
                        <AddressDetails addressDetails={postalAddress} />

                        <FixedWidthRadioGroup
                            name={`${prefix}postalAddress`}
                            style={{ display: 'grid' }}
                            id="postalAddress"
                            disabled={submitted}
                        >
                            <FixedWidthRadioOption value="confirmed" />
                            <FixedWidthRadioOption value="unconfirmed" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </React.Fragment>
            )}

            {residantialAddress !== null && (
                <React.Fragment>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography>Please confirm your Residential Address</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={[classes.marginTop, classes.screeningQuestionsView].join('')}
                    >
                        <AddressDetails addressDetails={residantialAddress} />
                        <FixedWidthRadioGroup
                            name={`${prefix}residentialAddress`}
                            style={{ display: 'grid' }}
                            id="residentialAddress"
                            disabled={submitted}
                        >
                            <FixedWidthRadioOption value="confirmed" />
                            <FixedWidthRadioOption value="unconfirmed" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </React.Fragment>
            )}
            <Field
                name="introduction.screening"
                subscription={{ value: true }}
                component={({ input: { value: values } }) => {
                    if (values && !shouldConfirmIdNumber(values)) {
                        values.idNumber = null;
                    }
                    return null;
                }}
            />

            <Condition
                when="introduction.screening"
                passes={values => shouldConfirmIdNumber(values)}
            >
                <True>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography>Please confirm your ID number</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={[classes.marginTop, classes.screeningQuestionsView].join('')}
                    >
                        <Typography
                            className={[classes.marginTop, classes.screeningQuestions].join('')}
                        >
                            {lifeAssured.idNumber}
                        </Typography>
                        <FixedWidthRadioGroup
                            name={`${prefix}idNumber`}
                            label="ID Number"
                            id="idNumber"
                            style={{ display: 'grid' }}
                            disabled={submitted}
                        >
                            <FixedWidthRadioOption value="confirmed" />
                            <FixedWidthRadioOption value="unconfirmed" />
                        </FixedWidthRadioGroup>
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}idNumber`} is="unconfirmed">
                <True>
                    <Grid item xs={12}>
                        <Typography className={classes.contentText}>
                            Unfortunately we cannot proceed with this call at this time. Thank you
                            for your time&nbsp;{getTitleLastName(lifeAssured)}
                        </Typography>
                        <Grid item xs={12} className={classes.buttonGroup}>
                            <Button
                                variant="contained"
                                className={classes.leftButton}
                                onClick={() => handleBackStepper(form)}
                                id="back-button"
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.rightButton}
                                onClick={event => terminateCall(event)}
                                id="screening-confirmed"
                            >
                                Terminate
                            </Button>
                        </Grid>
                    </Grid>
                </True>
                <False>
                    <Grid item xs={12}>
                        <Typography className={classes.contentText}>
                            Thank you for the information, we can now continue with the claim
                            questions
                        </Typography>
                    </Grid>
                    <Navigation
                        form={form}
                        handleBack={handleBackStepper}
                        handleNext={handleNext}
                        submitted={submitted}
                    />
                </False>
            </Condition>
        </Grid>
    );
};

function shouldConfirmIdNumber(screening) {
    if (screening) {
        if (
            screening.fullName === 'confirmed' &&
            screening.dateOfBirth === 'confirmed' &&
            (screening.residentialAddress === 'confirmed' ||
                screening.postalAddress === 'confirmed' ||
                (!screening.postalAddress && !screening.residantialAddress))
        ) {
            return false;
        }
    }
    return true;
}

const mapStateToProps = state => {
    const lifeAssured = (state.claimSummary && state.claimSummary.lifeAssured) || {};
    const references = state.teleClaim && state.teleClaim.references;
    return {
        lifeAssured,
        references,
    };
};

const mapDispatchToProps = {
    logOut: logOutThunk,
    openDialog: openDialogAction,
};

export const Screening = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ScreeningView));
