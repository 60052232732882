import React from 'react';

import { Route, Switch } from 'react-router';
import { LoadClaimSummaryFailed } from '../initiate-interview';
import { ClaimInterview } from '../interview/interview';

export const StaticRoutes = () => {
    return (
        <Switch>
            <Route exact path="/" component={LoadClaimSummaryFailed} />
            <Route exact path="/interview" component={ClaimInterview} />
        </Switch>
    );
};

export default StaticRoutes;
