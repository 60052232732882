import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    Condition,
    OutlinedSearchSelectField,
    OutlinedTextAreaField,
    OutlinedTextField,
    True,
} from '../../forms';
import { HighLeveTreatments } from '../../shared';
import { Navigation } from '../common/navigation';

const currentTreatment = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    const prefix = 'claimantStatement.currentTreatment';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Current Treatment</Typography>
            </Grid>
            <OutlinedSearchSelectField
                name={`${prefix}.highLevelDescriptionOfTreatment`}
                label="High-level description of the current treatment plan"
                options={HighLeveTreatments}
                placeholder="Select description"
                disabled={submitted}
            />

            <Condition when={`${prefix}.highLevelDescriptionOfTreatment`} is={'Other'}>
                <True>
                    <OutlinedTextField
                        name={`${prefix}.highLevelDescriptionOfTreatmentOther`}
                        label="Other"
                        placeholder="Enter other description"
                        disabled={submitted}
                    />
                </True>
            </Condition>

            <OutlinedTextAreaField
                name={`${prefix}.detailedDescriptionOfTreatment`}
                label="Please provide a detailed description of the medical treatment you are currently undergoing"
                placeholder="Enter detailed description"
                disabled={submitted}
            />
            <OutlinedTextAreaField
                name={`${prefix}.detailedMedicationDescription`}
                label="Please provide a detailed description of the medication you are currently taking"
                placeholder="Enter detailed description"
                disabled={submitted}
            />
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const CurrentTreatment = withStyles(styles)(currentTreatment);
