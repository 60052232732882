import * as React from 'react';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { CssBaseline } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ReduxToastr from 'react-redux-toastr';
import { ModalView } from '../forms/form-modal';
import { LoadingScreenView } from '../forms/loading-screen';
import { AuthenticatedView } from '../authentication/authenticated-view';
import { LoadClaimSummaryView } from '../initiate-interview';
import { connect } from 'react-redux';

function styles(theme) {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        appFrame: {
            zIndex: 1,
            display: 'flex',
            width: '100%',
            minWidth: '1164px',
            minHeight: '756px',
            backgroundColor: '#F0F1F4',
        },
        contentWrapper: { width: '1164px', margin: '0 auto' },
        content: {
            marginTop: '64px',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(0.6),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    });
}

function MasterLayout({ classes, children }) {
    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.appFrame}>
                <div className={classes.contentWrapper}>
                    <LoadingScreenView />
                    <AuthenticatedView>
                        <main id="mainElement" className={classes.content}>
                            <LoadClaimSummaryView />
                            {children}
                            <ModalView />
                            <ReduxToastr
                                timeOut={5000}
                                newestOnTop={true}
                                preventDuplicates
                                position="top-right"
                                transitionIn="bounceIn"
                                transitionOut="fadeOut"
                                progressBar
                            />
                        </main>
                    </AuthenticatedView>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MasterLayout));
