import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GenericForm } from '../../forms';
import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import axios from '../../http-request';
import { loadTeleClaimAction, teleClaimLoadedThunk } from '../../initiate-interview';
import styles from '../../styles/styles';
import { ErrorsPanel } from '../common/errors-panel';
import { openDialogAction } from '../../forms/confirmation-dialog';
import { prettifiedErrors } from '../../shared';
import { logOutThunk } from '../../authentication/actions/log-out';
import ConfirmationDialog from '../../forms/confirmation-dialog';

const { env } = require('../../env/env');

function scrollToTop() {
    setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 200);
}

function StepperComponent({
    classes,
    components,
    currentUser,
    claimSummary,
    headerComponent,
    hideLoading,
    loadTeleClaim,
    openDialog,
    showLoading,
    teleClaim,
    teleClaimLoaded,
}) {
    let stepIndex = +sessionStorage.getItem('activeStepIndex');
    const [activeStepIndex, setActiveStepIndex] = useState(stepIndex || 0);
    const activeStep = components[activeStepIndex];
    const [errorList, setErrors] = useState([]);
    const [loadingTeleClaim, setLoadingTeleClaim] = useState(false);
    const submitted = teleClaim && teleClaim.submitted;

    if (_.isEmpty(teleClaim) && !loadingTeleClaim) {
        setLoadingTeleClaim(true);
        const teleClaimId = sessionStorage.getItem('teleClaimId');
        loadTeleClaim(teleClaimId);
    }

    const updateStepIndexAll = (index, allowNext = false) => {
        sessionStorage.setItem('verticalStepIndex', '0');
        sessionStorage.setItem('canGoToNextPage', 'false');
        if (!submitted) {
            if (index > activeStepIndex && !allowNext) {
                return;
            }
        }
        setActiveStepIndex(index);
        sessionStorage.setItem('activeStepIndex', index.toString());
    };

    useEffect(() => {
        scrollToTop();
        closeErrorPanel();
    }, [activeStepIndex]);

    function displayClaimHeader(form) {
        if (headerComponent) {
            const ClaimHeader = headerComponent;
            const lifeAssured = claimSummary && claimSummary.lifeAssured;
            return (
                <ClaimHeader
                    form={form.form}
                    components={components}
                    updateStepIndexAll={updateStepIndexAll}
                    classes={classes}
                    activeStepIndex={activeStepIndex}
                    currentUser={currentUser}
                    lifeAssured={lifeAssured}
                    stepThrough={submitted}
                />
            );
        }

        return null;
    }

    return (
        <React.Fragment>
            <ErrorsPanel closePanel={closeErrorPanel} errors={errorList} />
            <GenericForm
                onSubmit={validateSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                initialValues={teleClaim}
                render={renderForm}
            />
        </React.Fragment>
    );

    function renderForm(form) {
        const StepRenderComponent = activeStep && activeStep.component;
        return (
            <React.Fragment>
                {displayClaimHeader(form)}
                <StepRenderComponent
                    form={form.form}
                    submitted={submitted}
                    handleSubmit={handleSubmit(form.form)}
                    handleBack={handleBack(form.form)}
                    setFormErrors={setFormErrors}
                    resetFormErrors={resetFormErrors}
                />
                <ConfirmationDialog />
            </React.Fragment>
        );
    }

    function closeErrorPanel(event) {
        event && event.preventDefault();
        setErrors([]);
    }

    function resetFormErrors(form) {
        form.reset(form.getState().values);
    }

    function setFormErrors(form) {
        const errors = form.getState().errors;
        setErrors(prettifiedErrors(errors));
    }

    function handleBack(form) {
        return function _handleBack() {
            setErrors([]);
            form.reset();
            return updateStepIndexAll(activeStepIndex - 1);
        };
    }

    function handleSubmit(form) {
        return function () {
            if (form.hasValidationErrors) {
                setErrors(prettifiedErrors(form.getState().errors));
                form.submit();
                scrollToTop();
            } else {
                setErrors([]);
                form.submit();
            }
        };
    }

    async function validateSubmit(subMittedValues) {
        showLoading();
        try {
            const numberOfParams = Object.keys(subMittedValues).length;
            if (numberOfParams === 0) {
                return goToNextPage();
            }
            if (!submitted) {
                /**@type {import('axios').AxiosRequestConfig} */
                const config = {
                    url: `${env && env.ClaimsLiveAPI}/api/TeleClaim/${subMittedValues.teleClaimId}`,
                    data: { ...subMittedValues },
                    method: 'PUT',
                };
                const response = await axios(config);
                if (response && response.status === 200) {
                    await teleClaimLoaded(response.data);
                }
            }
            const canGoToNextPage = sessionStorage.getItem('canGoToNextPage') === 'true';
            if (canGoToNextPage || (stepIndex <= 0 && !canGoToNextPage)) {
                if (canGoToNextPage) {
                    sessionStorage.setItem('verticalStepIndex', '0');
                    return goToNextPage();
                }
            }
        } catch (err) {
            openDialog({
                dialogContentDetails: {
                    title: 'Error',
                    error: true,
                    content: `Error while loading claim ${err}`,
                },
            });
        } finally {
            hideLoading();
        }

        return {};

        function goToNextPage() {
            if (activeStepIndex < components.length) {
                updateStepIndexAll(activeStepIndex + 1, true);
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        claimSummary: state.claimSummary,
        currentUser: state.authentication,
        teleClaim: state.teleClaim,
    };
};

const mapDispatchToProps = {
    hideLoading,
    showLoading,
    teleClaimLoaded: teleClaimLoadedThunk,
    loadTeleClaim: loadTeleClaimAction,
    openDialog: openDialogAction,
    logOut: logOutThunk,
};

export const InterviewStepper = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(StepperComponent));
