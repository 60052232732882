import { SET_ACTIVE_USER } from './action-types';

const rolesPropertyName = 'roles';

export const setActiveUserActionCreator = user => {
    const payload = !user
        ? null
        : /* eslint-disable */
          {
              userId: user.userName,
              name: user.name,
              roles: user.idToken[rolesPropertyName] || [],
          };
    /* eslint-enable */
    return {
        type: SET_ACTIVE_USER,
        payload,
    };
};
