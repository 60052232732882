import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authenticationReducer } from '../authentication/authentication-reducer';
import { confirmationDialogReducer, initialDialogDetails } from '../forms/confirmation-dialog';
import { formModalReducer, initialModalState } from '../forms/form-modal/reducer';
import { loadingScreenReducer } from '../forms/loading-screen/loading-screen-reducer';
import history from '../routing/history';
import {
    claimSummaryReducer,
    teleClaimReducer,
} from '../initiate-interview/initiate-interview-reducer';

export const initialState = {
    formModal: initialModalState,
    confirmationDialog: initialDialogDetails,
};

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    claimSummary: claimSummaryReducer,
    confirmationDialog: confirmationDialogReducer,
    formModal: formModalReducer,
    loadingBar: loadingBarReducer,
    loadingScreenState: loadingScreenReducer,
    router: connectRouter(history),
    teleClaim: teleClaimReducer,
    toastr: toastrReducer,
});

export default rootReducer;
