import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { VerticalStepper } from '../steppers/vertical-stepper';
import { BankingInformation } from './banking-information';
import { CurrentTreatment } from './current-treatment';
import { CurrentMedicalCondition } from './current-medical-condition';
import { MedicalAidQuestions } from './medical-aid-questions';
import { MedicalQuestions } from './medical-questions';
import { PastMedicalCondition } from './past-medical-conditions';

const components = [
    { name: 'Medical Questions', component: MedicalQuestions },
    { name: 'Current Treatment', component: CurrentTreatment },
    { name: 'Current Medical Condition', component: CurrentMedicalCondition },
    { name: 'Past Medical Conditions', component: PastMedicalCondition },
    { name: 'Medical Aid Questions', component: MedicalAidQuestions },
    { name: 'Banking Information', component: BankingInformation },
];

const claimantStatement = ({ ...rest }) => {
    return <VerticalStepper name="Claimant Statement" verticalComponents={components} {...rest} />;
};

export const ClaimantStatement = withStyles(styles)(claimantStatement);
