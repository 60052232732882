import * as React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { closeDialogAction } from './actions';

const defaultDialogDetails = {
    dialogOpen: undefined,
    dialogContentDetails: undefined,
    doneAction: undefined,
    yesAction: undefined,
    noAction: undefined,
    canCancel: undefined,
    yesLabel: undefined,
    noLabel: undefined,
    cancelLabel: undefined,
    error: undefined,
};

function confirmationDialog({ classes, dialogDetails = defaultDialogDetails, closeDialog }) {
    const executeDialogAction = async dialogAction => {
        closeDialog();
        if (dialogAction && dialogAction.action) {
            return dialogAction.action() || {};
        }
    };

    const cancelAction = () => {
        executeDialogAction();
    };

    const noAction = () => {
        executeDialogAction(dialogDetails.noAction);
    };

    const yesAction = () => {
        executeDialogAction(dialogDetails.yesAction);
    };

    const doneAction = () => {
        executeDialogAction(dialogDetails.doneAction);
    };

    const dialogContentDetails = dialogDetails.dialogContentDetails || {};
    const contentClass = dialogContentDetails.contentClass || classes.dialogContent;
    return (
        <Dialog open={dialogDetails.dialogOpen} aria-labelledby="form-dialog-title">
            <Title dialogDetails={dialogDetails} content={dialogContentDetails} />
            <DialogContent className={contentClass}>{dialogContentDetails.content}</DialogContent>
            {(dialogDetails.noAction || dialogDetails.yesAction) && (
                <DialogActions className={classes.dialogAction}>
                    <Button id="no-button" className={classes.closeButton} onClick={noAction}>
                        {dialogDetails.noLabel || 'No'}
                    </Button>

                    <Button
                        id="yes-button"
                        className={classes.saveButton}
                        onClick={yesAction}
                        color="secondary"
                    >
                        {dialogDetails.yesLabel || 'Yes'}
                    </Button>
                </DialogActions>
            )}
            {(dialogDetails.error || dialogDetails.canCancel) && (
                <DialogActions className={classes.dialogAction}>
                    <Button
                        id="close-button"
                        className={classes.closeButton}
                        onClick={cancelAction}
                    >
                        {dialogDetails.cancelLabel || 'Close'}
                    </Button>
                </DialogActions>
            )}
            {dialogDetails.doneAction && (
                <DialogActions className={classes.dialogAction}>
                    <Button id="done-button" className={classes.closeButton} onClick={doneAction}>
                        {'Done'}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

const Title = ({ dialogDetails, content }) => {
    if (content.noTitle) {
        return null;
    }
    return (
        <React.Fragment>
            {dialogDetails.error ? (
                <DialogTitle>
                    <Grid container spacing={0} justify="flex-start">
                        <Grid item xs={1}>
                            <Error color="error" fontSize="large" />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography
                                style={{ marginLeft: '12px', marginTop: '4px', fontWeight: 600 }}
                                variant="subtitle1"
                            >
                                ...Oops
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
            ) : (
                <DialogTitle className={content.titleClass}>{content.title}</DialogTitle>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    dialogDetails: state.confirmationDialog,
});

const mapDispatchToProps = {
    closeDialog: closeDialogAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(confirmationDialog));
