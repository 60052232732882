import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import styles from '../../styles/styles';
import { LabelValue } from './common/label-value';
import { getDisplayDate } from '../../shared';
import { AssessorsNotes } from './common/assessors-notes';
import { IncludeInClientSummary } from './common/include-in-client-summary';

const claimantStatement = ({ classes, claimantStatement }) => {
    const notes = (claimantStatement && claimantStatement.notes) || [];
    const currentTreatement = claimantStatement && claimantStatement.currentTreatment;
    const medicalQuestions = claimantStatement && claimantStatement.medicalQuestions;
    const currentMedicalConsultaion =
        claimantStatement && claimantStatement.currentMedicalCondition;
    const pastMedicalConsultaion = claimantStatement && claimantStatement.pastMedicalConditions;
    const medicalAidDetails = claimantStatement && claimantStatement.medicalAidQuestions;
    const bankingInformation = claimantStatement && claimantStatement.bankingInformation;
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.grayTitle}>
                <Typography className={classes.grayTitleText}>
                    Section 2 - Claimant Statement
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <MedicalQuestions
                    classes={classes}
                    medicalQuestions={medicalQuestions}
                    notes={notes[0]}
                />
                <CurrentTreatment
                    classes={classes}
                    currentTreatement={currentTreatement}
                    notes={notes[1]}
                />
                <MedicalConsultation
                    classes={classes}
                    medicalConsultaion={currentMedicalConsultaion}
                    notes={notes[2]}
                    heading={'Current Medical Condition Consultation Log'}
                    title="Details of all doctors / hospitals / clinics that you are currently consulting in relation to
                        your medical condition"
                />
                <MedicalConsultation
                    classes={classes}
                    medicalConsultaion={pastMedicalConsultaion}
                    notes={notes[3]}
                    heading={'Past Medical Condition Consultation Log'}
                    title="Details of all doctors / hospitals / clinics that you have consulted over the past 3 years, in relation to
                        your medical condition"
                />
                <MedicalAidDetails
                    classes={classes}
                    medicalAidDetails={medicalAidDetails}
                    notes={notes[4]}
                />
                <BankingDetails
                    classes={classes}
                    bankingInformation={bankingInformation}
                    notes={notes[5]}
                />
            </Grid>
        </React.Fragment>
    );
};

const CurrentTreatment = ({ classes, currentTreatement, notes }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Current Treatment</Typography>
            </Grid>
            {currentTreatement && (
                <React.Fragment>
                    <LabelValue
                        label="High Level Description of Treatment"
                        value={currentTreatement.highLevelDescriptionOfTreatment}
                    />
                    <LabelValue
                        label="High Level Description of Treatment"
                        value={currentTreatement.highLevelDescriptionOfTreatmentOther}
                    />
                    {currentTreatement.detailedDescriptionOfTreatment && (
                        <IncludeInClientSummary
                            include="claimantStatement.currentTreatment.includeDescriptionOfTreatment"
                            label="Please provide a detailed description of the medical treatment you are currently undergoing"
                            name="claimantStatement.currentTreatment.detailedDescriptionOfTreatment"
                            placeholder="Please provide a detailed description of the medical treatment you are currently undergoing"
                            value={currentTreatement.detailedDescriptionOfTreatment}
                        />
                    )}
                    {currentTreatement.detailedMedicationDescription && (
                        <IncludeInClientSummary
                            include="claimantStatement.currentTreatment.includeMedicationDescription"
                            label="Please provide a detailed description of the medication you are currently taking"
                            name="claimantStatement.currentTreatment.detailedMedicationDescription"
                            placeholder="Please provide a detailed description of the medication you are currently taking"
                            value={currentTreatement.detailedMedicationDescription}
                        />
                    )}
                </React.Fragment>
            )}
            <AssessorsNotes id="current-treatment" notes={notes} />
        </React.Fragment>
    );
};

const MedicalQuestions = ({ classes, medicalQuestions, notes }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Medical Questions</Typography>
            </Grid>
            {medicalQuestions && (
                <Grid item xs={12}>
                    <LabelValue
                        label="What is the medical reason for the claim being submitted?"
                        value={medicalQuestions.medicalReason}
                    />
                    <LabelValue
                        label="Please provide the date of diagnosis/date the event took place?"
                        value={getDisplayDate(medicalQuestions.diagnosisDate)}
                    />
                    <LabelValue
                        label="The medical condition claimed for is due to?"
                        value={medicalQuestions.conditionType}
                    />
                    {medicalQuestions.conditionType === 'Accident' && (
                        <React.Fragment>
                            <LabelValue
                                label="Was the accident reported to the police?"
                                value={medicalQuestions.reportedToPolice}
                            />
                            <LabelValue
                                label="Which police station was it reported to?"
                                value={medicalQuestions.policeStationReportedTo}
                            />
                            <LabelValue
                                label="What is your case number?"
                                value={medicalQuestions.caseNumber}
                            />
                            <LabelValue
                                label="Please provide a fully detailed description of accident"
                                value={medicalQuestions.descriptionOfAccident}
                            />
                        </React.Fragment>
                    )}
                </Grid>
            )}
            <AssessorsNotes id="medical-questions" notes={notes} />
        </React.Fragment>
    );
};

const MedicalConsultation = ({ classes, medicalConsultaion, notes, heading, title }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>{heading}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.medicalHistoryTitle}>{title}</Typography>
            </Grid>
            {medicalConsultaion &&
                medicalConsultaion.doctorsVisits &&
                medicalConsultaion.doctorsVisits.map((doctor, index) => {
                    const doctorsName = `Dr ${capitalize(doctor.initials)}. ${capitalize(
                        doctor.lastName
                    )}`;
                    const address = doctor.addressDetails || {};
                    return (
                        <Grid item xs={12} key={'dovtor' + index}>
                            <Typography className={classes.summaryStepName}>
                                Doctor {index + 1} Details
                            </Typography>
                            <LabelValue label="Doctors name" value={doctorsName} />
                            <LabelValue label="Contact number" value={doctor.telephoneNumber} />
                            <Typography className={classes.summaryStepName}>
                                Practice Details
                            </Typography>
                            <LabelValue label="Address Type" value={address.addressType} />
                            <LabelValue label="Address line 1" value={address.addressLine1} />
                            <LabelValue label="Address line 2" value={address.addressLine2} />
                            <LabelValue label="Address line 3" value={address.addressLine3} />
                            <LabelValue label="Address line 4" value={address.addressLine4} />
                            <LabelValue label="Town/Suburb" value={address.suburb} />
                            <LabelValue label="City" value={address.town} />
                            <LabelValue label="Postal Code" value={address.postalCode} />
                            <LabelValue label="Province" value={address.province} />
                            <LabelValue label="Country" value={address.country} />
                            {doctor.visitOccurences &&
                                doctor.visitOccurences.map((consultation, index) => {
                                    return (
                                        <Grid item xs={12} key={'consultation' + index}>
                                            <Typography className={classes.summaryStepName}>
                                                Consultation {index + 1} Details
                                            </Typography>
                                            <LabelValue
                                                label="Date of consultation"
                                                value={getDisplayDate(consultation.date)}
                                            />
                                            <LabelValue
                                                label="Reason for consultation"
                                                value={consultation.reason}
                                            />
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    );
                })}
            <AssessorsNotes id={title.split(' ').join('-')} notes={notes} />
        </React.Fragment>
    );
};

const MedicalAidDetails = ({ classes, medicalAidDetails, notes }) => {
    if (!medicalAidDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Medical Aid Details</Typography>
            </Grid>
            <LabelValue
                label="Do you belong to a Medical Aid Scheme?"
                value={medicalAidDetails.memberOfMedicalScheme}
            />
            {medicalAidDetails.memberOfMedicalScheme === 'Yes' && (
                <React.Fragment>
                    <LabelValue
                        label="Medical Aid Scheme Name"
                        value={medicalAidDetails.medicalAidName}
                    />
                    <LabelValue
                        label="Medical Aid Scheme Number"
                        value={medicalAidDetails.medicalAidNumber}
                    />
                    <LabelValue
                        label="Date Joined"
                        value={getDisplayDate(medicalAidDetails.dateJoined)}
                    />
                    <LabelValue
                        label="LifeAssured The Main Member"
                        value={medicalAidDetails.isLifeAssuredTheMainMember}
                    />
                    {medicalAidDetails.isLifeAssuredTheMainMember === 'No' && (
                        <React.Fragment>
                            <Typography className={classes.summaryStepName}>
                                Main Members Details
                            </Typography>
                            <LabelValue label="Title" value={medicalAidDetails.mainMembersTitle} />
                            <LabelValue
                                label="Initials"
                                value={medicalAidDetails.mainMembersInitials}
                            />
                            <LabelValue
                                label="First Name"
                                value={medicalAidDetails.mainMembersFirstName}
                            />
                            <LabelValue
                                label="Last Name"
                                value={medicalAidDetails.mainMembersLastName}
                            />
                            <LabelValue
                                label="ID Number"
                                value={medicalAidDetails.mainMembersIdNumber}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <AssessorsNotes id="medical-aid" notes={notes} />
        </React.Fragment>
    );
};

const BankingDetails = ({ classes, bankingInformation, notes }) => {
    if (!bankingInformation) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.summaryStepName}>Banking Details</Typography>
            </Grid>
            <LabelValue
                label="Pay money into premium paying account?"
                value={bankingInformation.premiumPayingAccount}
            />
            {bankingInformation.premiumPayingAccount === 'No' && (
                <React.Fragment>
                    <LabelValue
                        label="Account Holders Name"
                        value={bankingInformation.accountHoldersName}
                    />
                    <LabelValue label="Bank Name" value={capitalize(bankingInformation.bankName)} />
                    <LabelValue label="Account Number" value={bankingInformation.accountNumber} />
                    <LabelValue label="Branch Name" value={bankingInformation.bankBranch} />
                    <LabelValue label="Branch Code" value={bankingInformation.branchCode} />
                    <LabelValue label="Account Type" value={bankingInformation.accountType} />
                    <LabelValue
                        label="Account Holders Relationship"
                        value={bankingInformation.accountHolderRelationship}
                    />
                </React.Fragment>
            )}
            <AssessorsNotes id="banking-details" notes={notes} />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    claimantStatement: (state.teleClaim && state.teleClaim.claimantStatement) || {},
});

const mapDispatchToProps = {};

export const ClaimantStatementSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimantStatement));
