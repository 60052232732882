import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { getDisplayDate } from '../../shared';
import { Accordion } from '../../forms';

const claimEventDetails = ({ classes, claimEvent }) => {
    return (
        <React.Fragment>
            <Typography className={classes.policyInfoHeading}>Claim Details</Typography>
            <Accordion name="Claims Live Information" defaultExpanded>
                <Typography
                    className={classes.policyInfoValue}
                >Claims Diagnosis</Typography>
                <Typography className={classes.policyInfoLabel}>{claimEvent.event || '-'}</Typography>

                <Typography
                    className={classes.policyInfoValue}
                >Claim Event Date</Typography>
                <Typography className={classes.policyInfoLabel}>{getDisplayDate(claimEvent.dateOfEvent)}</Typography>

                <Typography
                    className={classes.policyInfoValue}
                >Date Claim Reported</Typography>
                <Typography className={classes.policyInfoLabel}>{getDisplayDate(claimEvent.dateClaimReported)}</Typography>
            </Accordion>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const claimEvent = (state.claimSummary && state.claimSummary.claimEvent) || {};
    return {
        claimEvent
    };
};

const mapDispatchToProps = {};

export const ClaimEventDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimEventDetails));