import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { FixedWidthButtonGroup, OutlinedTextAreaField } from '../../forms';
import { requiredValidator } from '../../forms/validations';
import { getTitleLastName } from '../../shared';
import informationIcon from '../../styles/icons/information.svg';
import styles from '../../styles/styles';
import { Navigation } from '../common/navigation';

const declaration = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    lifeAssured,
    stepsLength,
    submitted,
}) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Declaration</Typography>
            </Grid>
            <div className={classes.userPrompts} style={{ marginTop: '32px', height: '134px' }}>
                <img
                    src={informationIcon}
                    alt="information"
                    style={{ height: '18px', marginTop: '18px' }}
                />
                <Typography
                    className={classes.userPromptsText}
                    style={{ width: '272px', marginTop: '8px' }}
                >
                    <span className={classes.empathyTitle}>Note</span>
                    <br />
                    Once the questions have been asked and the requirements discussed, it is
                    important that the client be made aware of consequences of false information.
                </Typography>
            </div>
            <Typography
                style={{
                    width: '368px',
                    lineHeight: '27px',
                    marginTop: '29.58px',
                }}
            >
                *&nbsp;<span style={{ fontWeight: 600 }}>{getTitleLastName(lifeAssured)}</span>, do
                you confirm that you have answered all of the above questions truthfully and in
                full, to the best of your knowledge and ability?
            </Typography>
            <FixedWidthButtonGroup
                name="conclusion.confirmed"
                options={['Yes', 'No']}
                validate={requiredValidator}
                labelprefixed={'true'}
                disabled={submitted}
                hideLabel
            />
            <div
                className={classes.userPrompts}
                style={{ marginTop: '24px', marginBottom: '8px', height: '116px' }}
            >
                <img
                    src={informationIcon}
                    alt="information"
                    style={{ height: '18px', marginTop: '18px' }}
                />
                <Typography className={classes.userPromptsText} style={{ marginTop: '8px' }}>
                    <span className={classes.empathyTitle}>Note</span>
                    <br />
                    The client has to give a clear YES to this question, otherwise false information
                    given cannot be held against him/her going forward.
                </Typography>
            </div>
            <OutlinedTextAreaField
                name="conclusion.declaration"
                placeholder="Enter declaration notes"
                disabled={submitted}
            />
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const lifeAssured = (state.claimSummary && state.claimSummary.lifeAssured) || {};
    return {
        lifeAssured,
    };
};

const mapDispatchToProps = {};

export const Declaration = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(declaration));
