// @ts-nocheck
import * as React from 'react';

import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    withStyles,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getComponentId, nameToLabel } from '../..';

function styles(theme) {
    return createStyles({
        expanded: {
            border: '2px solid #002B99',
            borderRadius: '4px',
            '& .MuiAccordionSummary-expandIcon': {
                color: '#002B99',
                border: '0px',
            },
            '& .MuiAccordionSummary-content': {
                border: '0px',
            },
        },
        accordion: {
            marginTop: '12px',
            borderRadius: '4px',
        },
        label: {
            fontSize: '16px',
            fontWeight: 600,
            flexShrink: 0,
        },
        secondaryLabel: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        panelDetails: {
            flexDirection: 'column',
            borderRadius: '4px',
        },
    });
}

const accordion = ({ children, name, id, label, classes, ...rest }) => {
    return (
        <MuiAccordion {...rest} className={classes.accordion}>
            <AccordionSummary
                classes={{ expanded: classes.expanded }}
                id={getComponentId({ id, name })}
                name={name}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography className={classes.label}>{nameToLabel({ label, name })}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>{children}</AccordionDetails>
        </MuiAccordion>
    );
};

export const Accordion = withStyles(styles)(accordion);
