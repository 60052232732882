import React, { useState } from 'react';
import { TextField, Typography, withStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import { KeyboardDatePicker, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { callAllFunctions, getComponentId, nameToLabel } from '../..';
import { processMetaForErrors } from '../../form-component-utilities';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import calendarIcon from './calendar-icon.png';
import styles from '../styles';

export const noError = { errorMessage: undefined, showError: false };

export const DatePicker = withStyles(styles)(function DsmDatePicker({
    classes,
    input,
    InputProps,
    meta,
    label,
    id,
    hideLabel,
    onChange: onChangeProp,
    maxDateField,
    minDateField,
    disabled,
    shaded = true,
    ...rest
}) {
    const onChange = callAllFunctions(onChangeProp, input.onChange);
    const [{ value, validationError }, onStateChanged] = useState({
        value: input.value || null,
        validationError: null,
    });

    const name = input.name;
    const minDateErrorMessage = minDateField
        ? `Date should not be before ${nameToLabel({ name: minDateField, label: undefined })}`
        : undefined;
    const maxDateErrorMessage = maxDateField
        ? `Date should not be after ${nameToLabel({ name: maxDateField, label: undefined })}`
        : undefined;

    const Icon = <img src={calendarIcon} alt="" style={{ width: '24px', height: '24px' }} />;
    const { errorMessage, showError } = getErrors();

    if (disabled) {
        const readonlyInputProps = { ...InputProps, readOnly: true };
        const readonlyTextFieldComponent = tfProps => (
            <TextField
                {...tfProps}
                variant="outlined"
                label=""
                disabled={disabled}
                InputProps={{
                    ...InputProps,
                    classes: {
                        root: shaded ? classes.cssOutlinedInputShaded : classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'numeric',
                }}
            />
        );

        return (
            <div className={classes.textField}>
                {!hideLabel && (
                    <Typography
                        className={classes.cssLabel}
                        style={showError ? { color: '#cd003d' } : {}}
                    >
                        {getValidatedLabel(rest, nameToLabel({ label, name }))}
                    </Typography>
                )}
                <MuiDatePicker
                    {...rest}
                    value={input.value || null}
                    id={getComponentId({ id, name })}
                    name={name}
                    format="dd/MM/yyyy"
                    label={getValidatedLabel(rest, nameToLabel({ label, name }))}
                    InputProps={readonlyInputProps}
                    TextFieldComponent={readonlyTextFieldComponent}
                    onChange={() => undefined}
                    disabled={disabled}
                />
            </div>
        );
    }

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography
                    className={classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <KeyboardDatePicker
                {...rest}
                value={input.value || null}
                id={getComponentId({ id, name })}
                name={name}
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                onChange={onDateChanged}
                clearable
                error={showError}
                helperText={errorMessage}
                onError={onError}
                InputProps={{
                    ...InputProps,
                    classes: {
                        root: shaded ? classes.cssOutlinedInputShaded : classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'numeric',
                }}
                invalidDateMessage="Invalid Date"
                inputVariant="outlined"
                maxDateMessage={maxDateErrorMessage}
                minDateMessage={minDateErrorMessage}
                disabled={disabled}
                keyboardIcon={Icon}
            />
        </div>
    );

    function getErrors() {
        const formError = processMetaForErrors(meta);

        if (formError.showError) {
            return formError;
        }

        if (validationError) {
            return { showError: true, errorMessage: validationError };
        }

        return noError;
    }

    function onError(error, invalidValue) {
        if (error !== validationError || invalidValue !== value) {
            onStateChanged({ value: invalidValue, validationError: error });
        }
    }

    function getJsDateValue(value) {
        if (value instanceof Date) {
            const dateValue = new Date(
                Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
            );
            return dateValue;
        }

        if (value instanceof DateTime && value.isValid) {
            const dateValue = new Date(Date.UTC(value.year, value.month - 1, value.day));
            return dateValue;
        }

        return null;
    }

    function onDateChanged(date, value) {
        var jsDateValue = getJsDateValue(date);
        onStateChanged({
            value: jsDateValue || value,
            validationError: null,
        });
        onChange(jsDateValue);
    }
});
