import * as React from 'react';

import { FormControl as MuiFormControl } from '@material-ui/core';

export const FormControl = ({ children, ...rest }) => {
    return (
        <MuiFormControl
            {...rest}
            component="fieldset"
            fullWidth
            margin="normal"
            style={{
                marginBottom: 0,
                marginTop: 0,
            }}
        >
            {children}
        </MuiFormControl>
    );
};
