import * as React from 'react';
import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { FieldArray } from 'react-final-form-arrays';
import { AddCircleOutlined, CheckCircle } from '@material-ui/icons';
import { CustomExpansionPanel } from '../../forms/form-components/custom-expansion-panel';
import { DocumentNames, getDisplayDate } from '../../shared';
import {
    Condition,
    OutlinedSearchSelectField,
    OutlinedTextAreaField,
    OutlinedTextField,
    True,
} from '../../forms';
import { requiredValidator } from '../../forms/validations';
import { Navigation } from '../common/navigation';

const informationRecords = ({
    requirements,
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Information Records</Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.infoRecordHeading}>
                    Information Record Received
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <InformationReceived classes={classes} requirements={requirements} />
            </Grid>
            <InformationRequired classes={classes} form={form} submitted={submitted} />
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

const InformationRequired = ({ classes, form, submitted }) => {
    const { push } = form.mutators;
    const prefix = 'requirements';
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.infoRecordHeading}>
                    Information Record Required
                </Typography>
            </Grid>
            <FieldArray name={`${prefix}.documents`}>
                {({ fields }) =>
                    fields.map((document, documentIndex) => {
                        return documentRecord(prefix, fields, documentIndex, submitted);
                    })
                }
            </FieldArray>
            <Divider />
            {!submitted && (
                <Button
                    variant="contained"
                    color="secondary"
                    className={[classes.dynamicAddButton, classes.marginTop].join(' ')}
                    onClick={() => push(`${prefix}.documents`, { date: currentDate() })}
                    id="add-information-record"
                    fullWidth
                >
                    <AddCircleOutlined />
                    &nbsp; Add Information Record
                </Button>
            )}
        </React.Fragment>
    );
};

const InformationReceived = ({ requirements, classes }) => {
    if (!requirements || requirements.length === 0) {
        return <Typography>None</Typography>;
    }
    return (
        <React.Fragment>
            {requirements.map((req, rIndex) => (
                <Grid key={`req-${rIndex}`} item xs={12} container>
                    <CheckCircle className={classes.infoRecIcon} />
                    <Typography className={classes.infoRecordName}>{req.name}</Typography>
                    <Typography className={classes.infoRecordDate}>
                        &nbsp;|&nbsp;Date of report {getDisplayDate(req.dateReceived)}
                    </Typography>
                </Grid>
            ))}
        </React.Fragment>
    );
};

function documentRecord(prefix, fields, recordIndex, submitted) {
    return (
        <CustomExpansionPanel
            key={`${prefix}.documents${recordIndex + 1}`}
            defaultExpanded={true}
            style={{ marginTop: '20px', maxWidth: '368px', minWidth: '368px' }}
            name={`document${recordIndex + 1}`}
            label={`Record ${recordIndex + 1}`}
            remove={() => fields.remove(recordIndex)}
            deletable={!submitted}
        >
            <React.Fragment>
                <Grid item xs={12}>
                    <OutlinedSearchSelectField
                        label="Requirement"
                        name={`${prefix}.documents[${recordIndex}].name`}
                        options={DocumentNames}
                        validate={requiredValidator}
                        placeholder="Select requirement"
                        labelprefixed={'true'}
                        disabled={submitted}
                    />
                </Grid>
                <Condition when={`${prefix}.documents[${recordIndex}].name`} is="Other">
                    <True>
                        <Grid item xs={12}>
                            <OutlinedTextField
                                name={`${prefix}.documents[${recordIndex}].description`}
                                label="Requirement Description"
                                placeholder="Enter requirement description"
                                labelprefixed="true"
                                validate={requiredValidator}
                                disabled={submitted}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12}>
                    <OutlinedTextAreaField
                        label="Reason for Requirement"
                        name={`${prefix}.documents[${recordIndex}].reason`}
                        validate={requiredValidator}
                        placeholder="Enter Reason for Requirement"
                        labelprefixed="true"
                        disabled={submitted}
                    />
                </Grid>
            </React.Fragment>
        </CustomExpansionPanel>
    );
}

function currentDate() {
    const value = new Date();
    return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
}

const mapStateToProps = state => {
    return {
        requirements: (state.claimSummary && state.claimSummary.requirements) || [],
    };
};

const mapDispatchToProps = {};

export const InformationRecords = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informationRecords));
