import * as React from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { generateEmailEvent, getTitleLastName } from '../../shared';
import styles from '../../styles/styles';
import { TerminateView } from './terminate-view';
import { CallBackView } from './call-back-view';

const welcome = ({
    classes,
    form,
    handleNext,
    index,
    stepsLength,
    lifeAssured,
    currentUser,
    references,
    logOut,
    openDialog,
    submitted,
}) => {
    const teleClaim = {
        personalDetails: lifeAssured,
        references: references,
    };

    const callBack = event => {
        event.stopPropagation();
        openDialog({
            dialogContentDetails: {
                title: 'Call Me Back',
                content: <CallBackView />,
                titleClass: classNames(classes.modalTitle, classes.modalMarginTop),
                contentClass: classes.modalContent,
            },
            yesAction: {
                action: () => {
                    generateEmailEvent(
                        form.getState().values.callBackDetails,
                        teleClaim,
                        currentUser
                    );
                    logOut();
                },
            },
            yesLabel: 'Save & Exit',
            noLabel: 'Close',
        });
    };

    const terminateCall = event => {
        event.stopPropagation();
        openDialog({
            dialogContentDetails: {
                notitle: true,
                content: <TerminateView />,
                contentClass: classes.modalContent,
            },
            yesAction: {
                action: async () => {
                    await form.submit();
                    logOut();
                },
            },
            yesLabel: 'Save & Exit',
            noLabel: 'Close',
        });
    };

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.contentHeader}>Welcome</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Good day {getTitleLastName(lifeAssured)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    My name is{' '}
                    <span style={{ fontWeight: 600 }}>{getAssessorsName(currentUser)}</span> and
                    I&apos;m calling from Critical Illness Claims Department at Liberty, regarding
                    your claim under your{' '}
                    <span style={{ fontWeight: 600 }}>Living Lifestyle Benefit</span>.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    The reason we are calling is to expendite the processing of this claim for you
                    completing your claim from telephonically.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    I will need about 30-minutes of your time.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Is this a convenient time to discuss this matter ?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    If this is not convenient right now, could I call you a bit later ?
                </Typography>
            </Grid>
            {!submitted && (
                <Grid item xs={12} className={classes.buttonGroup}>
                    <Button
                        variant="contained"
                        className={classes.leftButton}
                        onClick={terminateCall}
                        id="intro-terminate"
                    >
                        Terminate
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.rightButton}
                        onClick={callBack}
                        id="intro-call-back"
                    >
                        Call Me Back
                    </Button>
                </Grid>
            )}
            <Grid item xs={12} className={classes.buttonGroup}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleNext(form)}
                    id="intro-confirmed"
                >
                    {submitted ? 'Next' : 'Confirmed'}
                </Button>
            </Grid>
        </Grid>
    );
};

function getAssessorsName(currentUser) {
    if (currentUser && currentUser.name) {
        if (currentUser.name.indexOf(',') > -1) {
            return currentUser.name.split(',')[1];
        } else {
            return currentUser.name.split(' ')[0];
        }
    }
    return '';
}

export const Welcome = withStyles(styles)(welcome);
