import React from 'react';
import { Grid, TextareaAutosize, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';

const assessorsNotes = ({ classes, id, notes }) => {
    return (
        <Grid item xs={12}>
            <Typography className={classes.summaryNotesLabel}>Assessor&apos;s Notes</Typography>
            <TextareaAutosize
                id={`${id}-notes`}
                value={notes}
                style={{
                    minHeight: '92px',
                    minWidth: '805px',
                    maxWidth: '805px',
                    borderRadius: '4px',
                    border: '1px solid #8B92A7',
                    backgroundColor: '#FCFCFC',
                    paddingLeft: '8px',
                    fontFamily: 'Noto Sans',
                    fontSize: '16px',
                }}
                disabled
            />
        </Grid>
    );
};

export const AssessorsNotes = withStyles(styles)(assessorsNotes);
