import * as React from 'react';

import { TextField as MuiTextField, Typography, withStyles } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';

const textField = ({
    id,
    input,
    classes,
    meta,
    label,
    hideLabel,
    helperText,
    onChange,
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    showMask,
    inputProps,
    InputProps,
    shaded = true,
    ...rest
}) => {
    const { name, value, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    // Only use MaskedInput component internally if a Mask is supplied
    if (mask) {
        InputProps = {
            ...InputProps,
            inputComponent: TextMask,
        };
    }

    const maskProps = !mask
        ? {}
        : /* eslint-disable */
          {
              mask,
              guide,
              placeholderChar,
              keepCharPositions,
              pipe,
              showMask,
          };
    /* eslint-enable*/
    inputProps = {
        ...restInput,
        ...inputProps,
        ...maskProps,
    };
    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography
                    className={classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <MuiTextField
                {...rest}
                id={getComponentId({ id, name })}
                name={name}
                helperText={showError ? errorMessage : helperText}
                // @ts-ignore
                FormHelperTextProps={{ style: { marginLeft: 0 }, component: 'pre' }}
                error={showError}
                InputProps={{
                    ...InputProps,
                    classes: {
                        root: shaded ? classes.cssOutlinedInputShaded : classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                }}
                // eslint-disable-next-line
                inputProps={inputProps}
                onChange={callAllFunctions(onChange, input.onChange)}
                value={value}
                variant="outlined"
                fullWidth
            />
        </div>
    );
};

const TextMask = ({ inputRef, ...rest }) => {
    return <MaskedInput ref={inputRef} {...rest} />;
};

export const TextField = withStyles(styles)(textField);
