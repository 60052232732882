import * as React from 'react';

import {
    FormHelperText,
    TextareaAutosize as MuiTextArea,
    Typography,
    withStyles,
} from '@material-ui/core';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import styles from '../styles';
import { capitalize } from 'lodash';

const textAreaField = ({
    id,
    input,
    classes,
    meta,
    label,
    hideLabel,
    helperText,
    onChange,
    placeholder,
    ...rest
}) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const errorColor = showError ? { border: '1px solid #cd003d' } : {};

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography
                    className={classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <MuiTextArea
                id={getComponentId({ id, name })}
                name={name}
                placeholder={!placeholder ? ` Enter ${placeholder}` : capitalize(placeholder)}
                onChange={callAllFunctions(onChange, input.onChange)}
                value={value}
                className={classes.textArea}
                style={{
                    ...errorColor,
                    paddingLeft: '8px',
                }}
                {...rest}
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export const TextAreaField = withStyles(styles)(textAreaField);
