import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { connect } from 'react-redux';

const notesView = ({ classes, claim, components, name }) => {
    const notes = claim && claim[name] && claim[name].notes;
    if (!components) {
        return (
            <Grid item xs={12}>
                <Typography>No notes for this section</Typography>
            </Grid>
        );
    }
    return (
        <Grid item xs={12}>
            {components.map((component, index) => {
                const note = notes && notes[index];
                return (
                    <Grid key={`assesors-notes${index}`} container justify="flex-start">
                        <Grid item xs={12} container justify="flex-start">
                            <Typography className={classes.notesIndex}>{index + 1}</Typography>
                            <Typography className={classes.sectionLabel}>
                                {component.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {note ? (
                                <Typography className={classes.notesView}>{note}</Typography>
                            ) : (
                                <Typography className={classes.notesDisclaimer}>
                                    Add your notes in Assessor’s Notes to view them here
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        claim: state.teleClaim,
    };
};

const mapDispatchToProps = {};

export const NotesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(notesView));
