import { env } from '../../env/env';
import { openDialogAction } from '../../forms/confirmation-dialog';
import axios from '../../http-request';
import { TELE_CLAIM_LOADED } from './types';

const claimsUri = `${env && env.ClaimsLiveAPI}/api/TeleClaim`;

export const teleClaimLoadedActionCreator = teleClaim => ({
    type: TELE_CLAIM_LOADED,
    payload: teleClaim,
});

export const teleClaimLoadedThunk = teleClaim => {
    return async dispatch => {
        sessionStorage.setItem('teleClaimId', teleClaim.teleClaimId);
        dispatch(teleClaimLoadedActionCreator(teleClaim));
        return {};
    };
};

export const loadTeleClaimAction = teleClaimId => {
    return async dispatch => {
        try {
            await axios.get(`${claimsUri}/${teleClaimId}`).then(response => {
                if (response && response.data) {
                    dispatch(teleClaimLoadedThunk(response.data));
                }
            });
        } catch (error) {
            dispatch(
                openDialogAction({
                    dialogContentDetails: {
                        title: 'Error',
                        error: true,
                        content: `Error while loading claim ${error}`,
                    },
                })
            );
        }
        return {};
    };
};

export const initiateTeleClaimAction = (referenceSource, referenceId) => {
    return async dispatch => {
        try {
            const loadClaimSummaryUri = `${claimsUri}?referenceSource=${referenceSource}&referenceId=${referenceId}`;
            await axios.get(loadClaimSummaryUri).then(response => {
                if (response && response.data) {
                    dispatch(teleClaimLoadedThunk(response.data));
                }
            });
        } catch (error) {
            dispatch(
                openDialogAction({
                    dialogContentDetails: {
                        title: 'Error',
                        error: true,
                        content: `Error while loading claim ${error}`,
                    },
                })
            );
        }
        return {};
    };
};
