import * as React from 'react';
import { withStyles } from '@material-ui/core';
import URLSearchParams from '@ungap/url-search-params';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { loadClaimSummaryAction } from '../actions/load-claim-summary';

class LoadClaimSummary extends React.Component {
    componentDidMount() {
        if (
            window.location &&
            window.location.pathname &&
            window.location.pathname.toLowerCase() === '/teleclaim'
        ) {
            const params = new URLSearchParams(window.location.search);
            const claimId = params.get('claimId') || '';
            const referenceSource = params.get('referenceSource') || '';
            const referenceId = params.get('referenceId') || '';
            this.loadClaimSummary(claimId, referenceSource, referenceId);
            return;
        }

        const claimId = sessionStorage.getItem('claimId');
        if (claimId) {
            this.loadClaimSummary(claimId, undefined, undefined);
            return;
        }

        this.checkReferences();
    }
    render() {
        return null;
    }

    loadClaimSummary(claimId = undefined, referenceSource = undefined, referenceId = undefined) {
        this.props.loadClaimSummary(claimId, referenceSource, referenceId);
    }

    checkReferences() {
        const referenceSource = sessionStorage.getItem('referenceSource');
        const referenceId = sessionStorage.getItem('referenceId');

        if (referenceSource && referenceId) {
            this.props.loadClaimSummary(undefined, referenceSource, referenceId);
        }
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    loadClaimSummary: loadClaimSummaryAction,
};

export const LoadClaimSummaryView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoadClaimSummary));
