import React from 'react';
import {
    createStyles,
    FormControl,
    FormHelperText,
    TextareaAutosize,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import {
    callAllFunctions,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../../../forms';
import { getValidatedLabel } from '../../../forms/form-component-utilities/label-validator/label-validator';
import { capitalize } from 'lodash';

export const CustomTextArea = ({ name, ...rest }) => {
    return (
        <FormControl>
            <Field
                {...rest}
                name={name}
                // @ts-ignore
                component={TextAreaField}
                type={'text'}
            />
        </FormControl>
    );
};

const textArea = ({
    id,
    input,
    classes,
    meta,
    label,
    hideLabel,
    helperText,
    onChange,
    placeholder,
    ...rest
}) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const errorColor = showError ? { border: '1px solid #cd003d' } : {};

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography
                    className={classes.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {getValidatedLabel(rest, nameToLabel({ label, name }))}
                </Typography>
            )}
            <TextareaAutosize
                id={getComponentId({ id, name })}
                name={name}
                placeholder={!placeholder ? ` Enter ${placeholder}` : capitalize(placeholder)}
                onChange={callAllFunctions(onChange, input.onChange)}
                value={value}
                className={classes.textArea}
                style={{
                    ...errorColor,
                }}
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

function styles() {
    return createStyles({
        textArea: {
            minWidth: '401px',
            maxWidth: '401px',
            minHeight: '217px',
            fontFamily: 'Noto Sans',
            fontSize: '16px',
            color: '#8B92A7',
            lineHeight: '27px',
            boxSizing: 'border-box',
            border: '1px solid #8B92A7',
            borderRadius: '4px',
            backgroundColor: '#FCFCFC',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingLeft: '8px',
        },
        textField: {
            margin: '12px auto auto 1px',
            borderColor: '#36B37E !important',
        },
        cssLabel: {
            minHeight: '20px',
            color: '#0D1325',
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '20px',
            marginBottom: 4,
        },
    });
}

const TextAreaField = withStyles(styles)(textArea);
