import React from 'react';
import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../../styles/styles';
import { Accordion } from '../../forms';
import { getDisplayDate, titleCase } from '../../shared';
import { ClaimEventDetails } from './claim-details';

const policyInformation = ({ classes, financialAdviser, contracts }) => {
    return (
        <React.Fragment>
            <ClaimEventDetails classes={classes} />
            <Typography className={classes.policyInfoHeading}>Policy Information</Typography>
            <PolicyDetails classes={classes} contracts={contracts} />
            <FinancialAdviser classes={classes} adviser={financialAdviser} />
        </React.Fragment>
    );
};

const FinancialAdviser = ({ classes, adviser }) => {
    return (
        <Accordion name="Financial Adviser Details" defaultExpanded>
            <Typography
                className={classes.policyInfoValue}
            >{`${adviser.firstName} ${adviser.lastName}`}</Typography>
            <Typography className={classes.policyInfoLabel}>Financial Adviser</Typography>
            <Typography className={classes.policyInfoValue}>
                {adviser.telephoneNumber || '-'}
            </Typography>
            <Typography className={classes.policyInfoLabel}>Telephone Number</Typography>
            <Typography className={classes.policyInfoValue}>
                {adviser.cellphoneNumber || '-'}
            </Typography>
            <Typography className={classes.policyInfoLabel}>Cellphone Number</Typography>
            <Typography className={classes.policyInfoValue}>
                {adviser.emailAddress || '-'}
            </Typography>
            <Typography className={classes.policyInfoLabel}>Email Address</Typography>
        </Accordion>
    );
};

const PolicyDetails = ({ classes, contracts }) => {
    return (
        <Accordion name="Policy Details" defaultExpanded>
            {contracts.map((contract, cIndex) => {
                return (
                    <Grid item key={`policyInformation_${cIndex}`}>
                        <Typography className={classes.policyInfo}>Policy Information</Typography>
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={6}>
                                <Typography className={classes.policyInfoValue}>
                                    {contract.policyNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.policyInfoValue}>
                                    {getDisplayDate(contract.paidToDate)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={6}>
                                <Typography className={classes.policyInfoLabel}>
                                    Policy Number
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.policyInfoLabel}>
                                    Paid to Date
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classes.policyInfo}>Exclusions</Typography>
                        {contract.benefits &&
                            contract.benefits.map((benefit, bIndex) => {
                                if (benefit.exclusions && benefit.exclusions.length > 0) {
                                    return (
                                        <Grid
                                            key={`benefit-infomation${bIndex}`}
                                            className={classes.marginBottom}
                                        >
                                            <Typography className={classes.policyInfoValue}>
                                                {benefit.name}
                                            </Typography>
                                            <Typography className={classes.policyInfoLabel}>
                                                Benefit
                                            </Typography>
                                            {benefit.exclusions.map((exclusion, index) => {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        className={classes.policyInfoValue}
                                                    >
                                                        {titleCase(exclusion)}
                                                    </Typography>
                                                );
                                            })}
                                            <Typography className={classes.policyInfoLabel}>
                                                Description
                                            </Typography>
                                        </Grid>
                                    );
                                }
                                return null;
                            })}
                        <Divider />
                    </Grid>
                );
            })}
        </Accordion>
    );
};

const mapStateToProps = state => {
    const financialAdviser = (state.claimSummary && state.claimSummary.financialAdviser) || {};
    const contracts = (state.claimSummary && state.claimSummary.contracts) || [];
    return {
        financialAdviser,
        contracts,
    };
};

const mapDispatchToProps = {};

export const PolicyInformation = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policyInformation));
