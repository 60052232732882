import * as React from 'react';

import { Grid, Button, Tooltip } from '@material-ui/core';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@material-ui/icons';
import axios from '../../http-request';
import { openDialogAction } from '../../forms/confirmation-dialog';
import { openModalAction } from '../../forms/form-modal/actions';
import { FormModalView } from '../../forms/form-modal/views/form-modal';
import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import { env } from '../../env/env';

const baseUrl = `${env && env.ClaimsLiveAPI}/api/TeleClaim`;

export function generateSummaryPdf(teleClaim, classes) {
    return async dispatch => {
        dispatch(
            openDialogAction({
                dialogContentDetails: {
                    notitle: true,
                    content: <span style={{ textAlign: 'center' }}>Select summary type</span>,
                    contentClass: classes.modalContent,
                },
                noAction: { action: () => getSummaryLink(teleClaim, dispatch, false) },
                noLabel: 'Full Summary',
                yesAction: { action: () => getSummaryLink(teleClaim, dispatch, true) },
                yesLabel: 'Client Summary',
                canCancel: true,
                cancelLabel: 'Cancel',
            })
        );
    };
}

async function getSummaryLink(teleClaim, dispatch, clientSummary) {
    dispatch(showLoading());
    let generateSynopsisUri = `${baseUrl}/GenerateSummary`;
    const reference = (teleClaim.references && teleClaim.references[0]) || {};
    if (clientSummary) {
        generateSynopsisUri += '?clientSummary=true';
    }
    try {
        /**@type {import('axios').AxiosRequestConfig} */
        const config = {
            url: generateSynopsisUri,
            data: { ...teleClaim },
            method: 'POST',
            responseType: 'text',
        };
        const summaryResponse = await axios(config);
        if (summaryResponse && summaryResponse.status === 200) {
            dispatch(
                openModalAction({
                    styles: { width: 550 },
                    modalContent: (
                        <FormModalView
                            formView={
                                <SummaryPdfView
                                    dispatch={dispatch}
                                    url={summaryResponse.data}
                                    clientSummary={clientSummary || false}
                                    requestSource={reference.referenceSource}
                                    requestNumber={reference.referenceId}
                                />
                            }
                            closeButton
                        />
                    ),
                })
            );
        } else {
            showErrorMessage(`Status Code ${summaryResponse.status}`);
        }
    } catch (error) {
        let errorMessage = '';
        if (error.response) {
            errorMessage = `Error Code ${error.response.status}`;
        } else {
            errorMessage = error.message;
        }
        showErrorMessage(errorMessage);
    } finally {
        dispatch(hideLoading());
    }

    function showErrorMessage(error) {
        dispatch(
            openDialogAction({
                error: true,
                dialogContentDetails: {
                    title: 'Error generating summary',
                    content: `Summary could not be generated: ${error}`,
                },
            })
        );
    }
}

const SummaryPdfView = ({ dispatch, url, clientSummary, requestSource, requestNumber }) => {
    return (
        <Grid container justify="space-between" style={{ marginBottom: '24px' }}>
            <Grid item xs={6}>
                <Tooltip title="Download pdf">
                    <Button
                        href={url}
                        id="downloadPdf"
                        color="secondary"
                        style={{ padding: 5, textTransform: 'none' }}
                    >
                        <CloudDownloadOutlined style={{ marginRight: 8 }} />
                        Download {clientSummary ? 'Client' : 'Full'} Summary
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={6}>
                <Tooltip title="Upload to Horizon">
                    <Button
                        id="uploadPdf"
                        color="secondary"
                        style={{ padding: 5, textTransform: 'none' }}
                        onClick={() =>
                            dispatch(
                                uploadDocumentAction({
                                    url: url,
                                    clientSummary: clientSummary,
                                    requestNumber: requestNumber,
                                    requestSource: requestSource,
                                })
                            )
                        }
                    >
                        <CloudUploadOutlined style={{ marginRight: 8 }} />
                        Upload {clientSummary ? 'Client' : 'Full'} Summary
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

function uploadDocumentAction(request) {
    return async dispatch => {
        dispatch(showLoading());
        const uploadSynopsisUri = `${baseUrl}/UploadSummary`;
        await axios
            .post(uploadSynopsisUri, getUploadRequest(request))
            .then(response => {
                if (response.status === 200) {
                    dispatch(
                        openDialogAction({
                            dialogContentDetails: {
                                title: 'Summary uploaded successfully',
                                content: `Document ID: ${response.data.documentId}`,
                            },
                            canCancel: true,
                        })
                    );
                } else {
                    showErrorMessage(`Status Code ${response.status}`);
                }
                dispatch(hideLoading());
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
                dispatch(hideLoading());
            });

        function showErrorMessage(error) {
            const errorMessage = `An error (${error}) occured while uploading summary, please retry later`;
            dispatch(
                openDialogAction({
                    error: true,
                    dialogContentDetails: {
                        title: 'Error uploading summary',
                        content: errorMessage,
                    },
                })
            );
        }
    };
}

function getUploadRequest(request) {
    const name = request.url.substring(request.url.lastIndexOf('/') + 1, request.url.indexOf('?'));
    return {
        name,
        url: request.url,
        clientSummary: request.clientSummary,
        requestNumber: request.requestNumber,
        requestSource: request.requestSource,
    };
}
