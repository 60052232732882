import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { AppHeader } from '../../master-layout/app-header/app-header';

const loadClaimSummaryFailed = () => {
    return (
        <React.Fragment>
            <AppHeader form={undefined} />
            <Typography>
                TODO: Error message view (claim does not exist or does not qualify)
            </Typography>
            <Typography>
                use full URL to load Tele Claims, e.g.
                http://localhost:3000/teleclaim?referenceId=00000000&referenceSource=Horizon
            </Typography>
        </React.Fragment>
    );
};

export const LoadClaimSummaryFailed = withStyles(styles)(loadClaimSummaryFailed);
