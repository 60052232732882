import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { VerticalStepper } from '../steppers/vertical-stepper';
import { ContactDetails } from './contact-details';
import { Declaration } from './declaration';

const components = [
    { name: 'Declaration', component: Declaration },
    { name: 'Contact Details', component: ContactDetails },
];

const conclusion = ({ ...rest }) => {
    return <VerticalStepper name="Conclusion" verticalComponents={components} {...rest} />;
};

export const Conclusion = withStyles(styles)(conclusion);
