import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import errorCloser from '../../styles/icons/blackX.png';
import styles from '../../styles/styles';

const errorsPanel = ({ classes, closePanel, errors }) => {
    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <div className={classes.errorWrapper}>
            <div className={classes.errorContainer}>
                <Warning className={classes.errorIcon} />
                <div className={classes.errorDetails}>
                    {errors && errors[0] && (
                        <div className={classes.errorHeading}>
                            {errors[0].label === 'Submission Error' ||
                            errors[0].label === 'Validation Error'
                                ? errors[0].label
                                : 'Validation error'}
                        </div>
                    )}
                    {errors.map(({ label, value }) => {
                        return (
                            label &&
                            label.indexOf('[') === -1 && (
                                <div key={label} className={classes.errorLine}>
                                    {label &&
                                    (label === 'Submission Error' || label === 'Validation Error')
                                        ? ''
                                        : `${label} : `}
                                    {value}
                                </div>
                            )
                        );
                    })}
                </div>
                <img
                    alt="X"
                    src={errorCloser}
                    className={classes.errorImage}
                    onClick={closePanel}
                />
            </div>
        </div>
    );
};

export const ErrorsPanel = withStyles(styles)(errorsPanel);
