import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { VerticalStepper } from '../steppers/vertical-stepper';
import { InformationRecords } from './information-records';

const components = [{ name: 'Information Records', component: InformationRecords }];

const requirements = ({ ...rest }) => {
    return <VerticalStepper name="Requirements" verticalComponents={components} {...rest} />;
};

export const Requirements = withStyles(styles)(requirements);
