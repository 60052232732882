import convertRegexToJsonSchemaString from './convert-regex-to-json-schema-string';

export const alpha = getRegexStringFn(/[a-zA-Z]/);
export const alphaAndSpace = getRegexStringFn(/[a-zA-Z ]/);
export const alphaAndDot = getRegexStringFn(/[a-zA-Z.]/);
export const telephoneNumber = getRegexStringFn(/[0-9 +]/);
export const numeric = getRegexStringFn(/[0-9]/);
export const alphaNumeric = getRegexStringFn(/[0-9a-zA-Z]/);
export const alphaNumericAndSpace = getRegexStringFn(/[0-9a-zA-Z ]/);

function getRegexStringFn(regex) {
    return function getRegexString(options = { nullable: false }) {
        const stringRegex = convertRegexToJsonSchemaString(regex);
        if (options.nullable) {
            return `^${stringRegex}*$`;
        }
        return `^${stringRegex}+$`;
    };
}
