import { isNil } from 'lodash';

export * from './conditional-validator';
export * from './regex-validator';

export const REQUIRED_VALIDATION_MESSAGE = 'This is a required field';
export const requiredValidator = customRequired(REQUIRED_VALIDATION_MESSAGE);

/**
 * @typedef { string|undefined } ValidationResult
 * @typedef {(value: any, values?: any) => ValidationResult} SyncValidator
 * @typedef {(value: any, values?: any) => Promise<ValidationResult>} AsyncValidator
 */

/**
 * @param {(values:any)=>boolean} condition
 */
export const conditionalRequired = condition =>
    conditionalCustomRequired(REQUIRED_VALIDATION_MESSAGE, condition);

/**
 * @param {string} message
 * @returns {SyncValidator}
 */
export function customRequired(message) {
    /**
     * @param {*} value
     * @returns {ValidationResult}
     */
    return function validateRequired(value) {
        let _value = value;
        if (typeof _value === 'boolean') {
            return undefined;
        }
        if (typeof _value === 'string') {
            _value = _value.trim();
        }
        if (typeof _value === 'object' && _value !== null) {
            if (_value instanceof Date) {
                return undefined;
            }
            if (!isNil(_value.value)) {
                _value = _value.value;
            } else {
                return message;
            }
        }
        return _value ? undefined : message;
    };
}

/**
 *
 * @param {string} message
 * @param {(values: any) => boolean} condition
 * @returns {SyncValidator}
 */
export function conditionalCustomRequired(message, condition) {
    /**
     * @param {*} value
     * @param {*} values
     * @returns {ValidationResult}
     */
    return function validateRequired(value, values) {
        if (condition(values)) {
            return customRequired(message)(value);
        }

        return undefined;
    };
}

const validInitialsRegex = /^([A-Z][ .])*$|^([A-Z])*$|^([a-z][ .])*$|^([a-z])*$/;
const validNameRegex = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;
const invalidNameMessage = 'Field contains invalid characters';

/**
 * @param {*} value
 * @returns {ValidationResult}
 */
export function isName(value) {
    return validateRegex(validNameRegex, value);
}

/**
 * @param {*} value
 * @returns {ValidationResult}
 */
export function isInitials(value) {
    return validateRegex(validInitialsRegex, value);
}

/**
 * @param {RegEx} regex
 * @param {*} value
 * @returns {ValidationResult}
 */
function validateRegex(regex, value) {
    if (value === undefined || isNil(value)) {
        return undefined;
    }

    if (typeof value === 'string') {
        value = value.trim();
        if (value === '') {
            return undefined;
        }
    }
    const _value = value.toString();
    return regex.test(_value) ? undefined : invalidNameMessage;
}

/**
 * @param {*} value
 * @returns {ValidationResult}
 */
export function mustBeNumber(value) {
    const error = 'Must be a number';
    if (value === undefined || value === null) {
        return undefined;
    }
    if (isNil(value)) {
        return error;
    }
    if (typeof value === 'boolean') {
        return error;
    }
    if (typeof value === 'string') {
        value = value.trim();
        if (value === '') {
            return error;
        }
    }
    const _value = Number(value);
    return isNaN(_value) ? error : undefined;
}

/**
 * @param {number} min
 * @returns {SyncValidator}
 */
export function minValue(min) {
    return function validateMinValue(value) {
        const _value = Number(value);
        if (isNaN(_value)) {
            return mustBeNumber(value);
        }
        return _value >= min ? undefined : `Should be greater than ${min}`;
    };
}

/**
 * @param {number} max
 * @returns {SyncValidator}
 */
export function maxValue(max) {
    return function validateMaxValue(value) {
        const _value = Number(value);
        if (isNaN(_value)) {
            return mustBeNumber(value);
        }
        return _value <= max ? undefined : `Should be less than ${max}`;
    };
}

/**
 *
 * @param  {...SyncValidator} validators
 * @returns {SyncValidator}
 */
export function composeValidators(...validators) {
    return function validateAll(value, allValues, meta) {
        const errors = [];
        // eslint-disable-next-line
        for (const validator of validators) {
            const error = validator(value, allValues, meta);
            if (error) {
                errors.push(error);
            }
        }
        return errors.join('\n');
    };
}
