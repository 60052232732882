import * as React from 'react';

import { Button, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { logOutThunk } from '../../authentication/actions/log-out';

function styles() {
    return createStyles({
        root: {
            padding: 8,
        },
        wrapper: {
            borderLeft: '1px solid #FFFFFF',
            display: 'flex',
            height: '48px',
        },
        userIcon: {
            marginTop: '8px',
            paddingLeft: '15px',
        },
        userName: {
            marginTop: '8px',
            paddingLeft: '8px',
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '12px',
        },
        logout: {
            paddingLeft: '8px',
            marginTop: '9px',
            width: '48px',
            fontSize: '10px',
            letterSpacing: 0,
            lineHeight: '13px',
            cursor: 'pointer',
        },
        circle: {
            borderRadius: '50%',
            height: '32px',
            width: '32px',
            backgroundColor: '#803CB2',
            color: '#ffffff',
            textAlign: 'center',
        },
        button: {
            marginLeft: '39px',
            backgroundColor: '#00164E',
            border: '2px solid #FFFFFF',
            color: '#FFFFFF',
            boxShadow: 'none',
            textTransform: 'none',
            height: '48px',
            width: '150px',
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '1px',
            '&:hover': {
                background: '#FFFFFF',
                borderColor: '#00164E',
                color: '#00164E',
            },
        },
    });
}

const currentUserInfo = ({ classes, currentUser, form, logOut }) => {
    let initials = '';
    if (currentUser && currentUser.name) {
        if (currentUser.name.indexOf(',') > -1) {
            const fullNames = currentUser.name.split(',');
            initials = `${getFirst(fullNames[1])}${getFirst(fullNames[0])}`;
        } else {
            const fullNames = currentUser.name.split(' ');
            if (fullNames.length === 1) {
                initials = `${getFirst(fullNames[0])}`;
            } else {
                initials = `${getFirst(fullNames[0])}${getFirst(fullNames[1])}`;
            }
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.userIcon}>
                    <div className={classes.circle}>
                        <Typography style={{ paddingTop: '2px' }}>{initials}</Typography>
                    </div>
                </div>
                <div>
                    <Typography className={classes.userName}>
                        {currentUser ? currentUser.name : ''}
                    </Typography>
                    <Typography className={classes.logout} onClick={logOut}>
                        Log out
                    </Typography>
                </div>
                <div>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => saveAndExit(form, logOut)}
                        id="save-exit"
                    >
                        {'Save & Exit'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

function getFirst(value) {
    return value && value.replace(/ /g, '').substring(0, 1);
}

const saveAndExit = (form, logOut) => {
    if (form) {
        form.submit();
    }
    logOut();
};

const mapStateToProps = state => ({
    currentUser: state.authentication,
});

const mapDispatchToProps = {
    logOut: logOutThunk,
};

export const CurrentUserInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(currentUserInfo));
