import { CLOSE_DIALOG, OPEN_DIALOG } from './';

export const openDialogAction = options => {
    return {
        type: OPEN_DIALOG,
        payload: {
            dialogOpen: true,
            dialogContentDetails: options.dialogContentDetails,
            doneAction: options.doneAction,
            yesAction: options.yesAction,
            noAction: options.noAction,
            canCancel: options.canCancel,
            yesLabel: options.yesLabel,
            noLabel: options.noLabel,
            cancelLabel: options.cancelLabel,
            error: options.error,
        },
    };
};

export const closeDialogAction = () => {
    return {
        type: CLOSE_DIALOG,
        payload: {
            dialogOpen: false,
        },
    };
};
