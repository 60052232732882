import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { DoctorVisits } from '../common/doctors-visits';
import { Navigation } from '../common/navigation';

const currentMedicalCondition = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography
                    className={[classes.contentHeader, classes.wrappingContentHeader].join(' ')}
                >
                    Current Medical Condition Consultation Log
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.contentText}>
                    Please provide the details of all doctors / hospitals / clinics that you are{' '}
                    <span style={{ textDecoration: 'underline' }}>currently consulting</span> in
                    relation to your medical condition
                </Typography>
            </Grid>
            <DoctorVisits
                prefix="claimantStatement.currentMedicalCondition"
                classes={classes}
                form={form}
                submitted={submitted}
            />
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const CurrentMedicalCondition = withStyles(styles)(currentMedicalCondition);
