import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { DoctorVisits } from '../common/doctors-visits';
import { requiredValidator } from '../../forms/validations';
import { Condition, FixedWidthButtonGroup, True } from '../../forms';
import { Navigation } from '../common/navigation';

const pastMedicalCondition = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    const prefix = 'claimantStatement.pastMedicalConditions';
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography
                    className={[classes.contentHeader, classes.wrappingContentHeader].join(' ')}
                >
                    Past Medical Condition Consultation Log
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FixedWidthButtonGroup
                    name={`${prefix}.previouslyConsulted`}
                    options={['Yes', 'No']}
                    label="Have you seen a doctor in the past 3 years?"
                    labelprefixed={'true'}
                    validate={requiredValidator}
                    disabled={submitted}
                />
            </Grid>
            <Condition when={`${prefix}.previouslyConsulted`} is="Yes">
                <True>
                    <Grid item xs={12}>
                        <Typography className={classes.contentText}>
                            Please provide the details of all doctors / hospitals / clinics
                            that&nbsp;
                            <span style={{ textDecoration: 'underline' }}>you have consulted</span>
                            &nbsp; over the&nbsp;
                            <span style={{ textDecoration: 'underline' }}>past 3 years</span>, in
                            relation to your medical condition
                        </Typography>
                    </Grid>
                    <DoctorVisits prefix={prefix} form={form} submitted={submitted} />
                </True>
            </Condition>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const PastMedicalCondition = withStyles(styles)(pastMedicalCondition);
