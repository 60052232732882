import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../../styles/styles';
import { Condition, FixedWidthCheckBoxField, True } from '../../../forms';
import { CustomTextArea } from './custom-text-area';

const includeInClientSummary = ({ classes, include, label, name, placeholder, value }) => {
    return (
        <Grid container justify="flex-start" className={classes.paddingTop}>
            <Grid item xs={6}>
                <Typography className={classes.summaryLabel}>{label}</Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.summaryValue}>{value || '-'}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FixedWidthCheckBoxField name={include} label="Include in Client Summary" />
                </Grid>
                <Condition when={include} is={true}>
                    <True>
                        <CustomTextArea name={name} label={label} placeholder={placeholder} />
                    </True>
                </Condition>
            </Grid>
        </Grid>
    );
};

export const IncludeInClientSummary = withStyles(styles)(includeInClientSummary);
