import { UserAgentApplication } from 'msal';
import { env } from '../env/env';

const regex = /\/?$/gi;
const clientID = () => (env && env.AADClientId) || '';
const instance = () => ((env && env.AADInstance) || '').replace(regex, '');
const tenantId = () => (env && env.AADTenantId) || '';

export const authority = () => `${instance()}/${tenantId()}`;
const defaultScopes = () => ((env && env.AADScopes) || '').split(';');

function config() {
    return {
        auth: {
            clientId: clientID(),
            authority: authority(),
            redirectUri: `${window.location.origin}`,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    };
}

const reqParams = {
    scopes: defaultScopes(),
    authority: authority(),
    prompt: 'select_account',
};

let _userAgentApplication;
function userAgentApplication() {
    if (!_userAgentApplication) {
        // @ts-ignore
        _userAgentApplication = new UserAgentApplication(config());
        _userAgentApplication.handleRedirectCallback(error => {
            if (error) {
                return console.error(error);
            }
        });
    }
    return _userAgentApplication;
}

export function redirectToLogin() {
    userAgentApplication().loginRedirect(reqParams);
}

export async function tryGetTokenSilent() {
    try {
        const token = await userAgentApplication().acquireTokenSilent(reqParams);
        return token;
    } catch (err) {
        return;
    }
}

export function getAccount() {
    return userAgentApplication().getAccount();
}

export function logout() {
    return userAgentApplication().logout();
}

let popedUp = false;
export async function getTokenAfterLogin() {
    let token;
    if (popedUp) {
        await sleep(5000);
        return getTokenAfterLogin();
    }
    // @ts-ignore
    token = await tryGetTokenSilent();
    if (token) {
        return token;
    }
    try {
        popedUp = true;
        token = await userAgentApplication().loginPopup(reqParams);
        return token;
    } catch (err) {
        console.error('Pop up error');
        console.error(err);
    } finally {
        popedUp = false;
    }
    redirectToLogin();
    await sleep(5000);
    // @ts-ignore
    return null;
}

export async function acquireTokenPopup() {
    return await userAgentApplication().acquireTokenPopup(reqParams);
}

export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
