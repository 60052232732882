import * as React from 'react';

import { FormControlLabel, Radio as MuiRadio, withStyles } from '@material-ui/core';
import { callAllFunctions, getComponentId, nameToLabel } from '../..';

const LargeRadio = withStyles({
    root: {
        '& .MuiSvgIcon-root': {
            fontSize: '32px',
        },
    },
})(MuiRadio);

export const Radio = ({ input, label, id, onChange, className, ...rest }) => {
    const { checked, name, ...restInput } = input;

    return (
        <FormControlLabel
            className={className}
            control={
                <LargeRadio
                    {...rest}
                    id={getComponentId({ id, name })}
                    name={name}
                    inputProps={{
                        ...restInput,
                    }}
                    checked={!!checked}
                    onChange={callAllFunctions(onChange, input.onChange)}
                    style={{ fontSize: 27 }}
                />
            }
            label={nameToLabel({ label, name })}
        />
    );
};
