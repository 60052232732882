import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { Navigation } from '../common/navigation';

const disclaimer = ({
    classes,
    form,
    handleNext,
    handleBackStepper,
    index,
    stepsLength,
    submitted,
}) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                <Typography className={classes.contentHeader}> Disclaimer</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '24px' }}>
                <Typography className={classes.contentText}>
                    Thank you for that. Before we continue I need to advise you that all our calls
                    are recorded for quality and security purposes and are available to you upon
                    request.
                </Typography>
            </Grid>
            <Navigation
                form={form}
                handleBack={handleBackStepper}
                handleNext={handleNext}
                submitted={submitted}
            />
        </Grid>
    );
};

export const Disclaimer = withStyles(styles)(disclaimer);
